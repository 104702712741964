import React, { useEffect, useState } from "react";
import PricingCard from "../../components/PricingCard";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  createPageView,
  getUserDetail,
} from "../../store/actions/users/users.actions";
import blackCircleIcon from "../../assets/img/blackCircle.jpg";
import blueCircleIcon from "../../assets/img/blueCircle.jpg";
import greenCircleIcon from "../../assets/img/greenCircle.jpg";
import orangeCircle from "../../assets/img/orangeCircle.png";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
const StyledAccordion = styled(Accordion)`
  .accordion-button {
    background-color: #fff;
  }
`;

const plansData = [
  {
    title: "Ganarpro All Pro Cleaning",
    features:
      " Professional Pricing and Proposal construction bidding tools, such as\n" +
      "        Projects, Calculator, Proposal, Prequalify, Planroom, QualKey,\n" +
      "        Capability Statement, Contact Outreach, and Site Visit Sheet.",
    price: "46",
    key: "cleaning",
    purchased: true,
    icon: blackCircleIcon,
    price_id: process.env.REACT_APP_ALL_PRO,
  },
  {
    title: "Ganarpro Business",
    features:
      "Sales and bidding support tools, such as Projects, " +
      "Prequalify, Planroom, QualKey, Capability Statement," +
      " and Contact Outreach. Excludes pricing and proposal tools.",
    price: "28.25",
    key: "business",
    purchased: true,
    icon: blueCircleIcon,
    price_id: process.env.REACT_APP_BUSINESS,
  },
  {
    title: "Ganarpro Basic Cleaning",
    features:
      " Professional Pricing and Proposal construction bidding tools, such as Calculator, and Proposal.",
    price: "22.50",
    purchased: true,
    key: "basic_cleaning",
    icon: greenCircleIcon,
    price_id: process.env.REACT_APP_BASIC_CLEANING,
  },
  {
    title: "Ganarpro Painting",
    features: "Paint Proposal, Projects, Prequalify, Planroom, Qualkey, Capability Statement, Contact Outreach and Site Visit Sheet",
    price: "30",
    key: "paint",
    purchased: true,
    icon: orangeCircle,
    price_id: process.env.REACT_APP_GANARPRO_PAINTING,
  },
];
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const PricingPage = () => {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const { user: profile } = useSelector((state) => state.userDetails);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const path = window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = "Pricing";
    const type = "Pricing";
    if (user) {
      dispatch(createPageView(pageRecord, path, type));
    }

    dispatch(getUserDetail());
  }, [dispatch, path]);
  return (
    <div className="container-fluid bg-white min-vh-100">
      <div className="container p-5">
        <div className="row ">
          <h1 className="my-2">Plans and pricing for Ganarpro Cloud Apps</h1>
          <h3 className="mb-md-5">
            <a
              href="https://www.ganarpro.com/compare-plans/"
              target="_blank"
              rel="noreferrer"
            >
              see product features
            </a>
          </h3>

          {plansData.map((product) => {
            return (
              <Elements key={product.id} stripe={stripePromise}>
                <Col md={3} xs={12} className="mb-5 mb-lg-0 col-md-6 col-lg-3">
                  <PricingCard
                    key={product.id}
                    num={product.id}
                    product={product}
                    profile={profile}
                  />
                </Col>
              </Elements>
            );
          })}
        </div>
        <Row className="justify-content mt-5">
          <h2 className="text-start mt-5 fw-bolder">
            Exclusive business features
          </h2>
          <Col md={3} className="text-start mt-5">
            <h5 className="fw-bolder"> Instant access, no downloads</h5>
            <p>
              Ganarpo is a web based application, you can use all tools from
              your desktop and mobile device instantly, and at all times.
            </p>
          </Col>
          <Col md={3} className="text-start mt-5">
            <h5 className="fw-bolder">Affordable and simple pricing</h5>
            <p>
              Subscribe to all apps or subscribe to each product individually.
              Use the apps that your business needs most to succeed in the
              construction industry.
            </p>
          </Col>
          <Col md={3} className="text-start mt-5">
            <h5 className="fw-bolder">Find active construction projects</h5>
            <p>
              Use the map to locate commercial construction projects. View
              project details with decision maker contact information.
            </p>
          </Col>
          <Col md={3} className="text-start mt-5">
            <h5 className="fw-bolder">Calculate winning prices</h5>
            <p>
              Enter building square footage to estimate a bid amount. Easily
              adjust the estimated price and save your estimate for further
              configuration.
            </p>
          </Col>
          <Col md={3} className="text-start mt-5">
            <h5 className="fw-bolder">Generate proposals</h5>
            <p>
              Use our scope of work templates and merge your company details
              into proposals for quick generation.
            </p>
          </Col>
          <Col md={3} className="text-start mt-5">
            <h5 className="fw-bolder">Find customers</h5>
            <p>
              Access 1,000+ blank prequalification applications to fill in and
              submit to start receiving invitation to bid requests directly to
              your email inbox.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-"></Row>
      </div>
    </div>
  );
};

export default PricingPage;
