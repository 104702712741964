import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { StyledInput } from "../StyledInputWithLoader";
import { saveEmailBody } from "../../store/actions/users/users.actions";
import { useDispatch } from "react-redux";
import EmailIcon from "../../assets/icons/EmailIcon";
import ActivityRow from "./activityRow";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`;

const ActivityModal = (props) => {
  const { user: profile } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const { title, activities } = props;

  return (
    <StyledModal
      {...props}
      // backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={props.closeButton}>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="mx-4" style={{ width: '97%' }}>
          {activities?.map((activity, index) => (
            <ActivityRow key={index} activity={activity} />
          ))}
        </table>
      </Modal.Body>
    </StyledModal>
  );
};

ActivityModal.defaultProps = {
  closeButton: true
};

export default ActivityModal;
