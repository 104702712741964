const FileIcon = ({
  style = {},
  fill = "#000",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 27 20"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0064 19C9.73484 19 6.46332 19 3.18167 19C2.85755 19 2.54357 18.99 2.22958 18.9103C1.61174 18.7508 1.36866 18.4517 1.29776 17.8337C0.882486 14.1852 0.467215 10.5367 0.0418156 6.89822C-0.0898556 5.77177 0.0418155 5.52256 1.1357 5.20357C1.43956 5.11385 1.42943 4.93442 1.42943 4.72508C1.42943 3.65845 1.42943 2.60178 1.42943 1.53515C1.42943 0.558237 1.96624 0.019937 2.95884 0.00996852C4.91365 0 6.87859 0.00996852 8.8334 0C9.44111 0 9.97793 0.189402 10.3223 0.697796C11.1022 1.83421 12.1454 2.16317 13.5331 2.12329C16.6223 2.03358 19.7115 2.09339 22.8007 2.09339C23.9958 2.09339 24.5023 2.58185 24.5023 3.75813C24.5023 4.6553 24.5124 5.55247 24.5023 6.44963C24.5023 6.74869 24.5934 6.88825 24.9175 6.93809C25.8595 7.10755 26.1127 7.46642 25.981 8.39349C25.5556 11.4837 25.1302 14.5839 24.7048 17.6742C24.5833 18.5514 24.2491 18.8804 23.3476 18.9801C23.1147 19.01 22.8716 19 22.6386 19C19.4177 19 16.2171 19 13.0064 19ZM13.0368 3.30955C10.0387 3.30955 7.03052 3.30955 4.03247 3.30955C2.66511 3.30955 2.63473 3.35939 2.65498 4.71511C2.66511 5.05404 2.7664 5.17366 3.1209 5.17366C4.56928 5.15373 6.02779 5.22351 7.47617 5.15373C8.80302 5.07398 9.92729 5.383 10.8692 6.33001C11.2643 6.71878 11.7808 6.88825 12.348 6.88825C15.8727 6.88825 19.3975 6.88825 22.9222 6.89822C23.2565 6.89822 23.3679 6.77859 23.3679 6.4596C23.3577 5.71196 23.3679 4.96432 23.3577 4.21668C23.3476 3.44911 23.216 3.30955 22.4462 3.30955C19.3165 3.30955 16.1766 3.30955 13.0368 3.30955Z"
      fill={fill}
    />
  </svg>
);

export default FileIcon;
