const AttachmentIcon = ({
  style = {},
  fill = "white",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 12 18",
  handleClick = () => {}
}) => (
  <svg
    onClick={handleClick}
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 0C8.49456 0 9.44839 0.395088 10.1517 1.09835C10.8549 1.80161 11.25 2.75544 11.25 3.75V12.75C11.25 13.4394 11.1142 14.1221 10.8504 14.7591C10.5865 15.396 10.1998 15.9748 9.71231 16.4623C9.2248 16.9498 8.64605 17.3365 8.00909 17.6004C7.37213 17.8642 6.68944 18 6 18C5.31056 18 4.62787 17.8642 3.99091 17.6004C3.35395 17.3365 2.7752 16.9498 2.28769 16.4623C1.80018 15.9748 1.41347 15.396 1.14963 14.7591C0.885795 14.1221 0.75 13.4394 0.75 12.75V6.75H2.25V12.75C2.25 13.7446 2.64509 14.6984 3.34835 15.4017C4.05161 16.1049 5.00544 16.5 6 16.5C6.99456 16.5 7.94839 16.1049 8.65165 15.4017C9.35491 14.6984 9.75 13.7446 9.75 12.75V3.75C9.75 3.45453 9.6918 3.16194 9.57873 2.88896C9.46566 2.61598 9.29992 2.36794 9.09099 2.15901C8.88206 1.95008 8.63402 1.78434 8.36104 1.67127C8.08806 1.5582 7.79547 1.5 7.5 1.5C7.20453 1.5 6.91194 1.5582 6.63896 1.67127C6.36598 1.78434 6.11794 1.95008 5.90901 2.15901C5.70008 2.36794 5.53434 2.61598 5.42127 2.88896C5.3082 3.16194 5.25 3.45453 5.25 3.75V12.75C5.25 12.9489 5.32902 13.1397 5.46967 13.2803C5.61032 13.421 5.80109 13.5 6 13.5C6.19891 13.5 6.38968 13.421 6.53033 13.2803C6.67098 13.1397 6.75 12.9489 6.75 12.75V4.5H8.25V12.75C8.25 13.3467 8.01295 13.919 7.59099 14.341C7.16903 14.7629 6.59674 15 6 15C5.40326 15 4.83097 14.7629 4.40901 14.341C3.98705 13.919 3.75 13.3467 3.75 12.75V3.75C3.75 2.75544 4.14509 1.80161 4.84835 1.09835C5.55161 0.395088 6.50544 0 7.5 0V0Z"
      fill={fill}
    />
  </svg>
);

export default AttachmentIcon;
