import {
  FETCH_INVITE_HISTORY_START,
  FETCH_INVITE_HISTORY_SUCCESS,
  FETCH_INVITE_HISTORY_FAIL,
  SEND_INVITE_START,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAIL,
  SHOW_ALERT,
  HIDE_ALERT,
} from "../../constants/refferalConstants";

const initialState = {
  loading: false,
  invites: [],
  error: null,
  alert: {
    show: false,
    message: "",
  },
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVITE_HISTORY_START:
    case SEND_INVITE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_INVITE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        invites: action.payload,
        error: null,
      };
    case SEND_INVITE_SUCCESS:
      // Assuming the payload includes the new invite that was just sent
      return {
        ...state,
        loading: false,
        invites: [...state.invites, action.payload],
        error: null,
      };
    case FETCH_INVITE_HISTORY_FAIL:
    case SEND_INVITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          message: action.payload,
        },
      };
    case HIDE_ALERT:
      return {
        ...state,
        alert: {
          show: false,
          message: "",
        },
      };
    default:
      return state;
  }
};

export default referralReducer;
