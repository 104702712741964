import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";

const StepIndicator = ({ currentStep, handleStepChange }) => {
  const steps = [
    "Company type",
    "Business details",
    "Company presence",
    "Business objectives",
  ];
  return (
    <div className="d-flex justify-content-center my-4">
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        const isActive = stepNumber === currentStep;
        const isCompleted = stepNumber < currentStep;

        return (
          <div key={step} className="text-center mx-3">
            <FontAwesomeIcon
              icon={isCompleted ? faCheckCircle : faCircle}
              color={isActive || isCompleted ? "#0d6efd" : "#d1d3d4"}
              size="2x"
              onClick={() => handleStepChange(stepNumber)}
            />
            <div className={` ${isActive ? "text-primary" : ""} h3`}>
              {step}
            </div>
            {index < steps.length - 1 && (
              <hr className="mx-auto" style={{ width: "40px" }} />
            )}
          </div>
        );
      })}
    </div>
  );
};
export default StepIndicator;
