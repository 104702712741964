export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "CONTACT_LIST_FAIL";

export const USER_CONTACT_DELETE_REQUEST = "USER_CONTACT_DELETE_REQUEST";
export const USER_CONTACT_DELETE_SUCCESS = "USER_CONTACT_DELETE_SUCCESS";
export const USER_CONTACT_DELETE_FAIL = "USER_CONTACT_DELETE_FAIL";

export const USER_COMPANY_DELETE_REQUEST = "USER_COMPANY_DELETE_REQUEST";
export const USER_COMPANY_DELETE_SUCCESS = "USER_COMPANY_DELETE_SUCCESS";
export const USER_COMPANY_DELETE_FAIL = "USER_COMPANY_DELETE_FAIL";

export const DOWNLOAD_CONTACT_LIST_REQUEST = "DOWNLOAD_CONTACT_LIST_REQUEST";
export const DOWNLOAD_CONTACT_LIST_SUCCESS = "DOWNLOAD_CONTACT_LIST_SUCCESS";
export const DOWNLOAD_CONTACT_LIST_FAIL = "DOWNLOAD_CONTACT_LIST_FAIL";
