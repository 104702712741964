import styled from "styled-components";
import { Accordion, Button, ToggleButton } from "react-bootstrap";

export const StyledAccordionHeader = styled(Accordion.Header)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  button {
    font-size: 14px;
    font-weight: 700 !important;
  }
`;

export const GreyStyledAccordionHeader = styled(Accordion.Header)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  .accordion-button {
    background: #5D6D7E;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700 !important;
    box-shadow: none;
  }
  .accordion-button:after {
    color: #ffffff;
  }
`;

export const BlueStyledAccordionHeader = styled(Accordion.Header)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  .accordion-button {
    background: #5DADE2;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700 !important;
  }
  .accordion-button:after {
    color: #ffffff;
  }
`;

export const DarkBlueStyledAccordionHeader = styled(Accordion.Header)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  .accordion-button {
    background: #1F618D;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700 !important;
  }
  .accordion-button:after {
    color: #ffffff;
  }
`;

export const StyledForm = styled.form`
  text-align: justify;
`;

export const OrangeStyledButton = styled(Button)`
  background-color: #076F25;
  border: #076F25;
  color: white !important;

  :active, :focus-visible {
    background-color: #076F25 !important;
    border: #076F25 !important;
  }

  :hover {
    background-color: #04892a;
    border: #04892a;
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
