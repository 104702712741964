export const filterArray = [
  {
    title: "Stage",
    key: "phase",
    values: [
      { key: "Pre-construction", value: "Pre-construction" },
      { key: "Breaking ground now", value: "Breaking-ground-now" },
      { key: "Under construction", value: "Under-construction" },
      { key: "Completing soon", value: "Completing-soon" },
      { key: "Historical", value: "Historical" },
    ],
  },
  {
    title: "Size (SQFT)",
    key: "size",
    values: [
      { key: "blank", value: "No size (blank square foot)" },
      { key: "underTen", value: "under 10,000" },
      { key: "btw10And30", value: "10,000 - 30,000" },
      { key: "btw30And60", value: "30,000 - 60,000" },
      { key: "btw60And100", value: "60,000 - 100,000" },
      { key: "btw100And200", value: "100,000 - 200,000" },
      { key: "above200", value: "200,000 & above" },
    ],
  },
  {
    title: "Building Types",
    key: "building",
    values: [
      {
        key: "Military",
        value: "Military",
      },
      {
        key: "Police-Fire Station",
        value: "Police-Fire Station",
      },
      {
        key: "Recreation Center",
        value: "Recreation Center",
      },
      {
        key: "Bank",
        value: "Bank",
      },
      {
        key: "Data center",
        value: "Data center",
      },
      {
        key: "Worship",
        value: "Worship",
      },
      {
        key: "Theatre",
        value: "Theatre",
      },
      {
        key: "Athletic",
        value: "Athletic",
      },
      {
        key: "Medical-Dental",
        value: "Medical-Dental",
      },
      {
        key: "Fitness",
        value: "Fitness",
      },
      {
        key: "Gas Station",
        value: "Gas Station",
      },
      {
        key: "Auto Office-Bay",
        value: "Auto Office-Bay",
      },
      {
        key: "Grocery",
        value: "Grocery",
      },
      {
        key: "Library",
        value: "Library",
      },
      {
        key: "Car dealership",
        value: "Car dealership",
      },
      {
        key: "Industrial",
        value: "Industrial",
      },
      {
        key: "Restaurant",
        value: "Restaurant",
      },
      {
        key: "Retail",
        value: "Retail",
      },
      {
        key: "Multi-family residential",
        value: "Multi-family residential",
      },
      {
        key: "Interior tenant fit out",
        value: "Interior tenant fit out",
      },
      {
        key: "Education",
        value: "Education",
      },
      {
        key: "WWTP",
        value: "WWTP",
      },
      {
        key: "Senior living Retirement",
        value: "Senior living Retirement",
      },
      {
        key: "Community / Public",
        value: "Community / Public",
      },
      {
        key: "Mixed Use",
        value: "Mixed Use",
      },
      {
        key: "Parking",
        value: "Parking",
      },
      {
        key: "Corporate",
        value: "Corporate",
      },
      {
        key: "Airport",
        value: "Airport",
      },
      {
        key: "Renovation Addition",
        value: "Renovation Addition",
      },
      {
        key: "Zoo",
        value: "Zoo",
      },
      {
        key: "Transit station",
        value: "Transit station",
      },
      {
        key: "Residential Home",
        value: "Residential Home",
      },
    ],
  },
];
