import React, { useState } from "react";
import "./PricingCard.css";
import LoginModal from "../LoginModal";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../StripePaymentCard/StripePaymentCard.css";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { createSubscriptionAPI } from "../../utils/requests/proposals";
import { toastError, toastSuccess } from "../../utils/helpers/helper";
import Card from "react-bootstrap/Card";

const useOptions = () => {
  return {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
      invalid: {
        iconColor: "#eb1c26",
        color: "#eb1c26",
      },
    },
  };
};

const PricingCard = ({ num, product, profile }) => {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const dispatch = useDispatch();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const options = useOptions();
  const { user } = useSelector((state) => state.auth);

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();
    if (!user) {
      setLoginModalShow(true);
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (payload.error) {
      setError(payload.error);
    } else {
      setLoading(true);
      let data;
      if (plans.length) {
        data = {
          payment_method: payload.paymentMethod.id,
          price_id: product.price_id,
          subscription_id: plans[0].id,
          ...user,
        };
      } else {
        data = {
          payment_method: payload.paymentMethod.id,
          price_id: product.price_id,
          ...user,
        };
      }

      createSubscriptionAPI(data)
        .then(async (response) => {
          toastSuccess("Payment is Successful");

          localStorage.setItem("user", JSON.stringify(response));
          if (product?.name?.includes("Painting")){
            localStorage.setItem(`isSwitchButton_${user?.id}`, 'true');
          }
          localStorage.setItem(`ganarpro_app${user?.id}`, product.key);
          // dispatch({
          //   type: SUBSCRIPTION_CREATE_SUCCESS,
          //   payload: response,
          // });
          document.location.href = "/";
        })
        .catch((error) => {
          setLoading(false);
          toastError(`Payment Failed`);
        });
      // dispatch(
      //   createSubscription({
      //     payment_method: payload.paymentMethod.id,
      //     price_id: process.env.REACT_APP_STRIPE_PRICE_ID,
      //     ...user,
      //   })
      // );
      setLoading(true);
    }
  };
  let plans = [];
  if (user) {
    plans = profile?.subscriptions?.filter((subscription) => {
      if (!subscription) {
        return [];
      }

      return (
        (subscription.status === "active" &&
          product.price_id !== subscription?.metadata.price_id) ||
        (product.price_id === subscription?.metadata.price_id &&
          subscription.status === "active")
      );
    });
    plans = plans === undefined ? [] : plans;
  }

  // const [cardComplete, setCardComplete] = useState(false);
  return (
    <>
      <Card className="text-start p-2 h-100">
        <Card.Body>
          <Row className="fw-">
            <Card.Img
              variant="top"
              src={product.icon}
              className="col-md-2"
              style={{ width: "100px" }}
            />
            <Card.Title className="col-md-7 align-self-center fw-bolder">
              {product.title}
            </Card.Title>
          </Row>
          <Card.Text className="fw-semibold my-3">{product.features}</Card.Text>
          <Card.Text className="my-4 mb-0">Starting</Card.Text>
          <Card.Text>
            <span className="h4 fw-bolder">${product.price}</span>/month
          </Card.Text>
          <Row className="mt-auto h-25 text-center ">
            <form onSubmit={handleSubmit}>
              {user &&
              plans?.length > 0 &&
              plans[0]?.metadata.price_id === product.price_id ? (
                <h4 className="fw-bolder text-end">Subscribed</h4>
              ) : plans[0]?.status === "active" ? (
                <>
                  <label>Card details</label>
                  {error && <Alert variant="danger">{error.message}</Alert>}
                  <CardElement
                    options={options}
                    onChange={(e) => {
                      setError(e.error);
                      // setCardComplete(e.complete);
                    }}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    className="p-3 text-center justify-content-center"
                    disabled={!stripe || loading}
                  >
                    {loading ? "upgrading plan..." : "Upgrade Plan"}
                  </Button>
                </>
              ) : (
                <>
                  <label>Card details</label>
                  {error && <Alert variant="danger">{error.message}</Alert>}
                  <CardElement
                    options={options}
                    onChange={(e) => {
                      setError(e.error);
                      // setCardComplete(e.complete);
                    }}
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    className="p-3 text-center justify-content-center"
                    disabled={!stripe || loading}
                  >
                    {loading ? "processing..." : "Pay"}
                  </Button>
                </>
              )}
            </form>
          </Row>
        </Card.Body>
      </Card>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
    </>
    // <div
    //   className="col-md-4 card  shadow-lg my-4 mx-5"
    //   style={{ width: "30rem" }}
    // >
    //   <Card.Img variant="top" src={product.image} />
    //   <div className="card-body mb-5">
    //     <h5 className="card-title h2 fw-bolder">{product.name}</h5>
    //     <span className="h3 fw-bolder">{product.amount}</span>/month
    //   </div>
    //   <form onSubmit={handleSubmit}>
    //     {user && plans.length > 0 ? (
    //       <h3 className="fw-bolder">Subscribed</h3>
    //     ) : (
    //       <>
    //         <label>Card details</label>
    //         {error && <Alert variant="danger">{error.message}</Alert>}
    //         <CardElement
    //           options={options}
    //           onChange={(e) => {
    //             setError(e.error);
    //             // setCardComplete(e.complete);
    //           }}
    //         />
    //         <Button
    //           type="submit"
    //           variant="primary"
    //           className="p-3 "
    //           disabled={!stripe || loading}
    //         >
    //           {loading ? "processing..." : "Pay"}
    //         </Button>
    //       </>
    //     )}
    //   </form>

    // </div>
  );
};

export default PricingCard;
