import React, { useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const AutoComplete = ({
  onAddressSelect,
  initialValue,
  formControlName,
  placeholder,
  disable_fields,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    componentRestrictions: { country: "usa" },
    fields: ["address_components", "geometry", "icon", "name"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    // Add event listener for when a place is selected
    autoCompleteRef.current.addListener("place_changed", () => {
      onAddressSelect({ value: inputRef.current.value, name: formControlName });
    });

    inputRef.current.addEventListener("change", () => {
      onAddressSelect({ value: inputRef.current.value, name: formControlName });
    });
  }, [onAddressSelect, initialValue, formControlName]);

  useEffect(() => {
    if (initialValue || initialValue === "") {
      inputRef.current.value = initialValue;
    }
  }, [initialValue]);

  return (
    <>
      <Form.Control
        ref={inputRef}
        type="text"
        className="text-center"
        placeholder={placeholder}
        style={{ border: "none", background: "none" }}
        onChange={(e) => {
          inputRef.current.value = e.target.value;
        }}
        disabled={disable_fields ? true : false}
      />
    </>
  );
};

export default AutoComplete;
