import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Card,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Alert,
  Badge,
  Modal,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCopy,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  fetchInviteHistory,
  sendInvite,
  showAlert,
} from "../../store/actions/referral/referral.actions";
import { getUserDetail } from "../../store/actions/users/users.actions";

const Referral = () => {
  const dispatch = useDispatch();
  const { invites, loading, alertMessage, showAlertFlag } = useSelector(
    (state) => state.referral
  );
  const { user: profile } = useSelector((state) => state.userDetails);
  const { user } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState("");
  const [activeTab, setActiveTab] = useState("invites");
  const [copyButtonText, setCopyButtonText] = useState("Copy Link");
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();
  useEffect(() => {
    dispatch(getUserDetail());

    dispatch(fetchInviteHistory());
  }, [dispatch, user]);

  const handleSendInvite = () => {
    if (email && validateEmail(email)) {
      dispatch(sendInvite(email));
      setEmail("");
    } else if (email) {
      setInvalidEmail(email);
      dispatch(showAlert("Please enter a valid email address"));
      return;
    }

    if (emails.length === 0) {
      dispatch(showAlert("Please enter at least one email address"));
      return;
    }

    dispatch(sendInvite(emails.join(",")));
    setEmails([]);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setInvalidEmail("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addEmail();
    }
  };

  const addEmail = () => {
    if (validateEmail(email)) {
      setEmails((prevEmails) => [...prevEmails, email]);
      setEmail("");
    } else {
      setInvalidEmail(email);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleCopyLink = () => {
    const referralLink = `${window.location.origin}/signup?referral_code=${profile?.referral_code}`;
    navigator.clipboard.writeText(referralLink);
    setCopyButtonText("Copied");
    dispatch(showAlert("Link copied to clipboard!"));
    setTimeout(() => {
      setCopyButtonText("Copy Link");
    }, 1000); // Change text back to "Copy Link" after 2 seconds
  };

  const handleClickInput = () => {
    inputRef.current.focus();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const referralLink = `${window.location.origin}/signup?referral_code=${profile?.referral_code}`;

  return (
    <Container fluid>
      <Row className="my-5 justify-content-center">
        <Col className="col-md-11">
          <h1 className="fa-3x text-start">Referral</h1>
        </Col>
      </Row>
      <Row className="mb-5 justify-content-center">
        <Col className="col-md-11">
          <div
            className="p-5 text-start rounded"
            style={{
              backgroundColor: "#e8f4ff",
              borderRadius: "15px",
            }}
          >
            <h1>Refer a business and get a free month!</h1>
            <p>
              Invite a business to Ganarpro and get your next month free after
              the referred business subscribes to any plan and pays for one
              month.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center rounded my-5">
        <Col md={7} className="rounded">
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="invites" title="Invites">
              <Card className="mb-3 rounded  text-start">
                <Card.Body>
                  <Card.Title>
                    <h4>
                      <strong>Invite business via link</strong>
                    </h4>
                  </Card.Title>
                  <div className="bg-light p-3">
                    <Card.Text>Copy and Share your link</Card.Text>
                    <Card.Text>
                      Growing a business in the construction industry? Join
                      Ganarpro to find new customers, price projects, and create
                      proposals.
                    </Card.Text>
                    <strong>{referralLink}</strong>
                  </div>
                  <Row className="mt-3">
                    <Col className="align-self-center">
                      <Button variant="dark p-4" onClick={handleCopyLink}>
                        {copyButtonText}
                      </Button>
                    </Col>
                    <Col className="rounded text-start">
                      <Card className="mb-3 text-start rounded">
                        <Card.Body>
                          <Card.Title>
                            <strong>Invite business via social</strong>
                          </Card.Title>
                          <div className="d-flex justify-content-start">
                            <Button
                              variant="link"
                              href={`https://twitter.com/intent/tweet?url=${referralLink}`}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faTwitter} size="2xl" />
                            </Button>
                            <Button
                              variant="link"
                              href={`https://www.facebook.com/sharer/sharer.php?u=${referralLink}`}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faFacebook} size="2xl" />
                            </Button>
                            <Button
                              variant="link"
                              href={`https://www.linkedin.com/shareArticle?mini=true&url=${referralLink}`}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faLinkedin} size="2xl" />
                            </Button>
                            <Button
                              variant="link"
                              href={`https://wa.me/?text=${referralLink}`}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={faWhatsapp} size="2xl" />
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className=" text-start rounded ">
                <Card.Body>
                  <div className="row justify-content-between">
                    <Col className="col-md-6">
                      <h4>
                        <strong>Invite business via email</strong>
                      </h4>
                    </Col>
                    <Col className="col-md-3">
                      <h4 onClick={handleShowModal} className="cursor-pointer">
                        <strong> Preview email</strong>
                      </h4>
                    </Col>
                  </div>
                  <div
                    className="input-group email-input border mt-3"
                    onClick={handleClickInput}
                  >
                    {emails.map((email, index) => (
                      <Badge
                        pill
                        variant="primary"
                        key={index}
                        className="mr-2"
                        style={{ margin: "20px", display: "inline-flex" }}
                      >
                        {email}{" "}
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => removeEmail(index)}
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                        />
                      </Badge>
                    ))}
                    <FormControl
                      type="email"
                      placeholder="Enter business emails"
                      value={email}
                      onChange={handleEmailChange}
                      onKeyPress={handleKeyPress}
                      style={{
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                        height: "6rem",
                        flex: "1",
                      }}
                      ref={inputRef}
                    />
                  </div>
                  <p>
                    Press enter or type comma to invite multiple email addresses
                  </p>

                  {invalidEmail && (
                    <Alert variant="danger">
                      {invalidEmail} is not a valid email address.
                    </Alert>
                  )}
                  <Button
                    variant="primary"
                    onClick={handleSendInvite}
                    disabled={email.length === 0 && emails.length === 0}
                    className="mt-3 p-4"
                  >
                    <FontAwesomeIcon icon={faEnvelope} /> Send Invite
                  </Button>
                </Card.Body>
              </Card>
            </Tab>
            <Tab
              eventKey="history"
              title={`Invite History (${
                profile?.referral_history?.length || 0
              })`}
            >
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Table responsive>
                  <thead className="text-start">
                    <tr>
                      <th></th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="text-start">
                    {profile?.referral_history?.map((invite, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{invite.referred_email}</td>
                        <td>
                          {invite.subscription_purchased
                            ? "Subscribed"
                            : "Not Subscribed"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Tab>
          </Tabs>
        </Col>
        <Col md={4} className="mt-5 rounded">
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="text-start">
                <h4>
                  <strong>Summary</strong>
                </h4>
              </Card.Title>
              <Card.Text className="bg-light p-3">
                <strong>Total months free: {profile?.total_rewards}</strong>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-start">
            <Card.Body>
              <Card.Title className="my-3 mb-4">
                <strong>How it works</strong>
              </Card.Title>
              <div>
                <p className="my-4">
                  <FontAwesomeIcon icon={faCheckCircle} /> Invite a business to
                  check out Ganarpro.
                </p>
                <p className="my-4">
                  <FontAwesomeIcon icon={faCheckCircle} /> They'll need to
                  subscribe via your referral link.
                </p>
                <p className="my-4">
                  <FontAwesomeIcon icon={faCheckCircle} /> They'll need to pay 1
                  month subscription.
                </p>
                <p className="my-4">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  You'll receive the next month discounted to $0.
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showAlertFlag && <Alert variant="info">{alertMessage}</Alert>}

      {/* Modal for previewing the email */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Preview Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Email Preview</h5>
          <p>To: {emails.join(", ")}</p>
          <p>Subject: Invitation to join Ganarpro</p>
          <p>Body:</p>
          <div>
            <p>Hi there,</p>
            <p>
              I hope you're doing well. I wanted to invite you to join Ganarpro,
              a platform that helps businesses in the construction industry find
              new customers, price projects, and create proposals.
            </p>
            <p>Please use this link to accept the invitation: {referralLink}</p>
            <p>
              Best regards,
              <br />
              Ganarpro Team
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Referral;
