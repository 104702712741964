import { delete$, get$, post$, put$ } from "./default";
import { api } from "../constants/api";

export const saveSiteVisitAPI = (data) => {
  return post$(api.site_visits.saveSiteVisit, data, {}, true);
}

export const getAllSiteVisitAPI = () => {
  return get$(api.site_visits.getAllSiteVisit);
}

export const getSiteVisitDetailAPI = (siteVisitId) => {
  return get$(api.site_visits.getSiteVisit.replace(":siteVisitId", siteVisitId));
}

export const getSiteVisitDetailByProjectAPI = (siteVisitProject) => {
  return get$(api.site_visits.getSiteVisitByProject.replace(":siteVisitProject", siteVisitProject));
}

export const deleteSiteVisitAPI = (siteVisitId) => {
  return delete$(api.site_visits.deleteSiteVisit.replace(":siteVisitId", siteVisitId));
}

export const downloadSiteVisitDetailAPI = (siteVisitId, data) => {
  return post$(api.site_visits.downloadSiteVisit.replace(":siteVisitId", siteVisitId), data, {responseType: "blob"}, true);
}

export const updateSiteVisitAPI = (siteVisitId, data) => {
  return put$(api.site_visits.updateSiteVisit.replace(":siteVisitId", siteVisitId), data, {}, true);
}
