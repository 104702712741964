import React, { useEffect, useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import StepIndicator from "./components/StepIndicator";
import AccountCreationForm from "./components/AccountCreationForm";
import BusinessObjectivesForm from "./components/BuisnessObjectivesForm";
import CompanyType from "./components/CompanyType";
import CompanyBasic from "./components/CompanyBasic";
import CompanyPresence from "./components/CompanyPresence";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetail,
  submitOnboardingStep,
} from "../../store/actions/users/users.actions";
import Spinner from "react-bootstrap/Spinner";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
// Import other step components here if you have more steps

const OnboardingWizard = () => {
  const { user } = useSelector((state) => state.auth);
  const { id } = user;
  const userDetails = useSelector((state) => state.userDetails);
  const { user: profile } = userDetails;

  const dispatch = useDispatch();
  const onboardingState = useSelector((state) => state.onboarding);
  const { loading, onboarding } = onboardingState;
  const totalSteps = 4; // Adjust based on your actual number of steps
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    office_address: "",
    company_website: "",
    is_cleaning_company: true,
    business_objectives: null,
    working_area: "",
    about_us: ` works with commercial general contractors as a subcontractor completing the scope.
    We estimate bids via the plans and physical site visit walk throughs.
  `,
    // Initialize other fields here
  });

  const goToNextStep = () => {
    dispatch(submitOnboardingStep(formData));

    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFormDataChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    dispatch(submitOnboardingStep(formData));
    dispatch(getUserDetail());
    navigate("/");
  };

  const handleStepChange = (stepNumber) => {
    // Here, you can implement validation or other checks if needed
    setCurrentStep(stepNumber);
  };

  // useEffect(() => {
  //   dispatch(submitOnboardingStep(formData));
  // }, [dispatch]);

  // Render function or component logic
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <CompanyType
            formData={formData}
            handleFormDataChange={handleFormDataChange}
          />
        );
      case 2:
        return (
          <CompanyBasic
            formData={formData}
            handleFormDataChange={handleFormDataChange}
          />
        );
      case 3:
        return (
          <CompanyPresence
            formData={formData}
            handleFormDataChange={handleFormDataChange}
          />
        );
      case 4:
        return (
          <BusinessObjectivesForm
            formData={formData}
            handleFormDataChange={handleFormDataChange}
          />
        );

      // case 2, case 3 for other steps...
      default:
        return (
          <AccountCreationForm
            formData={formData}
            onChange={handleFormDataChange}
          />
        );
    }
  };
  return (
    <Container>
      <div style={{ minHeight: "70vh" }}>
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          handleStepChange={handleStepChange}
        />
        <Row className="justify-content-center text-left">
          <Col md={7}>{renderStep(currentStep)}</Col>
        </Row>

        {/* Include other steps here with similar pattern */}
      </div>
      <div className="d-flex justify-content-between">
        {currentStep > 1 && (
          <Button variant="secondary" onClick={goToPrevStep} className="p-2">
            <h4 className="p-2 mb-1">
              Back <BsArrowLeft style={{ fontSize: "1.5rem" }} />{" "}
            </h4>
            {/*<span className="h4">Back</span>*/}
          </Button>
        )}
        {currentStep < totalSteps && (
          <Button variant="primary" onClick={goToNextStep} className="p-2">
            {loading ? (
              <h4 className="p-2 mb-1">
                Loading <Spinner animation="border" size="sm" />{" "}
              </h4>
            ) : (
              <h4 className="p-2 mb-1">
                Next <BsArrowRight style={{ fontSize: "1.5rem" }} />{" "}
              </h4>
            )}
            {/*<span className="h4">Next</span>*/}
          </Button>
        )}
        {currentStep === totalSteps && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            className="p-2"
            disabled={loading}
          >
            {loading ? (
              <h4 className="p-2 mb-1">
                Loading <Spinner animation="border" size="sm" />{" "}
              </h4>
            ) : (
              <h4 className="p-2 mb-1">
                Submit <BsArrowRight style={{ fontSize: "1.5rem" }} />
              </h4>
            )}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default OnboardingWizard;
