export const SITE_VISIT_LIST_REQUEST = "SITE_VISIT_LIST_REQUEST";
export const SITE_VISIT_LIST_SUCCESS = "SITE_VISIT_LIST_SUCCESS";
export const SITE_VISIT_LIST_FAIL = "SITE_VISIT_LIST_FAIL";

export const SITE_VISIT_CREATE_REQUEST = "SITE_VISIT_CREATE_REQUEST";
export const SITE_VISIT_CREATE_SUCCESS = "SITE_VISIT_CREATE_SUCCESS";
export const SITE_VISIT_CREATE_FAIL = "SITE_VISIT_CREATE_FAIL";

export const SITE_VISIT_DETAIL_REQUEST = "SITE_VISIT_DETAIL_REQUEST";
export const SITE_VISIT_DETAIL_SUCCESS = "SITE_VISIT_DETAIL_SUCCESS";
export const SITE_VISIT_DETAIL_FAIL = "SITE_VISIT_DETAIL_FAIL";

export const SITE_VISIT_UPDATE_REQUEST = "SITE_VISIT_UPDATE_REQUEST";
export const SITE_VISIT_UPDATE_SUCCESS = "SITE_VISIT_UPDATE_SUCCESS";
export const SITE_VISIT_UPDATE_FAIL = "SITE_VISIT_UPDATE_FAIL";

export const SITE_VISIT_DELETE_REQUEST = "SITE_VISIT_DELETE_REQUEST";
export const SITE_VISIT_DELETE_SUCCESS = "SITE_VISIT_DELETE_SUCCESS";
export const SITE_VISIT_DELETE_FAIL = "SITE_VISIT_DELETE_FAIL";

export const SITE_VISIT_DOWNLOAD_REQUEST = "SITE_VISIT_DOWNLOAD_REQUEST";
export const SITE_VISIT_DOWNLOAD_SUCCESS = "SITE_VISIT_DOWNLOAD_SUCCESS";
export const SITE_VISIT_DOWNLOAD_FAIL = "SITE_VISIT_DOWNLOAD_FAIL";

export const SITE_VISIT_SET_VALUES = "SITE_VISIT_SET_VALUES";
export const SITE_VISIT_RESSET_VALUES = "SITE_VISIT_RESSET_VALUES";

export const SITE_VISIT_HEADING_SET_VALUES = "SITE_VISIT_HEADING_SET_VALUES";
export const SITE_VISIT_HEADING_RESSET_VALUES = "SITE_VISIT_HEADING_RESSET_VALUES";
