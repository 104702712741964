const PhoneIcon = ({
  style = {},
  fill = "#000",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 24 24"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.566 21.999H16.594C17.122 21.999 17.621 21.791 17.999 21.413L20.711 18.701C20.8039 18.6082 20.8777 18.498 20.928 18.3767C20.9783 18.2554 21.0042 18.1253 21.0042 17.994C21.0042 17.8627 20.9783 17.7326 20.928 17.6113C20.8777 17.49 20.8039 17.3798 20.711 17.287L16.711 13.287C16.6182 13.1941 16.508 13.1203 16.3867 13.07C16.2654 13.0197 16.1353 12.9938 16.004 12.9938C15.8726 12.9938 15.7426 13.0197 15.6213 13.07C15.5 13.1203 15.3898 13.1941 15.297 13.287L13.703 14.881C12.964 14.661 11.585 14.161 10.711 13.287C9.83697 12.413 9.33697 11.034 9.11697 10.295L10.711 8.701C10.8039 8.60821 10.8777 8.49801 10.928 8.37669C10.9783 8.25538 11.0042 8.12533 11.0042 7.994C11.0042 7.86267 10.9783 7.73262 10.928 7.61131C10.8777 7.48999 10.8039 7.37979 10.711 7.287L6.71097 3.287C6.5198 3.10655 6.26686 3.00603 6.00397 3.00603C5.74109 3.00603 5.48815 3.10655 5.29697 3.287L2.58597 5.999C2.20597 6.379 1.99197 6.901 1.99997 7.434C2.02297 8.858 2.39997 13.804 6.29797 17.702C10.196 21.6 15.142 21.976 16.566 21.999ZM6.00497 5.408L8.59097 7.994L7.29797 9.287C7.18031 9.40434 7.09387 9.54926 7.04654 9.70855C6.9992 9.86784 6.99248 10.0364 7.02697 10.199C7.05097 10.314 7.63797 13.041 9.29797 14.701C10.958 16.361 13.685 16.948 13.8 16.972C13.9625 17.0067 14.1312 17 14.2905 16.9527C14.4498 16.9054 14.5947 16.8188 14.712 16.701L16.005 15.408L18.591 17.994L16.585 19.999C15.337 19.978 11.067 19.643 7.71197 16.287C4.34597 12.921 4.01997 8.636 3.99997 7.413L6.00497 5.408ZM19.999 10.999H21.999C21.999 5.869 18.126 2 12.989 2V4C17.051 4 19.999 6.943 19.999 10.999Z"
      fill={fill}
    />
    <path
      d="M12.999 8C15.102 8 15.999 8.897 15.999 11H17.999C17.999 7.775 16.224 6 12.999 6V8Z"
      fill={fill}
    />
  </svg>
);

export default PhoneIcon;
