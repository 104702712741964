import React, { useEffect } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { deleteSiteVisit, downloadSiteVisit, getAllSiteVisits } from "../../store/actions/site_visits/site_visits.actions";

const RecentSiteVisits = ({ setModalShow, showFormTab }) => {
  let params = useParams();
  let [searchParams] = useSearchParams()
  const { id } = params;
  const { id: editId } = params;
  const { user } = useSelector((state) => state.auth);
  const { loading, site_visits } = useSelector((state) => state.siteVistList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSetModal = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  useEffect(() => {
    if (user) {
      dispatch(getAllSiteVisits())
    }
  }, [dispatch, user])

  const handleDownload = (e, id, site_visit) => {
    e.preventDefault();

    dispatch(downloadSiteVisit(id, site_visit));
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    let response = window.confirm("Are you Sure you want to delete Site visit?");
    if (response) {
      dispatch(deleteSiteVisit(id, editId));
    }
  };

  useEffect(() => {
    let siteVisitId = searchParams.get('download_id');
    if (siteVisitId && site_visits?.length > 0) {
      let site_visit = site_visits.find(site_visit => site_visit.id == siteVisitId);
      if (site_visit)
        dispatch(downloadSiteVisit(siteVisitId, site_visit));
      else
        navigate('/site_visit?show_tab=recent')
    }
  }, [searchParams, site_visits]);

  const recentSiteVisits = () => {
    return site_visits.map((site_visit, index) => {
      return (
        <ListGroup.Item
          key={site_visit.id}
          as="li"
          className="d-flex align-items-start  "
        >
          <div className="text-start w-100 ps-2">
            <div className="fw-bold d-flex justify-content-between align-items-center">
              <div>
                {index + 1}. {""}
                <Link
                  className="me-5 d-inline-block text-decoration-none"
                  to={`/site_visit/edit/${site_visit.id}`}
                  onClick={() => showFormTab("home")}
                >
                  {site_visit.project_name? site_visit.project_name : new Date(site_visit.created).toISOString()}__Site_Visit{" "}
                </Link>
              </div>
              <div className="d-flex flex-column">
                <Link
                  className="me-5 d-inline-block text-decoration-none"
                  to={`/site_visit/edit/${site_visit.id}`}
                  onClick={() => showFormTab("home")}
                >
                  Edit
                </Link>
                <Button
                  style={{ color: "#fff" }}
                  className="d-inline-block btn-block btn-danger me-3 my-3"
                  onClick={(e) => handleDelete(e, site_visit.id)}
                >
                  Delete
                </Button>

                <Button
                  className="d-inline-block btn-block "
                  onClick={(e) => handleDownload(e, site_visit.id, site_visit)}
                >
                  Download
                </Button>
              </div>
            </div>
            <div className="text-start">
              <span className="fw-bolder">create date:</span>{" "}
              {new Date(site_visit.modified).toUTCString().slice(0, 16)}
            </div>
          </div>
        </ListGroup.Item>
      );
    });
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <ListGroup as="ul">
          {user ? (
            <>
              {site_visits.length ? (
                recentSiteVisits()
              ) : (
                <h4>Nothing to show here, create a new site visit!</h4>
              )}
            </>
          ) : (
            <div>
              <Button variant="primary" type="submit" onClick={handleSetModal}>
                Login{" "}
              </Button>
              <h4>to see Recent Site Visits</h4>
            </div>
          )}
        </ListGroup>
      )}
    </>
  );
};

export default RecentSiteVisits;
