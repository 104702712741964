import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Form,
  OverlayTrigger,
  Popover,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SITE_VISIT_HEADING_SET_VALUES, SITE_VISIT_SET_VALUES } from "../../store/constants/siteVisitConstants";
import { StyledDocument } from "../ProposalPreviewForm/ProposalPreviewFrom.styled";
import styled from "styled-components";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import LoginModal from "../LoginModal";
import SubscriptionModal from "../subscriptionModal";
import { StyledInput } from "../StyledInputWithLoader";
import { useSearchParams } from "react-router-dom";

const StyledTable = styled(Table)`
  vertical-align: middle;
  text-align: start;
  padding: 5px;
  input {
    border: none;
  }
  textarea {
    border: none;
  }
`;

export const SiteVisitForm = ({ setPaymentModalShow, setModalShow }) => {
  const { user } = useSelector((state) => state.auth);
  const values = useSelector((state) => state.siteVisit);
  const headings = useSelector((state) => state.siteVisitHeading);
  const { loading: detailsLoader, site_visit } = useSelector((state) => state.siteVisitDetails);

  const dispatch = useDispatch();

  const handleValueChange = (event) => {
    const { name, value } = event.target;
    if (!user)
      setModalShow(true)
    else if (name.includes("heading"))
      dispatch({
        type: SITE_VISIT_HEADING_SET_VALUES,
        payload: {
          [name]: value
        }
      });
    else
      dispatch({
        type: SITE_VISIT_SET_VALUES,
        payload: {
          [name]: value
        }
      });
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    dispatch({
      type: SITE_VISIT_SET_VALUES,
      payload: {
        [name]: new Date(value).toISOString().split("T")[0]
      }
    });
  }

  useEffect(() => {
    let saved_site_visit = localStorage.getItem("site_visit");
    if (saved_site_visit && saved_site_visit != null) {
      saved_site_visit = JSON.parse("site_visit")
      dispatch({
        type: SITE_VISIT_SET_VALUES,
        payload: saved_site_visit
      })
      localStorage.removeItem("site_visit")
    }

    if(!values?.today_date){
      dispatch({
        type: SITE_VISIT_SET_VALUES,
        payload: {
          today_date: new Date().toISOString().split("T")[0]
        }
      })
    }

  }, []);


  return (
    <Container className="text-start">
        <section className="bg-white p-5">
          <Row>
            <Col sm={9}>
              <StyledInput
                type="text"
                name="main_heading"
                placeholder="Document Title"
                onChange={handleValueChange}
                value={headings?.main_heading}
                style={{ border: 'none', marginLeft: '-5px', fontSize: '18px' }}
              />
            </Col>
            <Col sm={3}>
              <StyledInput
                type="date"
                name="today_date"
                onChange={handleDateChange}
                style={{ paddingTop: "12px", paddingBottom: "12px" }}
                value={values?.today_date}
              />
            </Col>
            <StyledTable bordered className="my-4">
              <tbody className="fs-4">
                <tr>
                  <th>Project Name</th>
                  <td>
                    <StyledInput
                      type="text"
                      name="project_name"
                      onChange={handleValueChange}
                      value={values?.project_name}
                      disabled={values?.disabled}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Project Address</th>
                  <td>
                    <StyledInput
                      type="text"
                      name="project_address"
                      onChange={handleValueChange}
                      value={values?.project_address}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Company</th>
                  <td>
                    <StyledInput
                      type="text"
                      name="company"
                      onChange={handleValueChange}
                      value={values?.company}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Superintendent</th>
                  <td>
                    <StyledInput
                      type="text"
                      name="superintendent"
                      onChange={handleValueChange}
                      value={values?.superintendent}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Project Manager</th>
                  <td>
                    <StyledInput
                      type="text"
                      name="project_manager"
                      onChange={handleValueChange}
                      value={values?.project_manager}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="stories_heading"
                      placeholder="Stories/ number of buildings"
                      onChange={handleValueChange}
                      value={headings?.stories_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    <StyledInput
                      type="text"
                      name="stories"
                      onChange={handleValueChange}
                      value={values?.stories}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Size</th>
                  <td className="d-flex gap-4 align-items-center justify-content-between">
                    <div className="d-flex align-items-center flex-grow-1">
                      <StyledInput
                        type="number"
                        name="square_foot"
                        placeholder="Square foot"
                        onChange={handleValueChange}
                        style={{ width: "auto" }}
                        value={values?.square_foot}
                        loading={detailsLoader}
                      />
                      {values?.square_foot && "Sqft."}
                    </div>
                    <div className="d-flex align-items-center flex-grow-1">
                      <StyledInput
                        type="number"
                        name="units"
                        placeholder="Units"
                        onChange={handleValueChange}
                        style={{ width: "auto" }}
                        value={values?.units}
                        loading={detailsLoader}
                      />
                      {values?.units && "Units"}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="start_date_heading"
                      placeholder="Service start date"
                      onChange={handleValueChange}
                      value={headings?.start_date_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    <StyledInput
                      type="text"
                      name="start_date"
                      onChange={handleValueChange}
                      value={values?.start_date}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="phases_heading"
                      placeholder="Number of Phases"
                      onChange={handleValueChange}
                      value={headings?.phases_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    <StyledInput
                      type="text"
                      name="phases"
                      value={values?.phases}
                      onChange={handleValueChange}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="completion_date_heading"
                      placeholder="Project completion date"
                      onChange={handleValueChange}
                      value={headings?.completion_date_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    <StyledInput
                      type="text"
                      name="completion_date"
                      onChange={handleValueChange}
                      value={values?.completion_date}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="flooring_type_heading"
                      placeholder="Flooring type"
                      onChange={handleValueChange}
                      value={headings?.flooring_type_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    <StyledInput
                      type="text"
                      name="flooring_type"
                      onChange={handleValueChange}
                      value={values?.flooring_type}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="pressure_washing_heading"
                      placeholder="Pressure Washing"
                      onChange={handleValueChange}
                      value={headings?.pressure_washing_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    <StyledInput
                      type="text"
                      name="pressure_washing"
                      onChange={handleValueChange}
                      value={values?.pressure_washing}
                      loading={detailsLoader}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <StyledInput
                      type="text"
                      name="notes_heading"
                      placeholder="Notes"
                      onChange={handleValueChange}
                      value={headings?.notes_heading}
                      style={{ fontWeight: 'bold', paddingLeft: '0' }}
                    />
                  </th>
                  <td>
                    {detailsLoader? (
                      <h4 style={{ paddingInline: '15px', fontSize: '14px' }}>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">
                          Loading...
                        </span>{" "}{"Loading... "}
                      </h4>
                    ) : (
                      <Form.Control
                        as="textarea"
                        name="notes"
                        rows={3}
                        onChange={handleValueChange}
                        style={{
                          fontSize: "14px",
                          paddingTop: "15px",
                        }}
                        value={values?.notes}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          </Row>
        </section>
    </Container>
  );
};
