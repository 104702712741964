import AttachmentIcon from "../../../assets/icons/AttachmentIcon";
import SelectInput from "../../input/SelectInput";
import Button from "../../../components/button/Button";
import EmailEditor from "../../quill/EmailEditor";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emailTemplate,
  emailTemplateError,
} from "../../../store/selectors/projects/project.selector";
import { sendEmailToContactAPI } from "../../../utils/requests/projects";
import { toastError, toastSuccess } from "../../../utils/helpers/helper";
import DomainVerificationModal from "../../DomainVerificationModal";
import { getEmailTemplate } from "../../../store/actions/projects/projects.action";
import { Alert, FormControl, FormGroup, FormLabel, Row } from "react-bootstrap";

const SendMassEmailForm = ({
  emailTemplates,
  contact,
  handleCloseEmailModal,
  selectedProjects,
  handleLoadProjectByEvent,
  emailContent,
  subjectContent,
  setEmailContent,
  setSubjectContent,
  emailTemplateBody,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const templateError = useSelector(emailTemplateError());

  const { user } = useSelector((state) => state.userDetails);
  const [verificationModalShow, setVerificationModalShow] = useState(false);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  const reformatEmailText = (string) => {
    const splitedString = string?.split("\r\n");
    const newString = splitedString?.map((string) => `<p>${string}<br></p>`);
    return newString?.join("");
  };

  const emailContentHandler = (content) => {
    setEmailContent(content);
  };

  const handleSelectedTemplate = (templateId) => {
    const template = emailTemplates.find(
      (template) => template.id === templateId
    );
    setSelectedTemplate(template);
    // handleSelectedTemplate(template.id, contact);
  };

  const selectedTemplateHandler = (templateId) => {
    if (selectedProjects.length === 0) {
      toastError("No project selected", {
        autoClose: false,
      });
      return;
    }
    const template = emailTemplates.find(
      (template) => template.id === templateId
    );
    setSelectedTemplate(template);
    const query = `?project_id=${selectedProjects[0]}&email_type=mass_email`;
    dispatch(getEmailTemplate(templateId, query));
  };

  const subjectChangeHandler = (e) => {
    setSubjectContent(e.target.value);
  };
  const sendEmailHandler = (
    contact,
    selectedTemplate,
    subject,
    body,
    handleCloseEmailModal
  ) => {
    if (!user?.is_domain_verify) {
      setVerificationModalShow(true);
      return;
    }
    if (selectedProjects.length === 0) {
      toastError("No project selected", {
        autoClose: false,
      });
      return;
    }
    // const data = {
    //   temp_id: selectedTemplate?.id,
    //   company_id: contact?.company_id,
    //   contact_id: contact?.id,
    //   subject: subject,
    //   body: body,
    //   project_ids: selectedProjects,
    //   email_type: "mass_email",
    // };

    let formData = new FormData();
    formData.append("temp_id", selectedTemplate?.id);
    formData.append("company_id", contact?.company_id);
    formData.append("contact_id", contact?.id);
    formData.append("subject", subject);
    formData.append("body", body);
    formData.append("body", body);
    formData.append("project_ids", selectedProjects);
    formData.append("email_type", "mass_email");
    formData.append("image", image);
    sendEmailToContactAPI(selectedTemplate?.id, formData)
      .then(() => {
        toastSuccess("Email Sent Successfully");
        handleCloseEmailModal(true);
        handleLoadProjectByEvent();

        // getContactRoles();
      })
      .catch((error) => {
        toastError(error.data?.error || "something went wrong", {
          autoClose: false,
        });
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 5485760) {
      setImage(file);
    } else {
      alert("File size exceeds 5MB limit");
    }
  };

  return (
    <div>
      <div className="">
        <SelectInput
          placeHolder="Select a template"
          selectedItem={selectedTemplate?.name}
        >
          {emailTemplates.map((template) => (
            <div
              key={template.id}
              onClick={() => selectedTemplateHandler(template.id)}
              className="cursor-pointer select-input-item py-2 ps-4"
            >
              {template.name}
            </div>
          ))}
        </SelectInput>
        <div className="text-start text-danger text-10">
          {templateError ? templateError : ""}
        </div>
      </div>
      <div className="mt-5">
        <label htmlFor="subject" className="form-label">
          Subject
        </label>
        <input
          type="text"
          id="subject"
          className="form-control py-2"
          value={subjectContent}
          onChange={subjectChangeHandler}
        />
      </div>
      <div className="mt-5">
        <EmailEditor
          emailBody={reformatEmailText(emailContent)}
          handleEmailContent={emailContentHandler}
        />
      </div>
      <div className="d-flex justify-content-between">
        <FormGroup className="me-5 col-5">
          <FormLabel>Attach Image (up to 5MB)</FormLabel>
          <FormControl type="file" onChange={handleFileChange} />
        </FormGroup>
        {/*<Button customClassName="btn btn-outline-secondary-intel btn-small py-2 mt-3 px-3 ">*/}
        {/*  <AttachmentIcon*/}
        {/*    fill="currentColor"*/}
        {/*    className="me-2"*/}
        {/*    width="12"*/}
        {/*    height="18"*/}
        {/*  />*/}
        {/*  Attach Files*/}
        {/*</Button>*/}
        <Button
          onClick={() =>
            sendEmailHandler(
              contact,
              selectedTemplate,
              emailTemplateBody?.subject,
              emailContent,
              handleCloseEmailModal
            )
          }
          customClassName="btn btn-primary btn-small py-2 mt-3 px-3 text-white"
        >
          Send Email
        </Button>
      </div>
      <Row>
        <Alert variant="info" className="mt-2">
          The constant fields will be automatically merged with your data.
        </Alert>
      </Row>
      <DomainVerificationModal
        show={verificationModalShow}
        onHide={() => setVerificationModalShow(false)}
      />
    </div>
  );
};

export default SendMassEmailForm;
