import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./ProjectBoard.css";
import Tabs from "../../components/tabs/Tabs";
import { tabsData } from "./data/tabsData";
import Description from "./components/Description";
import DecisionMakers from "./components/DecisionMakers";
import ImportantDates from "./components/ImportantDates";
import Company from "./components/Company";
import {
  archiveProject,
  favouriteProject,
  fetchSingleProject,
  getEmailTemplate,
  projectVisit,
  unArchiveProject,
  unFavouriteProject,
  updateProjectContactLastDate,
} from "../../store/actions/projects/projects.action";
import Spinner from "../../components/spinner/Spinner";
import ArrowBack from "../../assets/icons/ArrowBack";
import FileIcon from "../../assets/icons/FileIcon";
import SelectInput from "../../components/input/SelectInput";
import SingleMap from "../../components/map/SingleMap";
import imageMarker from "../../assets/img/marker_new2.png";
import ProjectProgress from "../../components/projectProgress/ProjectProgress";
import FullModal from "../../components/modal/FullModal";
import ProjectProgressBar from "../../components/projectProgress/ProjectProgressBar";
import SimilarProject from "./components/SimilarProject";
import { classNames } from "../../components/helpers/helpers";
import { saveCallLog } from "../../utils/requests/callLogs";
import { getDefaultProjectType, isSubscriptionActive, toastSuccess } from "../../utils/helpers/helper";
import {
  getHotScopesAPI,
  saveProjectStatusAPI,
} from "../../utils/requests/projects";
import SubscriptionModal from "../../components/subscriptionModal";
import {
  createPageView,
  getUserDetail,
  setFreeModeAction,
} from "../../store/actions/users/users.actions";
import { USER_SET_FREE_MODE_SUCCESS } from "../../store/constants/userConstants";
import { TOTAL_FREE_MODE_COUNT } from "../../utils/constants/api";
import LoginModal from "../../components/LoginModal";

import { getContactRolesAPI } from "../../utils/requests/callLogs";
import FavouriteIcon from "../../assets/icons/FavouriteIcon";
import FavouriteStrokeIcon from "../../assets/icons/FavouriteStrokeIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFile, faHeart } from "@fortawesome/free-solid-svg-icons";
import Comments from "./components/Comments";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import dayjs from "dayjs";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import { FaInfoCircle } from "react-icons/fa";
import { DateTag } from "../../components/DateTagProjectTable/DateTag";
import { StyledInput } from "../../components/StyledInputWithLoader";

const sortItems = [
  {
    id: 1,
    key: "last_modified_date",
    value: "updated",
  },
  {
    id: 2,
    key: "created_date",
    value: "newest",
  },
  {
    id: 3,
    key: "bid_due_date",
    value: "Bid due date",
  },
  {
    id: 4,
    key: "sf_size",
    value: "Square Feet",
  },
  {
    id: 5,
    key: "project_completion",
    value: "Near completion",
  },
  {
    id: 6,
    key: "plan_drawings",
    value: "Plans Drawings",
  },
  {
    id: 7,
    key: "public_works",
    value: "Public works",
  },
];

const __projectStatus = [
  {
    id: 1,
    key: "ND",
    value: "Not decided yet",
  },
  {
    id: 2,
    key: "PC",
    value: "Pursuing contract",
  },
  {
    id: 3,
    key: "FU",
    value: "Flag as unavailable",
  },
];
const StyledSwitch = styled(Form.Check)`
  .custom-toggle .form-check-input {
    // Increase the width to accommodate the text "Available" / "Not Available"
    width: 120px; // Adjust this width based on your actual needs
    height: 2rem; // Also adjust the height if necessary
    font-weight: bold;
    font-size: 0.8em;
    &:checked {
      background-color: #0d6efd; // Bootstrap primary color or any color you like
      &:after {
        content: "Available";
        color: white; // Text color when switch is active
        padding-left: 32px; // Adjust the padding to position the text correctly
      }
    }
    &:not(:checked) {
      background-color: red; // A gray color for the off state
      &:after {
        content: "Not Available";
        color: white; // Text color when switch is inactive
        padding-left: 25px; // Adjust the padding to position the text correctly
      }
    }
    // This will move the toggle's indicator to the opposite side
    &:checked:before {
      right: -20px; // Adjust this value as needed
    }
    &:not(:checked):before {
      left: -20px; // Adjust this value as needed
    }
  }
`;

const tagsHelperText = {
  "Updated this week": "New information added in the last 7 days",
  "New this week": "Created within last 7 days",
  "Plans Drawings": "Architectural plans available for download",
  "Large area": "Between 150,001 and 500,000 SQFT",
  "Extra large area": "Between 500,001 and 3,800,000 SQFT",
  "Medium area": "Between 31,001 and 150,000 SQFT",
  "Extra small area": "Between 1 and 4,000 SQFT",
  "Small area": "Between 4,001 and 31,000 SQFT",
  "Pre construction":
    "Two weeks before the pre-construction bid due date and two weeks after",
  "Breaking ground now":
    "Sixty days before and sixty days after the estimated ground breaking date",
  "Completing soon": "Sixty days prior to and following the completion date",
  "Under construction":
    "From 61 days post-groundbreaking to 61 days before completion, including erecting, installing, and assembling structural, mechanical, and architectural components.",
};

function toLocalISOString(date_str) {
  const date = new Date(date_str);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const ProjectBoard = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const [selectedSortItem, setSelectedSortItem] = useState();
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [selectedStage, setSelectedStage] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState(
    __projectStatus[0]
  );
  const [hotScopes, setHotScopes] = useState([]);
  const [selectedHotScope, setSelectedHotScope] = useState();
  const [tradeEstimate, setTradeEstimate] = useState(0.0);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user } = useSelector((state) => state.auth);
  const { user: profile } = useSelector((state) => state.userDetails);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const price_id = process.env.REACT_APP_PROJECT_APP;
  const [contactCount, setContactCount] = useState(0);
  const [contactRoles, setContactRoles] = useState([]);
  const [contactActivities, setContactActivities] = useState([]);
  const [updatedLastVisit, setUpdatedLastVisit] = useState(false)
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Projects");

  const { projectSlug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAvailable, setIsAvailable] = useState(false);

  const { loading, singleProject, status, loadingProjectDate, last_delete_activity, last_create_activity } = useSelector(
    (state) => state.projects
  );
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [last_contact_date, setLastContactDate] = useState("")

  useCallback(() => {
    handleSetActiveTab();
  }, []);

  useEffect(() => {
    let didUpdate = false;

    singleProject?.last_contact_date && singleProject.last_contact_date != null ?
    setLastContactDate(toLocalISOString(singleProject?.last_contact_date)):
    setLastContactDate(null);

    if (singleProject?.id && !updatedLastVisit && !didUpdate) {
      setUpdatedLastVisit(true);
      setTimeout(function (){
        dispatch(projectVisit(singleProject?.id))
      }, 500);
      didUpdate = true;
    }

    return () => {
      didUpdate = true;
    };

  }, [singleProject?.id])

  useEffect(() => {
    if (last_delete_activity)
      setContactActivities(contactActivities.filter(cnt => cnt.id != last_delete_activity))
  }, [last_delete_activity])

  useEffect(() => {
    if (last_create_activity)
      setContactActivities([last_create_activity, ...contactActivities])
  }, [last_create_activity])

  const handleUpdateProjectLastDate = (date) => {
    setLastContactDate(date)
  }

  const handleSetActiveTab = (tabName) => {
    setActiveTab(tabName);
  };
  const handleSetFreeMode = () => {
    dispatch(setFreeModeAction());
    dispatch({
      type: USER_SET_FREE_MODE_SUCCESS,
      payload: free_mode_count + 1,
    });
  };

  const getContactRoles = () => {
    if (singleProject) {
      getContactRolesAPI(singleProject?.id)
        .then((response) => {
          setContactRoles(response?.info);
          setContactCount(response?.info?.length);
          setContactActivities(response?.activities)
        })
        .catch(() => {});
    }
    return;
  };

  const path = window.location.pathname.slice(1);
  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch]);
  useEffect(() => {
    const pageRecord = singleProject?.name;

    const type = "Project";

    if (pageRecord) {
      dispatch(createPageView(pageRecord, path, type));
      setUpdatedLastVisit(false)
    }
  }, [singleProject?.name]);

  useEffect(() => {
    getContactRoles();
    return function cleanup() {
      // setContactRoles([]);
      setContactCount(0);
    };
  }, [singleProject]);

  useEffect(() => {
    if (!user) {
      setLoginModalShow(true);
    } else {
      if (!hasFeatureAccess) {
        enforceAccess();
      } else {
        // dispatch(getUserDetail());
        dispatch(setFreeModeAction());
        dispatch({
          type: USER_SET_FREE_MODE_SUCCESS,
          payload: free_mode_count + 1,
        });
      }
    }
    // setIsInitialLoad(true);
    // setIsAvailable(true);

    dispatch(fetchSingleProject(projectSlug));

    getHotScopes();
  }, [dispatch, projectSlug, singleProject?.id]);

  const handleSelectedStage = (stage) => {
    setSelectedStage(stage);
    setShowProgressModal(true);
  };

  const handleCallLogs = (type, contactId, closeModal) => {
    const data = {
      activity: type,
      contact_role_id: contactId,
    };
    saveCallLog(singleProject?.id, data)
      .then(() => {
        closeModal(false);
        toastSuccess("Call recorded successfully");
      })
      .catch(() => {});

    return;
  };

  const handleProjectStatus = (status) => {
    const data = {
      status: status,
      project_id: singleProject?.id,
    };
    saveProjectStatusAPI(data)
      .then((response) => {
        toastSuccess("Project status updated");
      })
      .catch(() => {});

    return;
  };

  const handleProjectStatusUpdate = (e) => {
    if (e.target.checked) {
      handleArchive(e, "unhide", singleProject?.id);
    } else {
      handleArchive(e, "hide", singleProject?.id);
    }
  };

  const selectedTemplateHandler = (templateId, contact) => {
    const { company_id, name } = contact;
    const query = `?project_id=${singleProject?.id}&company_id=${company_id}&contact_name=${name}`;
    dispatch(getEmailTemplate(templateId, query));
  };

  const getHotScopes = () => {
    getHotScopesAPI()
      .then((response) => {
        setHotScopes(response);
      })
      .catch(() => {});

    return;
  };

  const handleSelectedScope = (scope) => {
    setSelectedHotScope(scope);
    if (singleProject?.sf_size === "None" || !singleProject?.sf_size) {
      setTradeEstimate(0.0);
      return;
    }
    const estimate =
      parseFloat(singleProject?.sf_size) * parseFloat(scope.amount);
    setTradeEstimate(estimate.toFixed(2));
  };

  const createMap = () => {
    return (
      <SingleMap
        id="singleMap"
        options={{
          center: {
            lat: parseFloat(singleProject?.latitude),
            lng: parseFloat(singleProject?.longitude),
          },
          zoom: 8,
          mapTypeControl: false,
          // draggable: false
        }}
        onMapLoad={(map) => {
          if (singleProject) {
            new window.google.maps.Marker({
              position: {
                lat: parseFloat(singleProject?.latitude),
                lng: parseFloat(singleProject?.longitude),
              },
              map: map,
              title: singleProject?.name || "N/A",
              icon: imageMarker,
            });
          }
        }}
        project={singleProject}
      />
    );
  };

  const handleFavourite = (e, type, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!hasFeatureAccess) {
      enforceAccess();
    } else {
      dispatch(getUserDetail());
      if (type === "favourite") {
        dispatch(favouriteProject(id));
        return;
      }
      dispatch(unFavouriteProject(id));
    }
  };

  const handleArchive = (e, type, id) => {
    dispatch(getUserDetail());
    if (type === "hide") {
      dispatch(archiveProject(id, e.target.checked));
    } else {
      dispatch(unArchiveProject(id, e.target.checked));
    }
  };

  const checkFav = (id) => {
    const isFavourite = profile?.project_favorites?.find(
      (project) => project?.id === id
    );
    return !!isFavourite;
  };

  const checkHidden = () => {
    const isHidden = profile?.project_archives?.find((project) => {
      return project?.id === singleProject?.id;
    });

    return !!isHidden;
  };

  if (loading) {
    return (
      <div className="vh-100 justify-content-center align-items-center d-flex">
        <Spinner />
      </div>
    );
  }

  const openCleaningEstimateWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const { sf_size, state, project_type, name, units, url_slug } =
      singleProject || {};
    const url = `/cleanup_calculator?square_foot=${sf_size || ""}&state=${
      state || ""
    }&project_type=${project_type || ""}&project_name=${
      name.replaceAll("#", "%23").replaceAll("&", "%26") || ""
    }&living_unit=${
      units || ""
    }&url_slug=${url_slug}&davis=${singleProject?.tags.includes(
      "Davis"
    )}&union=${singleProject?.tags.includes("Union requirements")}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    const newWindow = window.open(url, "_blank", windowFeatures);
  };

  const openPaintingProposalWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const { sf_size, state, project_type, name, units, url_slug } =
      singleProject || {};
    const url = `/paint_proposal/?project_name=${name.replaceAll("#", "%23").replaceAll("&", "%26")}&state=${state}&url_slug=${url_slug}&davis=${singleProject?.tags.includes(
      "Davis"
    )}&union=${singleProject?.tags.includes("Union requirements")}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    const newWindow = window.open(url, "_blank", windowFeatures);
  };

  const openSiteVisitWindow = () => {
    const width = 1200; // Width of the new window
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;

    const {
      name,
      address,
      city,
      state,
      account_name,
      sf_size,
      units,
      primary_contact_name,
      primary_contact_phone,
      site_contact_name,
      site_contact_phone,
    } = singleProject;

    const project_address = (address || address != null ? address : '') + (city ? ", " + city : "") + (state ? ", " + state : "");
    const url = `/site_visit?project_name=${encodeURIComponent(
      name
    )}&project_address=${encodeURIComponent(
      project_address
    )}&company=${encodeURIComponent(
      account_name != null ? account_name : ""
    )}&superintendent=${encodeURIComponent(
      (site_contact_name != null ? site_contact_name : "") +
        (site_contact_phone
          ? ` (${site_contact_phone.replaceAll("(", "").replaceAll(")", "")})`
          : "") || ""
    )}&project_manager=${encodeURIComponent(
      primary_contact_name +
        (primary_contact_phone
          ? ` (${primary_contact_phone
              .replaceAll("(", "")
              .replaceAll(")", "")})`
          : "") || ""
    )}&square_foot=${encodeURIComponent(sf_size || "")}&units=${
      units || ""
    }&disabled=true`;

    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},menubar=no,toolbar=no,location=no,status=no,scrollbars=yes`;

    const newWindow = window.open(url, "_blank", windowFeatures);
  };

  const completionBefore = dayjs().isBefore(
    dayjs(singleProject?.project_completion).format("YYYY-MM-DD")
  );
  const workProgressBefore = dayjs().isBefore(
    dayjs(singleProject?.est_break_ground_date).format("YYYY-MM-DD")
  );

  const completionDuration = completionBefore
    ? `complete ${dayjs(singleProject?.project_completion).fromNow()}`
    : `completed ${dayjs(singleProject?.project_completion).fromNow()}`;

  const workProgressDuration = workProgressBefore
    ? `start ${dayjs(singleProject?.est_break_ground_date).fromNow()}`
    : `started ${dayjs(singleProject?.est_break_ground_date).fromNow()}`;
  const isSwitchButton = localStorage.getItem(`isSwitchButton_${user?.id}`)
    ? !JSON.parse(localStorage.getItem(`isSwitchButton_${user?.id}`))
    : localStorage.setItem(`isSwitchButton_${user?.id}`, "false") ||
      localStorage.setItem(`ganarpro_app${user?.id}`, "cleaning");

  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);

  const handleDateChange = (e) => {
    // setLastContactDate(e.target.value);
    const date = e.target.value;
    const today = date ? new Date(date).toISOString() : null;
    setLastContactDate(date ? toLocalISOString(today) : null);
    dispatch(updateProjectContactLastDate(date ? today.slice(0, 16) : null, singleProject?.id));
  };

  return (
    <div>
      {/*<section className="mt-5 project-stage-section">*/}
      {/*  <ProjectProgressBar*/}
      {/*    {...singleProject}*/}
      {/*    setShowProgressModal={handleSelectedStage}*/}
      {/*  />*/}
      {/*  <FullModal*/}
      {/*    title="New message to Aaron Johnson"*/}
      {/*    show={showProgressModal}*/}
      {/*    onCloseModal={() => setShowProgressModal(false)}*/}
      {/*  >*/}
      {/*    <ProjectProgress {...singleProject} progressClicked={selectedStage} />*/}
      {/*  </FullModal>*/}
      {/*</section>*/}
      {/*<div className="border-top mx-3 mt-5"></div>*/}
      <Row className="">
        <Col md={6}>
          <Row className="justify-content-around">
            <Col md={3} className="col-6">
              <p className=" mt-2">
                {singleProject?.est_break_ground_date ? (
                  <>
                    <br />
                    <span className="fw-bolder text-primary">
                      {workProgressDuration}
                    </span>{" "}
                  </>
                ) : (
                  ""
                )}
              </p>
            </Col>
            <Col md={3} className="col-6">
              <p className="mt-2">
                {singleProject?.project_completion ? (
                  <>
                    <br />
                    <span className="fw-bolder text-primary">
                      {completionDuration}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="row project-board-row">
        <div className="col-xs-12 col-sm-8 px-md-4 mb-md-5">
          <section className="mt-4 mx-3">
            <div className="row d-flex">
              <div className="d-flex col-sm-12 col-lg-7 justify-content-center justify-content-md-start mt-3">
                <Link
                  to=""
                  className="text-primary text-decoration-none align-self-center me-4"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack
                    fill="#276FB4"
                    git
                    width="19"
                    height="16"
                    className="me-3"
                  />
                  <div className="d-none d-md-block">
                    Back to Project <br />
                    Search
                  </div>
                </Link>
                <div className="d-sm-flex text-md-start d-md-grid col-xs-12 col-md-8 justify-content-sm-center justify-content-md-start mt-4 mb-3">
                  <h4 className="fw-bold heading-3">{singleProject?.name}</h4>
                  <h6 className="body-text">
                    by{" "}
                    <span className="text-primary">
                      {singleProject?.account_name}
                    </span>
                  </h6>
                </div>
              </div>
              <div className="d-flex flex-column col-sm-12 col-lg-5 gap-2 text-nowrap justify-content-center">
                {singleProject?.last_visit_date &&
                  <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-2">
                    <div>Last Visit</div>
                      <StyledInput
                        type="datetime-local"
                        name="today_date"
                        disabled
                        style={{ width: 'max-content', padding: '8px', backgroundColor: 'white', border: 'none' }}
                        value={toLocalISOString(singleProject?.last_visit_date)}
                      />
                  </div>
                }
                <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-2">
                  <div>Last Contact</div>
                    <StyledInput
                      type="datetime-local"
                      name="today_date"
                      onChange={handleDateChange}
                      style={{ width: 'max-content', padding: '8px' }}
                      value={last_contact_date}
                      loading={loadingProjectDate}
                    />
                </div>
              </div>
              <Row className="my-5">
                <Col>
                  <div className="d-flex flex-wrap mt-2">
                    {singleProject?.tags.split(",").map((tag) => (
                      <div
                        className="p-2 m-1"
                        style={{ background: "#70B2F0", borderRadius: "10px" }}
                      >
                        {tag === "Union requirements" || tag === "Davis Bacon" ? (
                          <div>{tag}</div>
                        ) : (
                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                              <Tooltip className="tooltip">
                                {tagsHelperText[tag]}
                              </Tooltip>
                            }
                          >
                            <div>{tag}</div>
                          </OverlayTrigger>
                        )}
                      </div>
                    ))}
                  </div>
                </Col>
                <Col sm="12" className="d-md-none">
                  <p></p>
                </Col>
                <Col sm="12" md="5" lg="4">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "40px",
                    }}
                  >
                    <div style={{ marginRight: "40px" }}>
                      {checkFav(singleProject?.id) ? (
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="cursor-pointer"
                          size="xl"
                          style={{ color: "#0d6efd" }}
                          onClick={(e) =>
                            handleFavourite(e, "unfavourite", singleProject?.id)
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="cursor-pointer"
                          size="xl"
                          style={{
                            color: "#fff",
                            stroke: "#0d6efd",
                            strokeWidth: 50,
                          }}
                          onClick={(e) =>
                            handleFavourite(e, "favourite", singleProject?.id)
                          }
                        />
                      )}
                    </div>
                    <div>
                      {user && profile?.project_archives && (
                        <StyledSwitch>
                          <Form.Check
                            type="switch"
                            id="availability-switch"
                            className="custom-toggle ms-3 fw-bolder"
                            checked={status}
                            onChange={(e) => handleProjectStatusUpdate(e)}
                          />
                        </StyledSwitch>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <div className="row justify-content-md-end  justify-content-center">
                <div className="col-md-1 col-3 col-sm-3"></div>

                <div className="col-md-6 col-3 col-sm-3"></div>
              </div> */}
            </div>
          </section>
          <Row className="justify-content-end mx-2">
            <Col className="col-md-7">
              <div className="d-flex flex-row align-content-center justify-content-end" style={{ gap: '5px' }}>
                {singleProject?.estimate_id &&
                  <DateTag name="Estimate. " dateTime={singleProject.estimate_date} color="#249d5c" onClick={() => navigate('/cleanup_calculator/edit/'+singleProject.estimate_id)}/>
                }
                {singleProject?.proposal_id &&
                  <DateTag name="Proposal. " dateTime={singleProject.proposal_date} color="#58a5ec" onClick={() => navigate('/my_proposal/edit/'+singleProject.proposal_id)}/>
                }
              </div>
            </Col>
            <Col className="col-md-5 text-end">
              {!'business'?.includes(ganarpro_app) ? (
                <Button
                  className="p-3 m-2 btn btn-light"
                  style={{ backgroundColor: "#ebebeb" }}
                  onClick={openSiteVisitWindow}
                >
                  <span className="h4">Create Site Visit Sheet</span>
                </Button>
              ) : ''}
              {['cleaning', 'basic_cleaning'].includes(ganarpro_app) ? (
                <>
                  <Button
                    className="p-3 btn btn-light"
                    style={{ backgroundColor: "#ebebeb" }}
                    onClick={openCleaningEstimateWindow}
                  >
                    <span className="h4">Create Cleaning Proposal</span>
                  </Button>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        <ol style={{ textAlign: "start" }}>
                          <li>Calculate a bid amount</li>
                          <li>From Calculator, create a Proposal</li>
                          <li>Download word document Proposal</li>
                          <li>
                            Send proposal directly from your email or through
                            Ganarpro
                          </li>
                        </ol>
                      </Tooltip>
                    }
                  >
                    <span className="info-span">
                      <FaInfoCircle />
                    </span>
                  </OverlayTrigger>
                </>
              ) : ganarpro_app.includes("paint") ? (
                <>
                  <Button
                    className="p-3 btn btn-light"
                    style={{ backgroundColor: "#ebebeb" }}
                    onClick={openPaintingProposalWindow}
                  >
                    <span className="h4">Create Painting Proposal</span>
                  </Button>
                </>
              ) : ''}
            </Col>
          </Row>

          <section className="mt-3 mb-5">
            <div className="box-shadow-gray mx-2 px-5 py-5 mb-md-5 bg-white">
              <div className="d-flex justify-content-between overflow-scroll mb-5">
                {tabsData.map((tab, index) => (
                  <Tabs
                    key={index + 1}
                    setActiveTab={handleSetActiveTab}
                    name={tab}
                    title={tab}
                    activeTab={activeTab}
                    contactCount={contactCount}
                  />
                ))}
              </div>
              <div className="tab-content">
                {activeTab === tabsData[0] ? (
                  <div className="tab-content">
                    <Description {...singleProject} />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[1] ? (
                  <div className="tab-content">
                    <DecisionMakers
                      contacts={singleProject?.contact_roles}
                      callLogHandler={handleCallLogs}
                      emailTemplates={singleProject?.email_templates}
                      projectId={singleProject?.id}
                      handleSelectedTemplate={selectedTemplateHandler}
                      setContactCount={setContactCount}
                      contactRoles={contactRoles}
                      contactActivities={contactActivities}
                      getContactRoles={getContactRoles}
                      projectName={singleProject?.name}
                      account_phone={singleProject?.account_phone}
                      updateProjectLastDate={handleUpdateProjectLastDate}
                    />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[2] ? (
                  <div className="tab-content">
                    <ImportantDates {...singleProject} />
                  </div>
                ) : (
                  ""
                )}
                {activeTab === tabsData[3] ? (
                  <div className="tab-content">
                    <Company {...singleProject} />
                  </div>
                ) : (
                  ""
                )}
                {/*{activeTab === tabsData[4] ? (*/}
                {/*  <div className="tab-content">*/}
                {/*    <Activities projectId={singleProject?.id} />*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  ""*/}
                {/*)}*/}
                {activeTab === tabsData[4] ? (
                  <div className="tab-content">
                    <Comments projectId={singleProject?.id} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="d-flex border-top mt-5 flex-column-reverse flex-md-row">
                {/*<div className="mt-3 mt-md-0 flex-grow-1 d-flex">*/}
                {/*  <div className="board-action d-flex justify-content-between px-2 align-self-center">*/}
                {/*    <span className="me-3 svg-archive cursor-pointer">*/}
                {/*      <NotificationIcon*/}
                {/*        width="24"*/}
                {/*        height="24"*/}
                {/*        fill="currentColor"*/}
                {/*        className="cursor-pointer svg-archive"*/}
                {/*        handleClick={() => handleFavourite("unfavourite")}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*    <span>*/}
                {/*      <DislikeIcon*/}
                {/*        width="24"*/}
                {/*        height="24"*/}
                {/*        handleClick={handleArchive}*/}
                {/*        className="ms-4 cursor-pointer svg-archive"*/}
                {/*        fill="currentColor"*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="mt-4 mb-4 d-md-flex flex-md-row-reverse py-3">*/}
                {/*  /!*<Button*!/*/}
                {/*  /!*  customClassName="btn btn-primary btn-small ms-3"*!/*/}
                {/*  /!*  onClick={() => {}}*!/*/}
                {/*  /!*>*!/*/}
                {/*  /!*  Check Availability*!/*/}
                {/*  /!*</Button>*!/*/}
                {/*  /!*<div className="ms-lg-4 project-status-drop-down">*!/*/}
                {/*  /!*  <SelectInput*!/*/}
                {/*  /!*    placeHolder="Check Availability"*!/*/}
                {/*  /!*    selectedItem={selectedProjectStatus.value}*!/*/}
                {/*  /!*  >*!/*/}
                {/*  /!*    {__projectStatus.map((item) => (*!/*/}
                {/*  /!*      <div*!/*/}
                {/*  /!*        key={item.id}*!/*/}
                {/*  /!*        onClick={() => handleSelectedProjectStatus(item.id)}*!/*/}
                {/*  /!*        className={classNames(*!/*/}
                {/*  /!*          "cursor-pointer select-input-item py-2 ps-4",*!/*/}
                {/*  /!*          item.key === "FU" ? "text-danger" : "",*!/*/}
                {/*  /!*          item.key === "ND" ? "text-black" : "",*!/*/}
                {/*  /!*          item.key === "PC" ? "text-success" : ""*!/*/}
                {/*  /!*        )}*!/*/}
                {/*  /!*      >*!/*/}
                {/*  /!*        {item.value}*!/*/}
                {/*  /!*      </div>*!/*/}
                {/*  /!*    ))}*!/*/}
                {/*  /!*  </SelectInput>*!/*/}
                {/*  /!*</div>*!/*/}
                {/*</div>*/}
              </div>
            </div>
          </section>

          {/*<section>*/}
          {/*  <div className="bg-white px-5 py-5 mx-2">*/}
          {/*    <div className="d-md-flex mt-4 justify-content-center">*/}
          {/*      <p className="mt-4 me-md-4 border-bottom border-primary ic-trade">*/}
          {/*        IC TradeEstimate*/}
          {/*      </p>*/}
          {/*      <div className="d-flex align-items-center">*/}
          {/*        <div className="col-8">*/}
          {/*          <div className="hotScope">*/}
          {/*            <SelectInput*/}
          {/*              placeHolder="Select Your Trade"*/}
          {/*              selectedItem={selectedHotScope?.name}*/}
          {/*            >*/}
          {/*              <div className="hotScope-container">*/}
          {/*                {hotScopes.map((scope, index) => (*/}
          {/*                  <div*/}
          {/*                    key={index + 1}*/}
          {/*                    onClick={() => handleSelectedScope(scope)}*/}
          {/*                    className="cursor-pointer select-input-item py-2 ps-4"*/}
          {/*                  >*/}
          {/*                    {scope.name}*/}
          {/*                  </div>*/}
          {/*                ))}*/}
          {/*              </div>*/}
          {/*            </SelectInput>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*        <div className="ms-1 col-4">*/}
          {/*          <div className="price-input rounded-3 px-3 py-2 text-start">*/}
          {/*            $ {Intl.NumberFormat('en-US').format(tradeEstimate)}*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          <section className="mt-4">
            <div>
              <div className="box-shadow-gray py-2 px-2 mx-2 bg-white">
                <div>{singleProject ? createMap() : ""}</div>
              </div>
            </div>
          </section>
        </div>
        <section className="mt-5 mb-5 col-xs-12 col-sm-4">
          <div className="box-shadow-gray mx-2 px-4 py-3 bg-white">
            <div>
              <Link
                className="text-decoration-none d-flex align-items-center justify-content-between"
                to={`/myganarpro/favorites`}
              >
                <Button
                  style={{
                    color: "rgb(13, 110, 253)",
                    backgroundColor: "transparent",
                    border: "none",
                    fontSize: "16px",
                  }}
                  className="btn"
                >
                  Manage Favorites
                </Button>
              </Link>
              <div className="d-flex align-items-center justify-content-between my-5">
                <h4 className="m-0 lead-text black-100">Similar Projects</h4>
                <div className="d-flex align-items-center"></div>
              </div>
            </div>
            {singleProject?.similar_projects.length ? (
              singleProject?.similar_projects.map((project, index) => (
                <SimilarProject key={index + 1} {...project} />
              ))
            ) : (
              <div className="no-records">
                <FileIcon
                  width="30%"
                  height="30%"
                  className="text-center"
                  fill="#70B2F0"
                />
                <p className="mt-4 text-13">No project</p>
              </div>
            )}
          </div>
        </section>
      </div>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        closeButton={false}
      />
      <SubscriptionModal
        backdrop="static"
        size="lg"
        show={paymentModalShow}
        dialogClassName="modal-100w"
        closeButton={false}
      />
    </div>
  );
};
