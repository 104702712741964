import React, { useEffect, useState } from "react";
import { Row, Table, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";
import SimpleRichTextEditor from "./SimpleTextEditor";
import ProposalPreviewFormHeader from "../ProposalPreviewForm/ProposalPreviewFormHeader";
import ProposalPreviewFormFooter from "../ProposalPreviewForm/ProposalPreviewFormFooter";
import SignatureBlock from "../ProposalPreviewForm/SignatureBlock";

const PaintProposalTemplate = ({ disable_fields }) => {
  const proposalValues = useSelector((state) => state.proposalValues);
  const sections = useSelector((state) => state.proposalSections);
  const [firstLoad, setFirstLoad] = useState(true);
  // let bidAmount = proposalValues.bid_amount?.replace(",", "");
  const dispatch = useDispatch();

  const handleChange = (evt) => {
    const { name, value: newValue } = evt.target;
    const formattedValue = parseInt(newValue);
      // .replace(/[^0-9.]/g, "")
      // .match(/^-?\d*(\.\d{0,2})?/)[0];
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: { [name]: formattedValue },
    });
  };

  return (
    <section className="bg-white mt-5 p-5">
      <ProposalPreviewFormHeader disable_fields={disable_fields} />
      <SimpleRichTextEditor></SimpleRichTextEditor>
      <div className="row" style={{ textTransform: "capitalize" }}></div>
      <br />

      <Row>
        <h6 className="fw-bolder">
          PRICING PER MENTIONED SCOPE:
        </h6>
        <Table bordered className="my-4">
          <tbody>
            <tr className="fs-4">
              <th className="text-start w-50">
                <Form.Control
                  type="number"
                  min="0"
                  name="bid_amount"
                  className="text-start w-100"
                  placeholder={proposalValues?.proposal_template?.includes("Paint") ? "Paint Price" : "Drywall Price"}
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  disabled={disable_fields ? true : false}
                  value={proposalValues?.bid_amount}
                  onChange={handleChange}
                />
              </th>
            </tr>
          </tbody>
        </Table>
      </Row>
      <SignatureBlock />
      <ProposalPreviewFormFooter page="2" />
    </section>
  );
};

export default PaintProposalTemplate;
