import React, { useEffect, useState } from "react";
import ProposalPreviewFormHeader from "./ProposalPreviewFormHeader";
import ProposalPreviewFormFooter from "./ProposalPreviewFormFooter";
import { Row, Table, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";
import SignatureBlock from "./SignatureBlock";
import SimpleRichTextEditor from "./SimpleTextEditor";

const ProposalProjectType = ({ disable_fields }) => {
  const proposalValues = useSelector((state) => state.proposalValues);
  const sections = useSelector((state) => state.proposalSections);
  const [firstLoad, setFirstLoad] = useState(true);
  // let bidAmount = proposalValues.bid_amount?.replace(",", "");
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstLoad && proposalValues?.bid_amount !== "") {
      setFirstLoad(false);
      return;
    }
    let total =
      parseFloat(
        proposalValues.rough_clean_amount &&
          proposalValues?.phases?.includes("rough")
          ? proposalValues.rough_clean_amount
          : 0
      ) +
      parseFloat(
        proposalValues.final_clean_amount &&
          proposalValues?.phases?.includes("final")
          ? proposalValues.final_clean_amount
          : 0
      ) +
      parseFloat(
        proposalValues.fluff_clean_amount &&
          proposalValues?.phases?.includes("fluff")
          ? proposalValues.fluff_clean_amount
          : 0
      ) +
      parseFloat(
        proposalValues.exterior_clean_amount && sections.window_cleaning
          ? proposalValues.exterior_clean_amount
          : 0
      ) +
      parseFloat(
        proposalValues.pressure_washing_amount && sections.pressure_washing
          ? proposalValues.pressure_washing_amount
          : 0
      );
    total = total.toFixed(0);
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: { ["bid_amount"]: String(total) },
    });
  }, [
    proposalValues.rough_clean_amount,
    proposalValues.final_clean_amount,
    proposalValues.fluff_clean_amount,
    proposalValues.exterior_clean_amount,
    proposalValues.pressure_washing_amount,
    proposalValues.phases,
    sections.window_cleaning,
    sections.pressure_washing
  ]);

  const handleChange = (evt) => {
    const { name, value: newValue } = evt.target;
    const formattedValue = parseInt(newValue);
      // .replace(/[^0-9.]/g, "")
      // .match(/^-?\d*(\.\d{0,2})?/)[0];
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: { [name]: formattedValue },
    });
  };

  return (
    <section className="bg-white mt-5 p-5">
      <ProposalPreviewFormHeader disable_fields={disable_fields} />
      <SimpleRichTextEditor></SimpleRichTextEditor>
      <div className="row" style={{ textTransform: "capitalize" }}></div>
      <br />

      <Row>
        <h6 className="fw-bolder">
          Pricing Based on the Provided Scope for Post-Construction Cleaning:
        </h6>
        <Table bordered className="my-4">
          <tbody>
            {proposalValues?.phases?.includes("rough") && !sections?.single_bid_amount && (
              <tr>
                <td> Rough Clean</td>
                <td>
                  <Form.Control
                    type="number"
                    min="0"
                    name="rough_clean_amount"
                    className="text-start w-100"
                    placeholder="Rough Clean Amount"
                    style={{ border: "none", background: "none" }}
                    disabled={disable_fields ? true : false}
                    value={proposalValues.rough_clean_amount}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            )}
            {proposalValues?.phases?.includes("final") && !sections?.single_bid_amount && (
              <tr>
                <td> Final Clean</td>
                <td>
                  <Form.Control
                    type="number"
                    min="0"
                    name="final_clean_amount"
                    className="text-start w-100"
                    placeholder="Final Clean Amount"
                    style={{ border: "none", background: "none" }}
                    disabled={disable_fields ? true : false}
                    value={proposalValues.final_clean_amount}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            )}
            {proposalValues?.phases?.includes("fluff") && !sections?.single_bid_amount && (
              <tr>
                <td> Fluff/puff Clean </td>
                <td>
                  <Form.Control
                    type="number"
                    min="0"
                    name="fluff_clean_amount"
                    className="text-start w-100"
                    placeholder="Fluff Clean Amount"
                    style={{ border: "none", background: "none" }}
                    disabled={disable_fields ? true : false}
                    value={proposalValues.fluff_clean_amount}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            )}

            {sections.window_cleaning ? (
              <tr>
                <td> Exterior Window Cleaning </td>
                <td>
                  <Form.Control
                    type="number"
                    min="0"
                    name="exterior_clean_amount"
                    className="text-start w-100"
                    placeholder="Window Cleaning Amount"
                    style={{ border: "none", background: "none" }}
                    disabled={disable_fields ? true : false}
                    value={proposalValues.exterior_clean_amount}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            ) : (
              ""
            )}
            {sections.pressure_washing ? (
              <tr>
                <td> Pressure Washing </td>
                <td>
                  <Form.Control
                    type="number"
                    min="0"
                    name="pressure_washing_amount"
                    className="text-start w-100"
                    placeholder="Pressure Washing Amount"
                    style={{ border: "none", background: "none" }}
                    disabled={disable_fields ? true : false}
                    value={proposalValues.pressure_washing_amount}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            ) : (
              ""
            )}
            <tr className="fs-4">
              <th className="text-start w-50">Total Bid amount</th>
              <th className="text-start w-50">
                <Form.Control
                  type="number"
                  min="0"
                  name="bid_amount"
                  className="text-start w-100"
                  placeholder="Bid amount"
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  disabled={disable_fields ? true : false}
                  value={proposalValues?.bid_amount}
                  onChange={handleChange}
                />
              </th>
            </tr>
          </tbody>
        </Table>
      </Row>
      <SignatureBlock />
      <ProposalPreviewFormFooter page="2" />
    </section>
  );
};

export default ProposalProjectType;
