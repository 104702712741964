// BusinessDetailsForm.js
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import {
  FormTitle,
  CustomForm,
  CustomInput,
  CustomFormGroup,
} from "./StyledComponents";
import Message from "../../../components/Message";
const CompanyBasic = ({ formData, handleFormDataChange }) => {
  const [formValues, setFormValues] = useState({
    company_name: "",
    office_address: "",
    office_city: "",
    office_state: "",
    office_zip: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // If you need to lift state up
    handleFormDataChange(name, value);
  };
  // Set default state from formData prop
  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      ...formData,
    }));
  }, [formData]);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form submission here
  };

  return (
    <Container
      className="justify-content-center "
      // style={{ minHeight: "100vh" }}
    >
      <Row>
        <Col md={8} lg={12}>
          <CustomForm
            className="col-md-12"
            onSubmit={handleSubmit}
            style={{ minHeight: "50vh" }}
          >
            <FormTitle className="text-start border-bottom">
              Business details
            </FormTitle>
            <Row className="justify-content-center">
              <CustomFormGroup controlId="formCompanyName" className="col-md-6">
                <Form.Label className="fw-bold h4">Company Name</Form.Label>
                <CustomInput
                  name="company_name"
                  type="text"
                  value={formValues.company_name}
                  onChange={handleInputChange}
                  required
                />
              </CustomFormGroup>
              <Form.Group as={Col} controlId="formOfficeAddress">
                <Form.Label className="fw-bold h4">Office Address</Form.Label>
                <Form.Control
                  name="office_address"
                  value={formValues.office_address}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>

            <Row className="mt-5">
              <Form.Group as={Col} controlId="formOfficeCity">
                <Form.Label className="fw-bold h4">Office City</Form.Label>
                <Form.Control
                  name="office_city"
                  value={formValues.office_city}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formOfficeState">
                <Form.Label className="fw-bold h4">Office State</Form.Label>
                <Form.Control
                  name="office_state"
                  value={formValues.office_state}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>
            <Row className="mt-5">
              <Form.Group
                as={Col}
                controlId="formOfficeZip"
                className="col-md-3"
              >
                <Form.Label className="fw-bold h4">Office Zip</Form.Label>
                <Form.Control
                  name="office_zip"
                  value={formValues.office_zip}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>
          </CustomForm>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyBasic;
