// DescribeBusinessForm.js
import React, { useEffect, useState } from "react";
import {
  Container,
  ButtonGroup,
  ToggleButton,
  Row,
  Col,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import {
  CustomFormGroup,
  ToggleButtonGroup,
  ToolButton,
} from "./StyledComponents";
import ToolTip from "../../../components/tooltip/ToolTip";
import MultiStateDropdown from "../../../components/MultiStateDropdown";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import { getRegions } from "../../../store/actions/gc_qualify/gcQualify.actions";
import { working_area_database } from "../../../utils/helpers/helper";
import { useDispatch } from "react-redux";

const CompanyType = ({ formData, handleFormDataChange }) => {
  const [serviceProvided, setServiceProvided] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const dispatch = useDispatch();

  // Event handlers for input changes
  const handleServiceChange = (e) => {
    setServiceProvided(e.target.value);
    handleFormDataChange("company_specialty", e.target.value);
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleChange = (value) => {
    setSelectedRegions([...value]);
    value = value.map((location) => location.name).join("_");
    handleFormDataChange("working_area", value);
  };

  useEffect(() => {
    dispatch(getRegions());

    if (formData["working_area"] !== "") {
      setSelectedRegions(working_area_database(formData["working_area"]));
    }

    setServiceProvided(formData["company_specialty"]);
  }, [dispatch, formData]);
  //
  // useEffect(() => {
  //   setIsCleaningCompany(formData["is_cleaning_company"]);
  // }, [formData]);

  // cons;

  return (
    <Container className="my-4 justify-content-center ">
      <h2>Describe your business</h2>
      <p className="mb-5">
        Help us better understand your business by answering the following.
      </p>
      <Row className="my-5 justify-content-center">
        <Form.Group as={Col} controlId="serviceProvided" className="" md={6}>
          <Form.Label className="text-center mb-3 fw-bold h3">
            What service do you provide?
          </Form.Label>
          <Form.Control
            name="serviceProvided"
            type="text"
            className="form-control mt-n3 h3"
            onChange={handleServiceChange}
            value={serviceProvided}
            placeholder="What Service do you provide"
          />
        </Form.Group>
      </Row>
      <Row className="justify-content-center">
        <Form.Group controlId="formCompanyAddress" className="col-md-6">
          <Form.Label className="text-center mb-3 fw-bold h3">
            What is your service area?
          </Form.Label>
          <MultiSelectDropdown
            customHandler={handleChange}
            selectedRegions={selectedRegions}
          />
        </Form.Group>
      </Row>
    </Container>
  );
};

export default CompanyType;
