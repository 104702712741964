const FilterIcon = ({
  style = {},
  fill = "white",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 24 24"
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5V7.14134L10.6688 12.8101C10.6688 12.8102 10.6687 12.8101 10.6688 12.8101C11.023 13.1643 11.2221 13.6447 11.2222 14.1456V17.5858L12.7778 16.0302V14.1458C12.7779 13.6449 12.9769 13.1644 13.3311 12.8102C13.3312 12.8102 13.3311 12.8103 13.3311 12.8102L19 7.14134V5H5ZM3.55324 3.55324C3.90748 3.19901 4.38792 3 4.88889 3H19.1111C19.6121 3 20.0925 3.19901 20.4468 3.55324C20.801 3.90748 21 4.38793 21 4.88889V7.18756C20.9999 7.68843 20.8009 8.16896 20.4467 8.52311C20.4466 8.52314 20.4467 8.52307 20.4467 8.52311L14.7778 14.192V16.4444C14.7778 16.7097 14.6724 16.964 14.4849 17.1516L10.9293 20.7071C10.6433 20.9931 10.2132 21.0787 9.83954 20.9239C9.46586 20.7691 9.22222 20.4045 9.22222 20V14.192L3.55344 8.52321C3.55341 8.52318 3.55348 8.52325 3.55344 8.52321C3.19925 8.16907 3.00011 7.68864 3 7.18777V4.88889C3 4.38792 3.19901 3.90748 3.55324 3.55324Z"
      fill={fill}
    />
  </svg>
);

export default FilterIcon;
