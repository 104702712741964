import Spinner from "../../../components/spinner/Spinner";
import ProjectDataCard from "../components/ProjectDataCard";
import PaginationV2 from "../../../components/pagination/PaginationV2";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { removeAllProjectSiteRoute, updateSiteRouteNotes } from "../../../store/actions/projects/projects.action";
import { Button } from "react-bootstrap";
import LoginModal from "../../../components/LoginModal";
import { Link } from "react-router-dom";

const Mobile = ({
  loading,
  selectedTab,
  projects,
  currentPage,
  handlePageChanged,
  handleArchive,
  handleFavourite,
  map,
  handleProjectDetailCLick,
  authenticatedUser,
  isMainCheckboxChecked,
  selectedProjects,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { siteRoutesNotes } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const handleSetModal = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  return (
    <>
      {selectedTab === "list" ? (
        <>
          <div className="mt-4">
            <h4 className="text-start font-oswald lead-text">Site Route Router</h4>
            <h5 className="text-start me-3" style={{ fontWeight: 'normal' }}>
              Optimize job site visits by integrating route planning. <Link to="https://learn.ganarpro.com/site-route-router/">Learn more</Link>
            </h5>

            <div className="d-flex text-10 align-items-center justify-content-between mb-5">
              <div className="d-flex px-0 col-6 align-items-center justify-content-between">
                <button className="btn btn-secondary w-100 mx-3" onClick={() => dispatch(removeAllProjectSiteRoute(0))}> Clear All </button>
                <button className="btn btn-primary w-100" onClick={() => dispatch(updateSiteRouteNotes(siteRoutesNotes))}> Save All Notes </button>
              </div>
            </div>
            <div>
              {loading ? (
                <div className="m-auto">
                  <Spinner />
                </div>
              ) : projects?.length ? (
                projects?.map((project, index) => (
                  <ProjectDataCard
                    key={project.id}
                    handleArchive={handleArchive}
                    handleFavourite={handleFavourite}
                    handleProjectDetailCLick={handleProjectDetailCLick}
                    {...project}
                    authenticatedUser={authenticatedUser}
                    isMainCheckboxChecked={isMainCheckboxChecked}
                    selectedProjects={selectedProjects}
                    count={index}
                  />
                ))
              ) : user?.id ? (
                <p className="text-start m-4" style={{ fontSize: '15px' }}>
                  + Add project to route 
                </p>
              ) : (
                <div>
                  <Button variant="primary" type="submit" onClick={handleSetModal}>
                    Login{" "}
                  </Button>
                  <h4>to see Site route router projects</h4>
                </div>
              )}
            </div>
          </div>
          <div className="col-10 mx-auto">
            {loading ? (
              ""
            ) : (
              <PaginationV2
                totalRecords={projects?.length}
                pageLimit={10}
                pageNeighbours={1}
                onPageChanged={handlePageChanged}
                pageNumber={currentPage}
              />
            )}
          </div>
        </>
      ) : (
        <div>
          <div className="col-12 mb-4" style={{ height: "90vh" }}>
            {map}
          </div>
        </div>
      )}
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Mobile;
