import { toast } from "react-toastify";
import { TOTAL_FREE_MODE_COUNT } from "../constants/api";
import dayjs from "dayjs";

export const updateObject = (oldObject, updatedObject) => {
  return {
    ...oldObject,
    ...updatedObject,
  };
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const getUser = () => {
  const access = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(localStorage.getItem("data"));
  const refresh = JSON.parse(localStorage.getItem("refresh"));
  const result = {
    access,
    user,
    refresh,
  };

  return result;
};

export const checkAuth = () => {
  const user = JSON.parse(localStorage.getItem("data"));
  const token = JSON.parse(localStorage.getItem("token"));
  if (!user) return false;
  if (!token) return false;
  // try {
  //   const { exp } = jwt.decode(token);
  //   if (exp < new Date().getTime() / 1000) {
  //     return false;
  //   }
  // } catch (error) {
  //   return false;
  // }
  return true;
};

export const toastSuccess = (message) => {
  return toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastError = (message, options) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

export let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return str;
};
const appPrices = [
  process.env.REACT_APP_ALL_APPS,
  process.env.REACT_APP_CALC_APP,
  process.env.REACT_APP_PROPOSAL_APP,
  process.env.REACT_APP_PREQUAL_APP,
  process.env.REACT_APP_PROJECT_APP,
  process.env.REACT_APP_CONTACT_OUT_REACH,
  process.env.REACT_APP_DOCUMENT_STARTER,
  process.env.REACT_APP_BUSINESS,
  process.env.REACT_APP_BASIC_CLEANING,
  process.env.REACT_APP_ALL_PRO,
  process.env.REACT_APP_GANARPRO_PAINTING
];
export const userSubscribedPlans = (profile, app_price_id) => {
  const allAppsPriceId = process.env.REACT_APP_ALL_APPS;
  const user_subs_price_ids = profile?.subscriptions.filter((sub) => {
    return (
      (appPrices.includes(sub.metadata.price_id) && sub.status === "active") ||
      ((sub.metadata.price_id === allAppsPriceId ||
        sub.metadata.price_id === "price_1MrVOXFoZLegDZsXAkoP1B4W") &&
        sub.status === "active")
    );
  });
  return user_subs_price_ids.length > 0;
};

export const isSubscriptionActive = (
  profile,
  app_price_id,
  user,
  free_mode_count
) => {
  return (
    (user && free_mode_count < TOTAL_FREE_MODE_COUNT) ||
    (user && userSubscribedPlans(profile, app_price_id))
  );
};

export const parseTemplate = (template, project_type) => {
  const finalCleanProjects = [
    "carpet cleaning",
    "change order",
    "residence home",
    "final clean scope alone",
    "flooring service",
    "pressure wash",
    "scope broken out",
    "trailer",
  ];

  let cleaning = {};
  const parser = new DOMParser();
  const doc = parser.parseFromString(template, "text/html");
  cleaning = { heading: doc.querySelector("p")?.textContent };

  doc.querySelectorAll("ol").forEach((olTag, index) => {
    let key = "";
    if (finalCleanProjects.indexOf(project_type.toLowerCase()) !== -1) {
      if (index !== 0 && project_type.toLowerCase() == "flooring service") {
        key = "special";
      } else {
        key = "final";
      }
    } else {
      key = olTag.previousElementSibling.textContent
        .toLowerCase()
        .replace(" clean", "");
    }
    let values = [];
    for (let i = 0; i < olTag.children.length; i++) {
      values.push(olTag.children[i].textContent);
    }
    cleaning[key] = values;
  });

  if (project_type?.toLowerCase() == "final clean scope alone") {
    cleaning["notes"] = doc.querySelector("p:last-child").textContent;
  }

  return cleaning;
};

export const createTemplate = (template, values) => {
  let cleaning = values?.cleaning;
  const parser = new DOMParser();
  let doc = parser.parseFromString(template, "text/html");
  doc.querySelectorAll("ol").forEach((olTag) => {
    let key = olTag.previousElementSibling.textContent
      .toLowerCase()
      .replace(" clean", "");
    for (let i = 0; i < olTag.children.length; i++) {
      try {
        olTag.children[i].children[0].children[0].innerText = cleaning[key][i];
      } catch (e) {
        olTag.children[i].innerText = cleaning[key][i];
      }
    }
  });

  doc.querySelectorAll("ol").forEach((olTag) => {
    for (let i = 0; i < olTag.children.length; i++) {
      let liTag = olTag.children[i];
      if (liTag.innerText.trim() === "") {
        let nextSibling = liTag.nextSibling;
        olTag.removeChild(liTag);
        if (
          (nextSibling && nextSibling.textContent.includes("\n")) ||
          nextSibling.textContent.includes("\t")
        ) {
          olTag.removeChild(nextSibling);
        }
        i--;
      }
    }
  });
  delete values.cleaning;
  return doc;
};

export const standardizeUrl = (url) => {
  // Check if the URL starts with 'http://' or 'https://', if not prepend 'https://'
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }

  // Ensure it uses 'https://'
  return url.replace(/^http:\/\//i, "https://");
};

export const getDefaultProjectType = (name) => {
  if (["Senior living Retirement"].includes(name)) {
    return "Multifamily-Residential";
  }

  return name;
};

export const working_area_database = (data) => {
  if (data !== null || data !== undefined || data !== "") {
    return data.split("_").map((item) => ({ name: item, label: item }));
  }
};

export const formatDate = (created) => {
  return dayjs(created).fromNow();
};

export function extractFileName(url) {
  if (!url) {
    return "";
  }
  // Split the URL by '/'
  var parts = url.split("/");
  // Get the last part of the array
  var fileName = parts[parts.length - 1];
  // Decode URL-encoded characters
  fileName = decodeURIComponent(fileName);
  return fileName;
}
