import { get$, patch$, post$, put$ } from "./default";
import { api } from "../constants/api";

export const deleteUserContactAPI = (id) => {
  let url = api.contact.deleteUserContact;
  return post$(url.replace(":contactId", id));
};

export const deleteUserCompanyAPI = (id) => {
  let url = api.contact.deleteUserCompany;
  return post$(url.replace(":contactId", id));
};
