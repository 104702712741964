import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import ProposalForm from "../../components/ProposalForm";
import RecentProposal from "../../components/RecentProposal";
import ProposalPreviewForm from "../../components/ProposalPreviewForm";
import LoginModal from "../../components/LoginModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadProposal,
  getProposalDetail,
  saveProposal,
  updateProposal,
} from "../../store/actions/proposals/proposals.actions";
import ProposalPreviewModal from "../../components/ProposalPreviewModal";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import SubscriptionModal from "../../components/subscriptionModal";
import { useNavigate } from "react-router";
import {
  PROPOSAL_RESSET_VALUES,
  PROPOSAL_SET_VALUES,
} from "../../store/constants/proposalConstants";
import { isSubscriptionActive, toastError } from "../../utils/helpers/helper";
import {
  addPageFavorite,
  createPageView,
  fetchPageFavorite,
  removePageFavorite,
} from "../../store/actions/users/users.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import { SiteVisitForm } from "../../components/siteVisit/siteVisitForm";
import RecentSiteVisits from "../../components/recentSiteVisits";
import {
  getSiteVisitDetails,
  getSiteVisitDetailsByProject,
  saveSiteVisit,
  updateSiteVisit,
} from "../../store/actions/site_visits/site_visits.actions";
import {
  SITE_VISIT_HEADING_RESSET_VALUES,
  SITE_VISIT_RESSET_VALUES,
  SITE_VISIT_SET_VALUES,
} from "../../store/constants/siteVisitConstants";

const StyledSectionTwo = styled.div`
  background-color: rgb(246 248 250);
`;
const StyledRow = styled(Row)`
  padding-top: 4rem;
`;

const StyledPreviewText = styled.h3`
  color: #000;
  font-weight: 700;
`;

const StyledTabs = styled(Tabs)`
  background-color: #fff;
  h2 {
    color: #000;
  }
  button {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  .nav-item button#noanim-tab-example-tab-new {
    color: #fff;
    background-color: #276fb4;
  }
`;

const SiteVisit = () => {
  const { search } = window.location;
  let params = useParams();
  const { id } = params;
  let [searchParams] = useSearchParams();
  const project_name = searchParams.get("project_name")?.trim();
  const project_address = searchParams.get("project_address")?.trim();
  const company = searchParams.get("company")?.trim();
  const superintendent = searchParams.get("superintendent")?.trim();
  const project_manager = searchParams.get("project_manager")?.trim();
  const square_foot = searchParams.get("square_foot")?.trim();
  const units = searchParams.get("units")?.trim();

  const isMobile = useIsMobile();
  const show_tab = new URLSearchParams(search).get("show_tab");
  const [modalShow, setModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [showTab, setShowTab] = useState("home");

  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: profile, loading } = useSelector((state) => state.userDetails);
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Site Visit");

  const values = useSelector((state) => state.siteVisit);
  const headings = useSelector((state) => state.siteVisitHeading);
  const { loading: actionLoader } = useSelector(
    (state) => state.siteVisitAction
  );
  const { loading: detailsLoader, site_visit } = useSelector(
    (state) => state.siteVisitDetails
  );
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const { favorites } = useSelector((state) => state.ListFavoritePages);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSetPaymentModalShow = (e) => {
    e.preventDefault();
    localStorage.setItem("site_visit", JSON.stringify(values));
    setPaymentModalShow(true);
  };

  const handleSetModal = (e) => {
    e.preventDefault();
    localStorage.setItem("site_visit", JSON.stringify(values));
    setModalShow(true);
  };

  const showFormTab = (e) => {
    if (e === "home") {
      setShowTab("home");
    } else if (e === "recent") {
      setShowTab("recent");
    } else if (e === "new") {
      dispatch({ type: SITE_VISIT_RESSET_VALUES });
      dispatch({ type: SITE_VISIT_HEADING_RESSET_VALUES });

      navigate("/site_visit");
    } else if (id !== undefined) {
      setShowTab("home");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const newValues = {
      ...values,
      ...headings,
    };

    dispatch(saveSiteVisit(newValues));
  };

  const updateHandler = (e) => {
    e.preventDefault();

    const newValues = {
      ...values,
      ...headings,
    };
    dispatch(updateSiteVisit(values?.id, newValues));
  };

  let buttonText = "Save Site Visit";
  let handleButton = submitHandler;
  if (!user) {
    handleButton = handleSetModal;
  } else if (user && site_visit && (id || values?.id)) {
    handleButton = updateHandler;
    buttonText = "Update Site Visit";
  }

  const path = global.window.location.pathname.slice(1);

  const handleDownload = (e) => {
    e.preventDefault();

    const newValues = {
      ...values,
      ...headings,
    };

    if (values?.id) {
      dispatch(updateSiteVisit(values?.id, newValues, true));
    } else {
      dispatch(saveSiteVisit(newValues, true));
    }
  };

  useEffect(() => {
    if (id && user) {
      dispatch(getSiteVisitDetails(id));
      // setShowTab("home");
    }
    if (show_tab) {
      setShowTab("recent");
    }
  }, [dispatch, id, show_tab, user]);

  const handleFavoriteToggle = () => {
    if (isFavorite) {
      favorites.find((f) => f.page_name == "Site Visit Generator")
        ? dispatch(removePageFavorite({ page_name: "Site Visit Generator" }))
        : dispatch(removePageFavorite({ page_name: "Site Visit Sheet" }));
    } else {
      dispatch(
        addPageFavorite({ page_name: "Site Visit Sheet", page_url: path })
      );
    }
  };

  useEffect(() => {
    favorites.find((f) => f.page_name == "Site Visit Generator")
      ? dispatch(fetchPageFavorite("Site Visit Generator"))
      : dispatch(fetchPageFavorite("Site Visit Sheet"));
  }, [dispatch]);

  useEffect(() => {
    const disabled = searchParams.get("disabled")?.trim();
    dispatch({
      type: SITE_VISIT_SET_VALUES,
      payload: {
        project_name: project_name,
        project_address: project_address,
        company: company,
        superintendent: superintendent,
        project_manager: project_manager,
        square_foot: square_foot,
        units: units,
        disabled: disabled,
        today_date: new Date().toISOString().split("T")[0],
      },
    });
    if (project_name && user) {
      dispatch(getSiteVisitDetailsByProject(project_name));
    }
  }, [searchParams]);

  return (
    <div className="bg-white ">
      <Container>
        {user && (
          <Row className="justify-content-end">
            <Col md={6}>
              <FontAwesomeIcon
                size={"2x"}
                icon={isFavorite ? solidStar : regularStar}
                style={{
                  color: isFavorite ? "gold" : "transparent",
                  stroke: isFavorite ? "" : "gold",
                  strokeWidth: isFavorite ? "0" : "20",
                  cursor: "pointer",
                }}
                onClick={handleFavoriteToggle}
                className="fw-bolder align-self-center my-4"
              />
            </Col>
          </Row>
        )}
        <StyledRow>
          <Col xs={12}>
            <h1 style={{ fontWeight: "bold" }}>Site Visit Sheet</h1>
          </Col>
          <Col xs={12} md={12} className="my-siteVisit-col">
            <StyledTabs
              activeKey={showTab}
              onSelect={showFormTab}
              transition={true}
              id="noanim-tab-example"
              className="mb-3"
            >
              <Tab
                eventKey="new"
                title="+"
                style={{ background: "blue", color: "white" }}
              />
              <Tab
                eventKey="home"
                title={"Create New Site Visit"}
                className="text-uppercase"
              >
                <Row>
                  <div style={{ textTransform: "none" }}>
                    <SiteVisitForm
                      setPaymentModalShow={handleSetPaymentModalShow}
                      setModalShow={setModalShow}
                    />
                    <div className="text-center mb-5">
                      <Button
                        className="btn-success"
                        style={{ color: "#fff", marginRight: "10px" }}
                        onClick={handleDownload}
                        disabled={actionLoader || detailsLoader}
                      >
                        <h4>
                          Download{" "}
                          {(actionLoader || detailsLoader) && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          <span className="visually-hidden">Loading...</span>{" "}
                        </h4>
                      </Button>
                      <Button
                        className="btn-primary"
                        style={{ color: "#fff", marginLeft: "10px" }}
                        onClick={handleButton}
                        disabled={actionLoader || detailsLoader}
                      >
                        <h4>
                          {buttonText}{" "}
                          {(actionLoader || detailsLoader) && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          <span className="visually-hidden">Loading...</span>{" "}
                        </h4>
                      </Button>
                    </div>
                  </div>
                </Row>
              </Tab>
              <Tab eventKey="recent" title="Saved Site Visits">
                <RecentSiteVisits
                  setModalShow={setModalShow}
                  showFormTab={showFormTab}
                />
              </Tab>
            </StyledTabs>
          </Col>
        </StyledRow>
      </Container>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <SubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
    </div>
  );
};

export default SiteVisit;
