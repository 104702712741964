import {
  CONTACT_LIST_FAIL,
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  USER_COMPANY_DELETE_FAIL,
  USER_COMPANY_DELETE_REQUEST,
  USER_COMPANY_DELETE_SUCCESS,
  USER_CONTACT_DELETE_FAIL,
  USER_CONTACT_DELETE_REQUEST,
  USER_CONTACT_DELETE_SUCCESS,
} from "../../constants/contactConstants";
import axios from "axios";
import { isEmpty, toastSuccess } from "../../../utils/helpers/helper";
import { favouriteProjectAPI } from "../../../utils/requests/projects";
import {
  favouriteProjectSuccess,
  fetchProjectsFailed,
} from "../projects/projects.action";
import {
  deleteUserCompanyAPI,
  deleteUserContactAPI,
} from "../../../utils/requests/contact";
import { toast } from "react-toastify";

export const getAllContacts =
  (query = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTACT_LIST_REQUEST,
      });
      let { access } = getState();
      let headers = {
        "content-type": "application/json",
      };
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }
      if (access) {
        headers = {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        };
      }
      const config = {
        // params,
        // paramsSerializer: function paramsSerializer(params) {
        //   // "Hide" the `answer` param
        //   return Object.entries(Object.assign({}, params))
        //     .map(([key, value]) => `${key}=${value}`)
        //     .join("&");
        // },
        headers,
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/contacts?${query}`,
        config
      );

      // const result = Object.values(
      //   data.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      // );

      dispatch({
        type: CONTACT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_LIST_FAIL,
        payload: error,
      });
    }
  };

export const deleteUserContact = (id) => {
  return async (dispatch) => {
    dispatch({
      type: USER_CONTACT_DELETE_REQUEST,
      payload: id,
    });
    return deleteUserContactAPI(id)
      .then((response) => {
        dispatch({
          type: USER_CONTACT_DELETE_SUCCESS,
          payload: id,
        });
        toast.success("contact removed successfully");
        dispatch(getAllContacts());
      })
      .catch((error) => {
        dispatch({
          type: USER_CONTACT_DELETE_FAIL,
          payload: id,
        });
        toast.success("contact delete failed");
      });
  };
};

export const deleteUserCompany = (id) => {
  return async (dispatch) => {
    dispatch({
      type: USER_COMPANY_DELETE_REQUEST,
      payload: id,
    });
    return deleteUserCompanyAPI(id)
      .then((response) => {
        dispatch({
          type: USER_COMPANY_DELETE_SUCCESS,
          payload: id,
        });
        toast.success("company removed successfully");
        dispatch(getAllContacts());
      })
      .catch((error) => {
        dispatch({
          type: USER_COMPANY_DELETE_SUCCESS,
          payload: id,
        });
        toast.success("company delete failed");
      });
  };
};

export const downloadContactsList =
  (contactIds) => async (dispatch, getState) => {
    dispatch({ type: CONTACT_LIST_REQUEST });

    try {
      const { access: token } = getState(); // Assuming token is directly under `access`
      const tokenInStorage = token || JSON.parse(localStorage.getItem("token"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          ...(tokenInStorage && { Authorization: `Bearer ${tokenInStorage}` }),
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/contacts/download_contacts`,
        { contact_ids: contactIds }, // Ensure the payload structure is correct
        config
      );

      dispatch({ type: CONTACT_LIST_SUCCESS, payload: data });

      const url = data.download_url;

      const a = document.createElement("a");
      a.href = url;
      a.download = "contacts.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      dispatch({
        type: CONTACT_LIST_FAIL,
        payload: error.response?.data || error.message,
      });
      toast.error("You have exceeded 50 daily limit ");
    }
  };
