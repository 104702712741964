export const DateTag = ({ name, dateTime, color, onClick }) => {
  // Create a Date object from the dateTime string
  const dateObj = new Date(dateTime);
 
  // Extract the date and month Also convert to device timezone format
  const options = { month: '2-digit', day: '2-digit' };
  const localDate = dateObj.toLocaleDateString(undefined, options);

  return (
    <div
        className="p-3"
        style={{ background: color, borderRadius: "15px", cursor: "pointer",fontSize: "10px" }}
        onClick={onClick}
    >
        <strong>{name}{localDate}</strong>
    </div>
  );
};
