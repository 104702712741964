const MapIcon = ({
  style = {},
  fill = "white",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 21 21"
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.06351C15.478 2.06351 14.9651 2.1997 14.5119 2.45862C14.0586 2.71754 13.6808 3.09023 13.4157 3.53988C13.1506 3.98952 13.0074 4.50055 13.0003 5.02247C12.9934 5.52807 13.1144 6.02694 13.3516 6.47285L16 10.2562L18.6483 6.47285C18.8856 6.02694 19.0066 5.52807 18.9997 5.02247C18.9926 4.50055 18.8494 3.98952 18.5843 3.53988C18.3192 3.09023 17.9414 2.71754 17.4881 2.45862C17.0349 2.1997 16.522 2.06351 16 2.06351ZM13.5198 0.722026C14.2751 0.290489 15.13 0.0635071 16 0.0635071C16.87 0.0635071 17.7249 0.290489 18.4802 0.722026C19.2356 1.15356 19.8653 1.77472 20.3071 2.52412C20.749 3.27353 20.9876 4.12524 20.9995 4.99512C21.0114 5.86499 20.7962 6.72291 20.375 7.48412L20.3495 7.53026L16 13.7438L11.6505 7.53026L11.625 7.48412C11.2038 6.72291 10.9886 5.86499 11.0005 4.99512C11.0124 4.12524 11.251 3.27353 11.6929 2.52412C12.1347 1.77472 12.7644 1.15356 13.5198 0.722026ZM7 1.88196L8.94721 2.85557C9.44119 3.10256 9.64142 3.70323 9.39443 4.19721C9.14744 4.69119 8.54676 4.89142 8.05279 4.64443L8 4.61803V15.382L12 17.382V14C12 13.4477 12.4477 13 13 13C13.5523 13 14 13.4477 14 14V17.382L18 15.382V14C18 13.4477 18.4477 13 19 13C19.5523 13 20 13.4477 20 14V16.618L13 20.118L7 17.118L0 20.618V5.38197L7 1.88196ZM6 15.382V4.61803L2 6.61803V17.382L6 15.382ZM16 4C16.5523 4 17 4.44771 17 5V5.01C17 5.56228 16.5523 6.01 16 6.01C15.4477 6.01 15 5.56228 15 5.01V5C15 4.44771 15.4477 4 16 4Z"
      fill="white"
    />
  </svg>
);


export default MapIcon
