export const FETCH_INVITE_HISTORY_START = "FETCH_INVITE_HISTORY_START";
export const FETCH_INVITE_HISTORY_SUCCESS = "FETCH_INVITE_HISTORY_SUCCESS";
export const FETCH_INVITE_HISTORY_FAIL = "FETCH_INVITE_HISTORY_FAIL";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";
export const SEND_INVITE_START = "SEND_INVITE_START";
export const SEND_INVITE_FAIL = "SEND_INVITE_FAIL";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const FETCH_SUMMARY_START = "FETCH_SUMMARY_START";
export const FETCH_SUMMARY_SUCCESS = "FETCH_SUMMARY_SUCCESS";
export const FETCH_SUMMARY_FAIL = "FETCH_SUMMARY_FAIL";
