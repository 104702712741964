import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavbarContext } from "../../App";
import logo from "../../assets/img/ganarpro-logo-1.png";
import { useSelector, useDispatch } from "react-redux";
import "./Navbar.css";
import ProfileBox from "./components/ProfileBox";
import SearchIcon from "../../assets/icons/SearchIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import { logout } from "../../store/actions/authentication.action";
import {
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  NavDropdown,
  Container,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserProfileIcon from "../../assets/icons/UserProfileIcon";
import LogoutIcon from "../../assets/icons/LogoutIcon";
import useIsMobile from "../../utils/helpers/use-is-mobile";
import SidebarV3 from "./components/SidebarV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserDetail,
  getManageUserBilling,
  updateLifetimeOffer,
  fetchPageView,
} from "../../store/actions/users/users.actions";
import {
  faCirclePlus,
  faDollarSign,
  faEnvelope,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import proposalIcon from "../../assets/img/sidebar/svg/proposal.svg";
import calculatorIcon from "../../assets/img/sidebar/svg/calculator.svg";
import Logo from "../../assets/img/sidebar/Logo.png";
import prequalifyIcon from "../../assets/img/sidebar/svg/prequalify.svg";
import projectIcon from "../../assets/img/sidebar/svg/projects.svg";
import my_companyIcon from "../../assets/img/sidebar/svg/my_company.jpg";
import siteVisitIcon from "../../assets/img/site_visit.jpg";
import { toastSuccess } from "../../utils/helpers/helper";
import { TbGridDots } from "react-icons/tb";
import blackCircleIcon from "../../assets/img/blackCircle.jpg";
import blueCircleIcon from "../../assets/img/blueCircle.jpg";
import greenCircleIcon from "../../assets/img/greenCircle.jpg";
import orangeCircle from "../../assets/img/orangeCircle.png";
import siteRouter from "../../assets/img/site route  router-01.png";
import PricingModal from "../pricingModal";
import { UPDATE_PRICING_MODAL } from "../../store/actionTypes";

const StyledHeader = styled.header`
  box-shadow: inset 0 -1px rgb(236 238 241);
  z-index: 1;
`;

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <FontAwesomeIcon
    icon={faCirclePlus}
    className="primary"
    size="2x"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: "pointer", color: "#0d6efd" }} // Optional for visual feedback on hover
  />
));

const CustomAppsToggle = React.forwardRef(({ onClick }, ref) => (
  <TbGridDots
    className="primary"
    size="25px"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: "pointer", color: "#0d6efd" }} // Optional for visual feedback on hover
  />
));
const Navbar = () => {
  const isMobile = useIsMobile();
  const [pricingModalShow, setPricingModelShow] = useState(false);
  const [pricingBannerShow, setPricingBannerShow] = useState(false);
  const [showMobileNavbar, setMobileNabvar] = useState(false);
  const [showProfileBox, setShowProfileBox] = useState(false);
  const [toggleSideBar, setToggleSideBar] = useState(false);
  const { pricingModalShow: pricingModal } = useSelector(
    (state) => state.projects
  );
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { user: profile, loading } = useSelector((state) => state.userDetails);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { loading: billingLoader, disabled } = useSelector(
    (state) => state.manageUserBilling
  );
  const paint_app = localStorage.getItem(`isSwitchButton_${user?.id}`)
    ? JSON.parse(localStorage.getItem(`isSwitchButton_${user?.id}`))
    : !profile?.is_cleaning_company;

  const navigate = useNavigate();
  const location = useLocation();
  // Check if the current pathname is '/onboarding'
  const isOnboarding = location.pathname.includes("/onboarding");
  const { pageList } = useSelector((state) => state.listPageView);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showApps, setShowApps] = useState(false);
  const target = useRef(null);
  const targetApps = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (target.current && !target.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (
        targetApps.current &&
        !targetApps.current.contains(event.targetApps)
      ) {
        setShowApps(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [target, targetApps]);
  const onLogoutHandler = () => {
    dispatch(logout());
  };

  const handleCloseNavigationMenu = () => {
    setMobileNabvar(false);
  };

  const handleShowProfileBox = () => {
    setShowProfileBox(!showProfileBox);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const toggleSideBarHandler = () => {
    setToggleSideBar((sidebar) => !sidebar);
  };

  const closeSidebarHandler = () => {
    setToggleSideBar(false);
  };
  const handleManageBilling = (e) => {
    e.preventDefault();
    dispatch(getManageUserBilling(profile?.customer));
  };

  let initials;
  if (user) {
    initials = `${user?.first_name[0]}${user?.last_name[0]}`;
  }
  let myAccount = `Hi ${user?.first_name}`;
  useEffect(() => {
    if (user) {
      dispatch(getUserDetail());
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(fetchPageView());
  }, [dispatch, location]);

  useEffect(() => {
    if (profile?.subscriptions.length < 1 && !profile.lifetime_offer_rejected) {
      setPricingBannerShow(true);
    }
  }, [profile]);

  const handleDismissOffer = (e) => {
    dispatch(updateLifetimeOffer());
    setPricingModelShow(false);
    setPricingBannerShow(false);
    dispatch({
      type: UPDATE_PRICING_MODAL,
      pricing_modal: false,
    });
  };

  const handleSwitchButton = (e) => {
    e.preventDefault();
    const isSwitchButton = localStorage.getItem(`isSwitchButton_${user?.id}`)
      ? JSON.parse(localStorage.getItem(`isSwitchButton_${user?.id}`))
      : !profile?.is_cleaning_company;
    localStorage.setItem(`isSwitchButton_${user?.id}`, `${!isSwitchButton}`);
    document.location.href = "/";
  };

  const changeAppView = (view) => {
    localStorage.setItem(`ganarpro_app${user?.id}`, view);
    localStorage.setItem(
      `isSwitchButton_${user?.id}`,
      `${view == "cleaning" ? "false" : "true"}`
    );
    document.location.href = "/";
  };

  return (
    <div id="nb">
      <div className="">
        {/* <SidebarV2
          toggleSideBar={toggleSideBar}
          toggleSideBarHandler={toggleSideBarHandler}
        /> */}

        <StyledHeader className="d-flex z-1 bg-white w-100 px-lg-5 justify-content-between py-2 py-md-2 text-13 align-items-lg-center">
          <div className="d-flex">
            {/* <span className="sidebar-open-v2" onClick={toggleSideBarHandler}>
              &#9776;
            </span> */}
            <SidebarV3
              show={toggleSideBar}
              toggleSideBarHandler={toggleSideBarHandler}
              closeSidebarHandler={closeSidebarHandler}
              scroll={true}
              backdrop={true}
            />
            <div className="d-none d-lg-flex ms-2 ms-md-5 align-items-lg-center ">
              <div className="ms-2">
                <Link to="/" className="text-decoration-none">
                  <img src={logo} style={{ maxWidth: 200 }} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center mx-5">
              <DropdownButton
                id="dropdown-basic-button"
                title="Recents"
                variant="transparent"
                size="lg"
              >
                {pageList?.slice(0, 10)?.map((resource, index) => (
                  <Dropdown.Item size="lg" onClick={() => navigate(resource?.page_url)} key={index}>
                    <Link to={resource?.page_url} className="text-decoration-none">
                      <div className="d-flex align-items-center">
                        <span
                          className="me-2"
                          style={{
                            width: 'max-content',
                            minWidth: '110px',
                          }}
                        >
                          {resource.page_name.length > 17 && resource.page_name !== resource.type
                            ? resource.page_name.slice(0, 17) + "..."
                            : resource.page_name}
                        </span>
                        {((resource?.page_url).split('/').length > 1 && resource.page_name !== resource.type) &&
                          <span className="ms-2">
                            {resource.type}
                          </span>
                        }
                      </div>
                    </Link>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div className=" d-flex ms-5 align-items-center">
            {isMobile && (
              <>
                <div className="me-5">
                  <Link to="/" className="text-decoration-none">
                    <img
                      src={logo}
                      style={isMobile ? { width: "12em" } : { width: "15em" }}
                      alt="logo"
                    />
                  </Link>
                </div>
              </>
            )}
          </div>

          {!isOnboarding && (
            <div className="w-sm-25 w-md-50 d-flex  align-items-center">
              <div className="me-5 d-none d-md-block">
                <Dropdown as={ButtonGroup} show={showDropdown} ref={target}>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    ref={target}
                    onClick={() => setShowDropdown(!showDropdown)}
                  />

                  <Dropdown.Menu align="end">
                    <Dropdown.Header className="fw-bolder h3">
                      Create new
                    </Dropdown.Header>
                    {paint_app ? (
                      <NavDropdown.Item eventKey="2">
                        <Link
                          to="paint_proposal"
                          className="text-decoration-none fw-bolder"
                        >
                          <img
                            src={proposalIcon}
                            style={{ width: 30 }}
                            alt="home"
                          />
                          Paint Proposal
                        </Link>
                      </NavDropdown.Item>
                    ) : (
                      <>
                        <Dropdown.Item eventKey="1">
                          <Link
                            to="cleanup_calculator"
                            className="text-decoration-none fw-bolder"
                          >
                            <img
                              src={calculatorIcon}
                              style={{ width: 30 }}
                              alt="home"
                            />
                            Estimate
                          </Link>
                        </Dropdown.Item>
                        <NavDropdown.Item eventKey="2">
                          <Link
                            to="my_proposal"
                            className="text-decoration-none fw-bolder"
                          >
                            <img
                              src={proposalIcon}
                              style={{ width: 30 }}
                              alt="home"
                            />
                            Proposal
                          </Link>
                        </NavDropdown.Item>
                      </>
                    )}
                    <Dropdown.Item eventKey="3">
                      <Link
                        to="capability_statement"
                        className="text-decoration-none fw-bolder"
                      >
                        <img src={Logo} style={{ width: 30 }} alt="home" />{" "}
                        Capability statements
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4">
                      <Link
                        to="qualkey"
                        className="text-decoration-none fw-bolder"
                      >
                        <img
                          src={my_companyIcon}
                          style={{ width: 30 }}
                          alt="home"
                        />
                        QualKey
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="5">
                      <Link
                        to="project_board"
                        className="text-decoration-none fw-bolder"
                      >
                        <img
                          src={projectIcon}
                          style={{ width: 30 }}
                          alt="home"
                        />
                        Opportunity search
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="6">
                      <Link
                        to="pre_qualify"
                        className="text-decoration-none fw-bolder"
                      >
                        <img
                          src={prequalifyIcon}
                          style={{ width: 30 }}
                          alt="home"
                        />
                        Pre qualification search
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="7">
                      <Link
                        to="plan_room"
                        className="text-decoration-none fw-bolder"
                      >
                        <img
                          src={prequalifyIcon}
                          style={{ width: 30 }}
                          alt="home"
                        />{" "}
                        Plan room search
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="8">
                      <Link
                        to="site_visit"
                        className="text-decoration-none fw-bolder"
                      >
                        <img
                          src={siteVisitIcon}
                          style={{ width: 30 }}
                          alt="home"
                        />{" "}
                        Site Visit Sheet
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="8">
                      <Link
                        to="site_router"
                        className="text-decoration-none fw-bolder"
                      >
                        <img
                          src={siteRouter}
                          style={{ width: 20, marginLeft: 5 }}
                          alt="home"
                        />{" "}
                        Site Route Router
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="me-5">
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomAppsToggle}
                    id="dropdown-custom-components"
                  >
                    Custom toggle
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align="end"
                    style={
                      isMobile
                        ? {
                            marginTop: "30px",
                            marginLeft: "-27rem",
                            borderRadius: "10px",
                          }
                        : {
                            marginTop: "30px",
                            marginLeft: "-15rem",
                            borderRadius: "10px",
                          }
                    }
                  >
                    <Dropdown.Header className="fw-bolder h2 text-center">
                      Ganarpro Apps
                    </Dropdown.Header>
                    <div className="grid-container">
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => changeAppView("cleaning")}
                        disabled={
                          localStorage.getItem(`ganarpro_app${user?.id}`) ===
                          "cleaning"
                        }
                      >
                        <div className="grid-item">
                          <img
                            src={blackCircleIcon}
                            style={{ width: 60, mixBlendMode: "multiply" }}
                          />
                          <span
                            className="text-below"
                            style={{ fontSize: "12px" }}
                          >
                            Ganarpro All Pro Cleaning
                          </span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => changeAppView("business")}
                        disabled={
                          localStorage.getItem(`ganarpro_app${user?.id}`) ===
                          "business"
                        }
                      >
                        <div className="grid-item">
                          <img
                            src={blueCircleIcon}
                            style={{ width: 60, mixBlendMode: "multiply" }}
                          />
                          <span
                            className="text-below"
                            style={{ fontSize: "12px" }}
                          >
                            Ganarpro Business
                          </span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => changeAppView("basic_cleaning")}
                        disabled={
                          localStorage.getItem(`ganarpro_app${user?.id}`) ===
                          "basic_cleaning"
                        }
                      >
                        <div className="grid-item">
                          <img
                            src={greenCircleIcon}
                            style={{ width: 60, mixBlendMode: "multiply" }}
                          />
                          <span
                            className="text-below"
                            style={{ fontSize: "12px" }}
                          >
                            Ganarpro Basic cleaning
                          </span>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="4"
                        onClick={() => changeAppView("paint")}
                        disabled={
                          localStorage.getItem(`ganarpro_app${user?.id}`) ===
                          "paint"
                        }
                      >
                        <div className="grid-item">
                          <img src={orangeCircle} style={{ width: 60 }} />
                          <span
                            className="text-below"
                            style={{ fontSize: "12px" }}
                          >
                            Ganarpro Painting
                          </span>
                        </div>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {user && profile?.subscriptions?.length === 0 && !loading ? (
                <div className="pt-2 mx-2 mx-md-4 d-none d-sm-none d-md-block">
                  <div
                    className=" text-primary body-text border-1 p-2 "
                    style={{ border: "1px solid green" }}
                  >
                    <p className="fw-bolder blue-100 p-0 m-0">Free trial</p>
                    <p className="p-0 m-0">
                      {free_mode_count}/10 actions taken
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/*<div className="mx-3 mx-md-6">*/}
              {/*  /!* <img src={question} alt="question" /> *!/*/}
              {/*  <HelpIcon width="24" height="24" fill="#AAAAAA" />*/}
              {/*</div>*/}

              {user ? (
                <NavDropdown
                  title={myAccount}
                  id="basic-nav-dropdown"
                  className="me-2 me-md-0"
                >
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    <Link
                      to="profile"
                      className="px-4 py-3 d-flex justify-content-start text-decoration-none active"
                    >
                      <UserProfileIcon fill="#888888" height="24" width="24" />
                      <div className="ms-3">User profile</div>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.1">
                    {" "}
                    <a
                      href="mailto:support@ganarpro.com"
                      className="px-4 py-3 d-flex justify-content-start text-decoration-none active"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size="lg"
                        style={{ fontSize: "20px", color: "gray" }}
                      />
                      <div className="ms-3">Contact Support</div>
                    </a>
                  </NavDropdown.Item>

                  <NavDropdown.Item>
                    <Link
                      to="/referral/invite"
                      className="ms-4 py-3 d-flex justify-content-start text-decoration-none active"
                    >
                      <FontAwesomeIcon
                        icon={faHandshake}
                        size="lg"
                        className="me-3"
                        style={{ fontSize: "20px", color: "gray" }}
                      />
                      <span style={{ fontSize: "12px" }}>Referral</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link
                      to="/pricing"
                      className="ms-4 py-3 d-flex justify-content-start text-decoration-none active"
                    >
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        size="lg"
                        className="me-3"
                        style={{ fontSize: "20px", color: "gray" }}
                      />
                      <span style={{ fontSize: "12px" }}>Pricing</span>
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    <Link
                      to="#"
                      className="ms-3 py-3 d-flex justify-content-start text-decoration-none active"
                      onClick={onLogoutHandler}
                    >
                      <LogoutIcon height="25" width="24" fill="#888888" />
                      <div className="ms-3">Log Out</div>
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <div className="pt-2 mx-2 mx-md-4 d-none d-sm-none d-md-block">
                  <div className="d-md-flex d-none text-primary body-text">
                    <Link
                      to="/login"
                      className="px-4 py-3 d-flex justify-content-start text-decoration-none active"
                    >
                      {" "}
                      Login
                    </Link>
                  </div>
                </div>
              )}
              <div className="py-2">
                <ProfileBox
                  isAuthenticated={!!user}
                  logoutHandler={onLogoutHandler}
                  title={
                    <div
                      className="profile-icon text-uppercase rounded-circle bg-primary text-white fs-6 px-2 py-2"
                      onClick={handleShowProfileBox}
                    >
                      {initials}
                    </div>
                  }
                />
              </div>
            </div>
          )}

          <div
            className={classNames(
              showMobileNavbar ? "open-menu" : "close-menu"
            )}
          >
            <div className="wrapper__menu__header align-items-center">
              <div className="wrapper__menu__header__img">
                {/* <img src={logo} style={{ width: 36 }} alt="logo" /> */}
                <span className="white">CONSTRUCTION CLEAN PARTNERS</span>
              </div>
              <div
                className="wrapper__menu__header__close"
                onClick={handleCloseNavigationMenu}
              >
                <CloseIcon
                  fill="white"
                  className="menu_close"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                />
              </div>
            </div>
            <div className="wrapper__menu__input">
              <div className="mx-4 position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search in Sales Cloud"
                />

                <SearchIcon
                  fill="#BBBBBB"
                  width="20"
                  height="20"
                  className="icon"
                />
              </div>
            </div>

            <SidebarV3
              show={toggleSideBar}
              toggleSideBarHandler={toggleSideBarHandler}
              closeSidebarHandler={closeSidebarHandler}
              scroll={true}
              backdrop={true}
            />
          </div>
        </StyledHeader>
        {pricingBannerShow && free_mode_count > 6 && (
          <Container
            className="d-flex gap-5 align-items-center pe-5"
            style={{ borderRadius: "5px", border: "solid 1px lightgray" }}
          >
            <div className="d-flex flex-column mx-5 my-4 text-start flex-grow-1">
              <h3>
                Exclusive Invitation - Unlock Lifetime Access All to Ganarpro
                software services.
              </h3>
              <p>
                This is a rare opportunity to invest once and reap the benefits
                for a lifetime. Once this offer is gone, it won’t be available
                again.
              </p>
            </div>
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => setPricingModelShow(true)}
            >
              View Details
            </Link>
            <Link
              style={{ textDecoration: "none", color: "gray" }}
              onClick={handleDismissOffer}
            >
              Dismiss
            </Link>
          </Container>
        )}
      </div>
      <PricingModal
        show={pricingModalShow}
        app="lifetime_offer"
        onHide={() => {
          dispatch({
            type: UPDATE_PRICING_MODAL,
            pricing_modal: false,
          });
          setPricingModelShow(false);
        }}
      />
    </div>
  );
};

export default Navbar;
