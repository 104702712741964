import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";

const ProposalPreviewFormHeader = ({ disable_fields }) => {
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.proposalSections);
  const values = useSelector((state) => state.proposalValues);

  // State.getStateByCodeAndCountry();

  const handleChange = (evt) => {
    const { name, value: newValue } = evt?.target || evt;
    dispatch({ type: PROPOSAL_SET_VALUES, payload: { [name]: newValue } });
    // setProposalValues((prev) =>({
    //   ...prev,
    //   [name]: newValue,
    // }));
  };

  return (
    <>
      <Row className="d-flex justify-content-between py-4">
        <Col md="6">
          {sections.scope_name?(
            <Form.Control
              as="textarea"
              name="scope_name"
              className="w-100 h-100"
              placeholder="Scope Name"
              style={{ border: 'none', background: 'none', fontSize: '18px', fontWeight: 'bold' }}
              onChange={handleChange}
              value={values?.scope_name}
              disabled={disable_fields? true : false}
            />
          ) : ""}
        </Col>
        <Col md="6">
          {sections.company_name?(
            <div className="text-end">
              {user?.company_name ? (
                <h3 className="fw-bolder">{user.company_name}</h3>
              ) : (
                <Link to="/profile">
                  <p>
                    Click to fill <span className="fw-bolder">Company Name</span>{" "}
                    information on profile page
                  </p>
                </Link>
              )}
              <p className="m-0">{user?.company_street}</p>
              <p>
                {user?.company_city}, {user?.company_state} {user?.company_zip}
              </p>
            </div>
          ) : ""}
        </Col>
      </Row>
    </>
  );
};

export default ProposalPreviewFormHeader;
