import React from "react";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { Col, Form } from "react-bootstrap";
import { isSubscriptionActive } from "../../../utils/helpers/helper";

const CheckBoxContainer = ({
  row,
  isMainCheckboxChecked,
  selectedContacts,
  onContactSelection,
}) => {
  const dispatch = useDispatch();

  // const handleFlagClick = (e) => {
  //   if (!user) {
  //     handleSetLoginModal();
  //   } else if (!isSubscriptionActive(user, price_id, user, free_mode_count)) {
  //     handleSetPaymentModal();
  //   } else {
  //     handleSetFreeMode();
  //     // dispatch(updatePlanRoom({ flag: true, company_account_name: row.name }));
  //   }
  // };
  const { contact_id } = row;
  return (
    <div className="text-center cursor-pointer">
      <Form.Group
        as={Col}
        md={1}
        className="mb-3 checkbox m-0 d-flex col-1"
        controlId={`project-${contact_id}`}
      >
        <Form.Check
          type="checkbox"
          name={`contact-${contact_id}`}
          className="mb-4 fw-bolder ms-0"
          checked={selectedContacts?.includes(contact_id)}
          onChange={(e) => onContactSelection(contact_id)}
        />
      </Form.Group>
    </div>
  );
};

export default CheckBoxContainer;
