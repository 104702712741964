const ListIcon = ({
  style = {},
  fill = "white",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 18 18"
}) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 1H18V3H5V1ZM1.5 3.5C1.10218 3.5 0.720644 3.34196 0.43934 3.06066C0.158035 2.77936 0 2.39782 0 2C0 1.60218 0.158035 1.22064 0.43934 0.93934C0.720644 0.658035 1.10218 0.5 1.5 0.5C1.89782 0.5 2.27936 0.658035 2.56066 0.93934C2.84196 1.22064 3 1.60218 3 2C3 2.39782 2.84196 2.77936 2.56066 3.06066C2.27936 3.34196 1.89782 3.5 1.5 3.5ZM1.5 10.5C1.10218 10.5 0.720644 10.342 0.43934 10.0607C0.158035 9.77936 0 9.39782 0 9C0 8.60218 0.158035 8.22064 0.43934 7.93934C0.720644 7.65804 1.10218 7.5 1.5 7.5C1.89782 7.5 2.27936 7.65804 2.56066 7.93934C2.84196 8.22064 3 8.60218 3 9C3 9.39782 2.84196 9.77936 2.56066 10.0607C2.27936 10.342 1.89782 10.5 1.5 10.5ZM1.5 17.4C1.10218 17.4 0.720644 17.242 0.43934 16.9607C0.158035 16.6794 0 16.2978 0 15.9C0 15.5022 0.158035 15.1206 0.43934 14.8393C0.720644 14.558 1.10218 14.4 1.5 14.4C1.89782 14.4 2.27936 14.558 2.56066 14.8393C2.84196 15.1206 3 15.5022 3 15.9C3 16.2978 2.84196 16.6794 2.56066 16.9607C2.27936 17.242 1.89782 17.4 1.5 17.4ZM5 8H18V10H5V8ZM5 15H18V17H5V15Z"
      fill={fill}
    />
  </svg>
);

export default ListIcon;
