import React, { useState } from "react";
import { classNames } from "../../../components/helpers/helpers";
import SearchBar from "../../../components/searchBar/SearchBar";
import SearchIcon from "../../../assets/icons/SearchIcon";
import MapIcon from "../../../assets/icons/MapIcon";
import ListIcon from "../../../assets/icons/ListIcon";
import Search from "../../../components/searchBar/Search";
import Button from "../../../components/button/Button";
import { useSelector } from "react-redux";
import * as htmlToImage from "html-to-image"
import { useLocation, useNavigate } from "react-router-dom";

const ProjectBoardNav = ({
  selectedTab,
  setSelectedTab,
  setQueryParams,
  setSearchLatLng,
  handleGetLocation,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleSearch = (e) => {
    e.preventDefault();
    setShowSearch(!showSearch);
  };

  const handleSetLatLng = (latLng) => {
    setSearchLatLng(latLng);
    handleSubmitFilter("", latLng);
  };

  const handleSubmitFilter = (value = null, latLng = "") => {
    let search = "";
    if (typeof value === "string") {
      search = value?.split(",")[0];
      if (!search) {
        search = searchValue;
      }
    }
    const values = {
      searchValue: search.toString() ?? "",
      sortItem: "",
      latitude: latLng.lat ?? "",
      longitude: latLng.lng ?? "",
    };
    setQueryParams(values);
  };

  const handleShowSearch = (e) => {
    if (e.target.name === "search-location") {
      setShowSearch(true);
      return;
    }
    if (e.target.name === "input-search") {
      setShowSearch(true);
      return;
    }
    if (e.target.name === "search-mobile") {
      setShowSearch(true);
      return;
    }
    e.stopPropagation();
    setShowSearch(!showSearch);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e);
    setShowSearch(true);
  };

  const handleShowSaveSearchModal = () => {
    setShowSaveSearchModal(true);
  };

  const captureScreen = async () => {
    const element = document.querySelector('.srr-card.project-srr');

    // Save the original styles
    const originalOverflow = element.style.overflow;
    const originalHeight = element.style.height;

    // Temporarily expand the element to fit its content
    element.style.overflow = 'visible';
    element.style.height = 'auto';

    try {
        const dataUrl = await htmlToImage.toPng(element);
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'screenshot.png';
        downloadLink.click();
    } catch (error) {
        console.error('Error capturing screenshot:', error);
    } finally {
        // Restore the original styles
        element.style.overflow = originalOverflow;
        element.style.height = originalHeight;
    }
  };


  const handleNavigate = () => {
    const queryParams = new URLSearchParams(location.search);
    const newUrl = `/project_board${location.search}`;
    navigate(newUrl);
  };

  return (
    <div className="bg-primary position-relative">
      <div className="d-flex justify-content-between d-md-none">
        <div
          className="text-white d-flex col-4 justify-content-center cursor-pointer py-3"
          onClick={handleShowSearch}
        >
          <SearchIcon fill="#BBBBBB" width="20" height="20" className="me-2" />
          <div>Search</div>
        </div>

        <div
          className={classNames(
            "text-white d-flex col-4 justify-content-center cursor-pointer py-3",
            selectedTab === "map" ? "project-board-nav-active" : ""
          )}
          onClick={() => setSelectedTab("map")}
        >
          <MapIcon fill="white" width="21" height="21" className="icon me-2" />
          <div>Map</div>
        </div>
        <div
          className={classNames(
            "text-white d-flex col-4 justify-content-center cursor-pointer py-3",
            selectedTab === "list" ? "project-board-nav-active" : ""
          )}
          onClick={() => setSelectedTab("list")}
        >
          <ListIcon fill="white" width="18" height="18" className="icon me-2" />
          <div>List</div>
        </div>
      </div>

      <div className="d-none d-md-flex justify-content-between align-items-center px-2 py-3">
        <div className="wrapper__menu__input m-1">
          <form onSubmit={handleSearch}>
            <Search
              showSearchHandler={handleShowSearch}
              activeSearch={showSearch}
              searchValue={searchValue}
              setSearchValue={handleSearchChange}
              handleSearchChange={handleSearchChange}
              customClassInput="form-control project-board-search"
              placeHolder="Search by project name or location"
              setSearchLatLng={handleSetLatLng}
              handleGetLocation={handleGetLocation}
            />
          </form>
        </div>
        <div className="text-white flex-grow-1">
          Switch to <button className="btn btn-primary border border-black" onClick={handleNavigate}>
            Project Board
          </button>
        </div>
        <div className="d-flex justify-content-end flex-grow-1 filter-container">
          <Button
            customClassName="btn-board-nav bg-white"
            onClick={captureScreen}
          >
            Capture Screenshot
          </Button>
        </div>
      </div>

      {showSearch ? (
        <div className="position-absolute bg-white w-100 show-search-mobile">
          <div>
            <SearchBar
              hideFilters={true}
              showSearchHandler={handleShowSearch}
              activeSearch={showSearch}
              searchValue={searchValue}
              handleShowSaveSearchModal={handleShowSaveSearchModal}
              handleSearchChange={handleSearchChange}
              setSearchLatLng={handleSetLatLng}
              handleGetLocation={handleGetLocation}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProjectBoardNav;
