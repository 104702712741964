const BuildingIcon = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 24 24"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 2H9C7.897 2 7 2.897 7 4V9.586L2.293 14.293C2.15319 14.4329 2.05798 14.611 2.01942 14.805C1.98085 14.9989 2.00065 15.2 2.07632 15.3827C2.15199 15.5654 2.28013 15.7215 2.44454 15.8314C2.60895 15.9413 2.80225 16 3 16V21C3 21.2652 3.10536 21.5196 3.29289 21.7071C3.48043 21.8946 3.73478 22 4 22H20C20.2652 22 20.5196 21.8946 20.7071 21.7071C20.8946 21.5196 21 21.2652 21 21V4C21 2.897 20.103 2 19 2ZM11 20H5V14.414L8 11.414L11 14.414V20ZM19 20H13V16C13.198 16.0004 13.3916 15.942 13.5563 15.8322C13.721 15.7224 13.8494 15.5662 13.9252 15.3833C14.0009 15.2004 14.0206 14.9991 13.9818 14.805C13.9429 14.6109 13.8473 14.4327 13.707 14.293L9 9.586V4H19V20Z"
      fill={fill}
    />
    <path
      d="M11 6H13V8H11V6ZM15 6H17V8H15V6ZM15 10.031H17V12H15V10.031ZM15 14H17V16H15V14ZM7 15H9V17H7V15Z"
      fill={fill}
    />
  </svg>
);

export default BuildingIcon;
