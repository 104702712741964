import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import blackCircleIcon from "../../assets/img/blackCircle.jpg";
import blueCircleIcon from "../../assets/img/blueCircle.jpg";
import greenCircleIcon from "../../assets/img/greenCircle.jpg";

const StyledCard = styled(Card)`
  margin: 20px 0;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled(Card.Header)`
  background-color: #f8f9fa;
  border-bottom: none;
  font-weight: bold;
`;

const CardBody = styled(Card.Body)`
  padding: 20px;
`;

const Price = styled.div`
  margin: 20px 0;
  font-size: 2em;
  color: #333;
`;

const OriginalPrice = styled.div`
  font-size: 1em;
  color: #666;
  text-decoration: line-through;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
`;

const Feature = styled.li`
  margin-bottom: 10px;
`;

const PurchaseBadge = styled(FontAwesomeIcon)`
  color: green;
  margin-left: 10px;
`;

const PlanButton = styled(Button)`
  width: 100%;
`;

const Plan = ({ title, features, price, icon, purchased }) => (
  <Card className="text-start p-2" style={{ height: "300px" }}>
    <Card.Body>
      <Row className="fw-">
        <Card.Img
          variant="top"
          src={icon}
          className="col-md-2"
          style={{ width: "100px" }}
        />
        <Card.Title className="col-md-7 align-self-center fw-bolder">
          {title}
        </Card.Title>
      </Row>
      <Card.Text className="fw-semibold" style={{ height: "100px" }}>
        {features}
      </Card.Text>
      <Card.Text className="mt-4 mb-0">Starting</Card.Text>
      <Card.Text>
        <span className="h4">{price}</span>/month
      </Card.Text>
      <Row className="justify-content-end">
        <Col md={4} className="text-end fw-bold">
          UPRGRADE
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

const PlansContainer = () => {
  const plansData = [
    {
      title: "Ganarpro All Pro Cleaning",
      features:
        " Professional Pricing and Proposal construction bidding tools, such as\n" +
        "        Projects, Calculator, Proposal, Prequalify, Planroom, QualKey,\n" +
        "        Capability Statement, Contact Outreach, and Site Visit Sheet.",
      price: "46",
      purchased: true,
      icon: blackCircleIcon,
    },
    {
      title: "Ganarpro Business",
      features:
        "Sales and bidding support tools, such as Projects, " +
        "Prequalify, Planroom, QualKey, Capability Statement," +
        " and Contact Outreach. Excludes pricing and proposal tools.",
      price: "$28.25",
      purchased: true,
      icon: blueCircleIcon,
    },
    {
      title: "Ganarpro Basic Cleaning",
      features:
        " Professional Pricing and Proposal construction bidding tools, such as Calculator, and Proposal.",
      price: "22.50",
      purchased: true,
      icon: greenCircleIcon,
    },
    {
      title: "Document Starter",
      features: "Most advanced tools for managing your company information",
      price: "12",
      purchased: true,
      icon: greenCircleIcon,
    },
  ];

  return (
    <Container>
      <div className="row justify-content-center my-5 ">
        <h1 className="my-2">Plans and pricing for Ganarpro Cloud Apps</h1>
        <h3 className="mb-md-5">
          <a
            href="https://www.ganarpro.com/compare-plans/"
            target="_blank"
            rel="noreferrer"
          >
            see product features
          </a>
        </h3>
      </div>
      <Row>
        {plansData.map((plan, index) => (
          <Col md={3} sm={4} lg={3} key={index}>
            <Plan {...plan} />
          </Col>
        ))}
      </Row>
      <Row className="justify-content mt-5">
        <h2 className="text-start mt-5 fw-bolder">
          Exclusive business features
        </h2>
        <Col md={3} className="text-start mt-5">
          <h5 className="fw-bolder"> Instant access, no downloads</h5>
          <p>
            Ganarpro is a web based application, you can use all tools from your
            desktop and mobile device instantly, and at all times.
          </p>
        </Col>
        <Col md={3} className="text-start mt-5">
          <h5 className="fw-bolder">Affordable and simple pricing</h5>
          <p>
            Subscribe to all apps or subscribe to each product individually. Use
            the apps that your business needs most to succeed in the
            construction industry.
          </p>
        </Col>
        <Col md={3} className="text-start mt-5">
          <h5 className="fw-bolder">Find active construction projects</h5>
          <p>
            Use the map to locate commercial construction projects. View project
            details with decision maker contact information.
          </p>
        </Col>
        <Col md={3} className="text-start mt-5">
          <h5 className="fw-bolder">Calculate winning prices</h5>
          <p>
            Enter building square footage to estimate a bid amount. Easily
            adjust the estimated price and save your estimate for further
            configuration.
          </p>
        </Col>
        <Col md={3} className="text-start mt-5">
          <h5 className="fw-bolder">Generate proposals</h5>
          <p>
            Use our scope of work templates and merge your company details into
            proposals for quick generation.
          </p>
        </Col>
        <Col md={3} className="text-start mt-5">
          <h5 className="fw-bolder">Find customers</h5>
          <p>
            Access 1,000+ blank prequalification applications to fill in and
            submit to start receiving invitation to bid requests directly to
            your email inbox.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PlansContainer;
