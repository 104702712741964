import { PROJECT_TYPE_DETAIL_REQUEST } from "./proposalConstants";

export const BID_AMOUNT_SET_VALUES = "BID_AMOUNT_SET_VALUES";
export const BID_AMOUNT_RESET_VALUES = "BID_AMOUNT_RESET_VALUES";
export const BID_AMOUNT_PRICING_DETAIL_REQUEST =
  "BID_AMOUNT_PRICING_DETAIL_REQUEST";
export const BID_AMOUNT_PRICING_DETAIL_SUCCESS =
  "BID_AMOUNT_PRICING_DETAIL_SUCCESS";
export const BID_AMOUNT_PRICING_DETAIL_FAIL = "BID_AMOUNT_PRICING_DETAIL_FAIL";

export const STATE_LABOR_PRICING_DETAIL_REQUEST =
  "STATE_LABOR_PRICING_DETAIL_REQUEST";
export const STATE_LABOR_PRICING_DETAIL_SUCCESS =
  "STATE_LABOR_PRICING_DETAIL_SUCCESS";
export const STATE_LABOR_PRICING_DETAIL_FAIL =
  "STATE_LABOR_PRICING_DETAIL_FAIL";
export const STATE_LABOR_PRICING_LIST_REQUEST =
  "STATE_LABOR_PRICING_LIST_REQUEST";
export const STATE_LABOR_PRICING_LIST_SUCCESS =
  "STATE_LABOR_PRICING_LIST_SUCCESS";
export const STATE_LABOR_PRICING_LIST_FAIL = "STATE_LABOR_PRICING_LIST_FAIL";
export const PROJECT_TYPE_PRICING_LIST_REQUEST =
  "PROJECT_TYPE_PRICING_LIST_REQUEST";
export const PROJECT_TYPE_PRICING_LIST_SUCCESS =
  "PROJECT_TYPE_PRICING_LIST_SUCCESS";
export const PROJECT_TYPE_PRICING_LIST_FAIL = "PROJECT_TYPE_PRICING_LIST_FAIL";

export const COST_PROFIT_SET_VALUES = "COST_PROFIT_SET_VALUES";
export const COST_PROFIT_RESET_VALUES = "COST_PROFIT_RESET_VALUES";

export const CLEANUP_ESTIMATE_CREATE_REQUEST =
  "CLEANUP_ESTIMATE_CREATE_REQUEST";
export const CLEANUP_ESTIMATE_CREATE_SUCCESS =
  "CLEANUP_ESTIMATE_CREATE_SUCCESS";
export const CLEANUP_ESTIMATE_CREATE_FAIL = "CLEANUP_ESTIMATE_CREATE_FAIL";

export const CLEANUP_ESTIMATE_LIST_REQUEST = "CLEANUP_ESTIMATE_LIST_REQUEST";
export const CLEANUP_ESTIMATE_LIST_SUCCESS = "CLEANUP_ESTIMATE_LIST_SUCCESS";
export const CLEANUP_ESTIMATE_LIST_FAIL = "CLEANUP_ESTIMATE_LIST_FAIL";

export const CLEANUP_ESTIMATE_DETAIL_REQUEST =
  "CLEANUP_ESTIMATE_DETAIL_REQUEST";
export const CLEANUP_ESTIMATE_DETAIL_SUCCESS =
  "CLEANUP_ESTIMATE_DETAIL_SUCCESS";
export const CLEANUP_ESTIMATE_DETAIL_FAIL = "CLEANUP_ESTIMATE_DETAIL_FAIL";

export const CLEANUP_ESTIMATE_UPDATE_REQUEST =
  "CLEANUP_ESTIMATE_UPDATE_REQUEST";
export const CLEANUP_ESTIMATE_UPDATE_SUCCESS =
  "CLEANUP_ESTIMATE_UPDATE_SUCCESS";
export const CLEANUP_ESTIMATE_UPDATE_FAIL = "CLEANUP_ESTIMATE_UPDATE_FAIL";

export const CLEANUP_ESTIMATE_DELETE_REQUEST =
  "CLEANUP_ESTIMATE_DELETE_REQUEST";
export const CLEANUP_ESTIMATE_DELETE_SUCCESS =
  "CLEANUP_ESTIMATE_DELETE_SUCCESS";
export const CLEANUP_ESTIMATE_DELETE_FAIL = "CLEANUP_ESTIMATE_DELETE_FAIL";
//

export const CALCULATION_INFO_REQUEST = "CALCULATION_INFO_REQUEST";
export const CALCULATION_INFO_SUCCESS = "CALCULATION_INFO_SUCCESS";
export const CALCULATION_INFO_FAIL = "CALCULATION_INFO_FAIL";
export const CALCULATION_INFO_SAVED = "CALCULATION_INFO_SAVED";
