import {
  EMAIL_TEMPLATE_FAIL,
  EMAIL_TEMPLATE_REQUEST,
  EMAIL_TEMPLATE_SUCCESS,
} from "../../constants/emailTemplateConstants";

export const listEmailTemplateReducer = (
  state = { emailTemplates: [] },
  action
) => {
  switch (action.type) {
    case EMAIL_TEMPLATE_REQUEST:
      return { loading: true, emailTemplates: [] };
    case EMAIL_TEMPLATE_SUCCESS:
      return {
        loading: false,
        emailTemplates: action.payload,
      };
    case EMAIL_TEMPLATE_FAIL:
      return { loading: false, emailTemplates: [], error: action.payload };
    default:
      return state;
  }
};
