import React, { useEffect, useState } from "react";
import SelectInput from "../../../components/input/SelectInput";
import Spinner from "../../../components/spinner/Spinner";
import ProjectDataCard from "../components/ProjectDataCard";
import Pagination from "../../../components/pagination/Pagination";
import { DropdownButton, Dropdown, Row, Col, Form } from "react-bootstrap";
import PaginationV2 from "../../../components/pagination/PaginationV2";
import MapLoader from "../../../assets/img/map-loader.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import {
  addPageFavorite,
  fetchPageFavorite,
  removePageFavorite,
} from "../../../store/actions/users/users.actions";

const LargeScreen = ({
  loading,
  selectedTab,
  count,
  selectedSortItem,
  projects,
  currentPage,
  sortItems,
  favouriteProjects,
  handleSelectedSortItem,
  handlePageChanged,
  handleArchive,
  handleFavourite,
  map,
  handleProjectDetailCLick,
  authenticatedUser,
  firstTimeLoading,
  isMainCheckboxChecked,
  openEmailModalHandler,
  selectedProjects,
  handleMainCheckboxChange,
  handleProjectSelection,
}) => {
  const { user } = useSelector((state) => state.userDetails);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { user: auth } = useSelector((state) => state.auth);
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const dispatch = useDispatch();
  const checkFav = (id) => {
    const isFavourite = user?.project_favorites?.find(
      (project) => project?.id === id
    );
    if (isFavourite) {
      return true;
    }
    return false;
  };

  const indexOfLastProject = currentPage * 10;
  const indexOfFirstProject = indexOfLastProject - 10;
  const currentProjects = projects?.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const handleSubmitSelectedProjects = async () => {
    const selectedProjectIds = Object.keys(selectedProjects).filter(
      (projectId) => selectedProjects[projectId]
    );
  };
  useEffect(() => {
    dispatch(fetchPageFavorite("Project Board"));
  }, [dispatch]);
  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Project Board" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({
          page_name: "Project Board",
          page_url: "project_board",
        })
      );
    }
  };

  return (
    <>
      {/* <div className="col-6 ps-0">{map}</div> */}
      {/* {!currentProjects && !loading ? ( */}
      {firstTimeLoading ? (
        <div className="m-auto large-screen-pag full-loader">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="col-md-6 ps-0 map-section">
            {/* {currentProjects && !loading ? ( */}
            {firstTimeLoading ? (
              <img src={MapLoader} alt="map" className="w-100 h-100" />
            ) : (
              map
            )}
          </div>

          <div className="col-md-6 justify-content-between my-2 project-list-div">
            <div className="col-12 row row-cols-2  my-3 large-screen-details">
              {/*<Row>*/}
              {/*  <Col>*/}
              {/*    <Button>Send Email</Button>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <div className="d-flex px-0 col-5 align-items-center">
                <h4 className="text-start me-3 font-oswald lead-text">
                  Project Board
                </h4>
                <div className="text-start body-text">
                  Showing {count} Results.
                </div>

                {auth && (
                  <Col md={1}>
                    <FontAwesomeIcon
                      size={"xl"}
                      icon={isFavorite ? solidStar : regularStar}
                      style={{
                        color: isFavorite ? "gold" : "transparent",
                        stroke: isFavorite ? "" : "gold",
                        strokeWidth: isFavorite ? "0" : "20",
                        cursor: "pointer",
                      }}
                      onClick={handleFavoriteToggle}
                      className="fw-bolder"
                    />
                  </Col>
                )}
              </div>
              <div className="d-flex px-0 col-4 align-items-center ms-auto">
                <p className="mb-0 body-text-bold col-3">Sort by:</p>
                <div className="  col-3">
                  <DropdownButton
                    id="dropdown-item-button"
                    title={selectedSortItem}
                  >
                    {sortItems.map((item) => (
                      <Dropdown.Item
                        key={item.id}
                        onClick={() => handleSelectedSortItem(item.id)}
                        className="cursor-pointer select-input-item py-2 ps-4"
                        as="button"
                      >
                        {" "}
                        {item.value}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {/*<SelectInput selectedItem={selectedSortItem}>*/}
                  {/*  {sortItems.map((item) => (*/}
                  {/*    <div*/}
                  {/*      key={item.id}*/}
                  {/*      onClick={() => handleSelectedSortItem(item.id)}*/}
                  {/*      className="cursor-pointer select-input-item py-2 ps-4"*/}
                  {/*    >*/}
                  {/*      {item.value}*/}
                  {/*    </div>*/}
                  {/*  ))}*/}
                  {/*</SelectInput>*/}
                </div>
              </div>

              {user && (
                <div className="col-3 d-flex px-0 align-items-center">
                  <Link
                    to="/myganarpro/favorites"
                    className="text-decoration-none"
                  >
                    {" "}
                    <p className="fw-bolder m-0">
                      {" "}
                      {user?.project_favorites.length} Saved Project
                    </p>{" "}
                  </Link>
                </div>
              )}
            </div>
            <Row className="ms">
              {/*<Form.Group*/}
              {/*  as={Col}*/}
              {/*  md={4}*/}
              {/*  className="mb-3 checkbox m-0 d-flex ms-5"*/}
              {/*  controlId="parentCheckBox"*/}
              {/*>*/}
              {/*  <Form.Check*/}
              {/*    type="checkbox"*/}
              {/*    name="selectAll"*/}
              {/*    label={*/}
              {/*      selectedProjects.length > 0*/}
              {/*        ? `${selectedProjects.length} selected projects `*/}
              {/*        : `selected projects`*/}
              {/*    }*/}
              {/*    className="mb-4 fw-bolder ms-0"*/}
              {/*    checked={isMainCheckboxChecked}*/}
              {/*    onChange={handleMainCheckboxChange}*/}
              {/*  />*/}
              {/*</Form.Group>*/}
              <Col className=" col-lg-2 pe-lg-0 col-md-5">
                {/*<Button onClick={openEmailModalHandler}>Send Mass Email</Button>*/}
              </Col>
            </Row>
            {/* <div className="col-12 mt-4 mt-lg-0 row row-cols-2 pb-4 col-12 overflow-scroll project-board-card-container"> */}
            <div className="col-12 mt-4 mt-lg-0 project-bb overflow-scroll">
              {/* <div className="mt-4 mt-lg-0 row row-cols-2 col-12 pb-4 overflow-scroll"> */}
              {loading ? (
                <div className="m-auto large-screen-pag">
                  <Spinner />
                </div>
              ) : (
                currentProjects?.map((project) => (
                  <ProjectDataCard
                    key={project.id}
                    handleProjectDetailCLick={handleProjectDetailCLick}
                    handleArchive={handleArchive}
                    handleFavourite={handleFavourite}
                    isFavourite={checkFav(project.id)}
                    {...project}
                    authenticatedUser={authenticatedUser}
                    isMainCheckboxChecked={isMainCheckboxChecked}
                    selectedProjects={selectedProjects}
                    onProjectSelection={handleProjectSelection}
                  />
                ))
              )}
              <div className="col-12 large-screen-pag">
                {loading ? (
                  ""
                ) : (
                  <PaginationV2
                    totalRecords={projects?.length}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={handlePageChanged}
                    pageNumber={currentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LargeScreen;
