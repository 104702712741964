const PenIcon = ({
  style = {},
  fill = "#000",
  width = "100%",
  className = "",
  viewBox = "0 0 19 19"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.97 5.26999C18.0627 5.17748 18.1362 5.06759 18.1864 4.94662C18.2366 4.82564 18.2624 4.69596 18.2624 4.56499C18.2624 4.43402 18.2366 4.30434 18.1864 4.18337C18.1362 4.0624 18.0627 3.95251 17.97 3.85999L15.14 1.02999C15.0475 0.937289 14.9376 0.863742 14.8166 0.81356C14.6957 0.763379 14.566 0.737549 14.435 0.737549C14.304 0.737549 14.1743 0.763379 14.0534 0.81356C13.9324 0.863742 13.8225 0.937289 13.73 1.02999L9.24 5.51999L5.35 1.62999C4.57 0.849993 3.3 0.849993 2.52 1.62999L0.62 3.52999C-0.16 4.30999 -0.16 5.57999 0.62 6.35999L4.51 10.25L0 14.76V19H4.24L8.76 14.48L12.65 18.37C13.6 19.32 14.88 18.97 15.48 18.37L17.38 16.47C18.16 15.69 18.16 14.42 17.38 13.64L13.49 9.74999L17.97 5.26999ZM2.04 4.93999L3.93 3.03999L5.2 4.30999L4.02 5.49999L5.43 6.90999L6.62 5.71999L7.82 6.91999L5.92 8.81999L2.04 4.93999ZM13.27 12.38L12.08 13.57L13.49 14.98L14.68 13.79L15.95 15.06L14.05 16.96L10.16 13.07L12.06 11.17L13.27 12.38ZM3.41 17H2V15.59L11.61 5.97999L12.91 7.27999L13.02 7.38999L3.41 17ZM13.02 4.55999L14.43 3.14999L15.84 4.55999L14.43 5.96999L13.02 4.55999Z"
      fill={fill}
    />
  </svg>
);

export default PenIcon;

// <svg
//   width="20"
//   height="21"
//   viewBox="0 0 20 21"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     d="M3.33587 18C3.40337 18 3.47087 17.9917 3.53754 17.975L6.87087 17.1417C7.01754 17.105 7.15171 17.0292 7.25838 16.9225L17.5025 6.67836C17.8175 6.36336 17.9909 5.94503 17.9909 5.50003C17.9909 5.05503 17.8175 4.63669 17.5025 4.32169L16.1809 3.00003C15.5509 2.37003 14.4542 2.37003 13.8242 3.00003L3.58004 13.2442C3.47343 13.3509 3.39769 13.4846 3.36087 13.6309L2.52754 16.9642C2.49676 17.0871 2.4944 17.2153 2.52063 17.3393C2.54686 17.4632 2.601 17.5795 2.67893 17.6794C2.75686 17.7792 2.85653 17.86 2.97036 17.9156C3.0842 17.9711 3.2092 18 3.33587 18ZM15.0025 4.17836L16.3242 5.50003L15.0025 6.82169L13.6809 5.50003L15.0025 4.17836ZM4.92171 14.2592L12.5025 6.67836L13.8242 8.00003L6.24254 15.5809L4.48087 16.0209L4.92171 14.2592Z"
//     fill="white"
//   />
// </svg>;
