const HideIcon = ({
  style = {},
  fill = "white",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 20 20",
  handleClick = () => {}
}) => (
  <svg
    onClick={handleClick}
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
    className={`svg-icon ${className || ""}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 17C10.9462 17 11.8102 16.897 12.5982 16.719L10.8412 14.962C10.5682 14.983 10.2912 15 10.0002 15C4.64922 15 2.57622 11.154 2.07422 10C2.45117 9.15881 2.96027 8.38334 3.58222 7.703L2.18422 6.305C0.646223 7.972 0.0632231 9.651 0.0522231 9.684C-0.0167566 9.88936 -0.0167566 10.1116 0.0522231 10.317C0.0732231 10.383 2.36722 17 10.0002 17ZM10.0002 3C8.16322 3 6.65422 3.396 5.39622 3.981L1.70722 0.292999L0.293223 1.707L18.2932 19.707L19.7072 18.293L16.3882 14.974C19.0022 13.023 19.9352 10.359 19.9492 10.317C20.0182 10.1116 20.0182 9.88936 19.9492 9.684C19.9272 9.617 17.6332 3 10.0002 3ZM14.9722 13.558L12.6922 11.278C12.8822 10.888 13.0002 10.459 13.0002 10C13.0002 8.359 11.6412 7 10.0002 7C9.54122 7 9.11222 7.118 8.72322 7.309L6.91522 5.501C7.90774 5.16041 8.95093 4.991 10.0002 5C15.3512 5 17.4242 8.846 17.9262 10C17.6242 10.692 16.7602 12.342 14.9722 13.558Z"
      fill={fill}
    />
  </svg>
);

export default HideIcon;
