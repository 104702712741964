import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProposalPreviewFormFooter = () => {
  const { project_name, bid_amount, proposal_template } = useSelector(
    (state) => state.proposalValues
  );
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const ganarpro_app = localStorage.getItem(`ganarpro_app${user?.id}`);
  const paint_app = ganarpro_app ? ganarpro_app == "paint" : !user?.is_cleaning_company;
  const sections = useSelector((state) => state.proposalSections);
  // let bidAmount = bid_amount?.replace(",", "");

  return (
    <>
      {sections.footer ? (
        !paint_app?
        <>
          <Row className="d-flex justify-content-between py-4">
            <div className="col-md-10">
              <span className="fw-bolder">{user?.company_name?.toUpperCase()}</span>{" "}
              - Cleaning Proposal for{" "}
              <span className="fw-bolder">{project_name?.toUpperCase()} </span>
              {bid_amount === "" ? "" : `$${parseInt(bid_amount).toLocaleString()}`}
            </div>
          </Row>
          {user?.file_url && (
            <Row>
              <Col className="text-end">
                {sections.logo? (
                  <Image
                    src={user?.file_url}
                    style={{ width: "`50px", height: "50px" }}
                  />
                ): ""}
              </Col>
            </Row>
          )}
        </>
        :
        <>
          {user?.file_url && (
            <Row>
              <Col className="text-end">
                {sections.logo? (
                  <Image
                    src={user?.file_url}
                    style={{ width: "`50px", height: "50px" }}
                  />
                ): ""}
              </Col>
            </Row>
          )}
          <Row className="text-center">
            <span className="fw-bolder">{proposal_template?.includes("Paint") ? "Paint" : "Drywall"} - for {project_name?.toUpperCase()} </span>
          </Row>
          <Row className="text-center">
            <span>{bid_amount === "" ? "" : `$${parseInt(bid_amount).toLocaleString()}`}</span>
          </Row>
        </>
      ) : ""}
    </>
  );
};

export default ProposalPreviewFormFooter;
