import React, { useEffect } from "react";
import "./siteRouteWindow.css";
import { useDispatch, useSelector } from "react-redux";
import { addProjectSiteRoute, removeProjectSiteRoute } from "../../../store/actions/projects/projects.action";
import { UPDATE_PRICING_MODAL } from "../../../store/actionTypes";

const SiteRouteWindow = ({ project }) => {
  const dispatch = useDispatch();
  const { siteRoutesProjects } = useSelector((state) => state.projects);
  const { user: profile } = useSelector((state) => state.userDetails);

  const addProjectToRouteWindow = (e) => {
    if (profile.used_srr_project == profile.free_srr_projects) {
      dispatch({
        type: UPDATE_PRICING_MODAL,
        pricing_modal: true,
      })
      return;
    }
    dispatch(addProjectSiteRoute(project?.id))
  }

  return (
    <div>
      <div className="">
        {(project?.est_break_ground_date || project?.project_completion) &&
          <div className="d-flex gap-2" style={{ fontSize: 10, marginBottom: 10 }}>
            <div className="d-flex flex-column flex-grow-1 text-start">
              {project?.est_break_ground_date && <p>Break Ground</p>}
              {project?.project_completion && <p>Completion</p>}
            </div>
            <div className="d-flex flex-column flex-grow-1 text-start">
              {project?.est_break_ground_date &&
                <p>
                  {new Date(project?.est_break_ground_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                </p>
              }
              {project?.project_completion &&
                <p>
                  {new Date(project?.project_completion).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                </p>
              }
            </div>
          </div>
        }
        <div>
          <p>{project?.name}</p>
        </div>
        <div>
          <div className="mx-auto">
              {!siteRoutesProjects.filter(srr => srr.id === project?.id).length &&
                <button className="btn btn-outline-primary py-1" onClick={addProjectToRouteWindow}>Add to Site Route</button>
              }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteRouteWindow;
