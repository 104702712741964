import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import {
  FormTitle,
  CustomForm,
  CustomInput,
  CustomFormGroup,
} from "./StyledComponents";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown";
import { getRegions } from "../../../store/actions/gc_qualify/gcQualify.actions";
import { useDispatch } from "react-redux";
import {
  standardizeUrl,
  working_area_database,
} from "../../../utils/helpers/helper";

const CompanyPresence = ({ formData, handleFormDataChange }) => {
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [aboutUs, setAboutUs] = useState(`${
    formData["company_name"] || ""
  } works with commercial general contractors as a subcontractor completing the ${
    formData["company_specialty"] || ""
  } scope.\n We estimate ${
    formData["company_specialty"] || ""
  } bids via the plans and physical site visit walk throughs.
  `);
  const [isAboutUsEdited, setIsAboutUsEdited] = useState(false);

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSetWebsite = (data) => {
    setCompanyWebsite(data);
    handleFormDataChange("company_website", data);
  };
  const handleSetWebsiteURL = (data) => {
    data = standardizeUrl(data);
    setCompanyWebsite(data);
    handleFormDataChange("company_website", data);
  };

  const handleAboutUs = (data) => {
    setAboutUs(data);
    handleFormDataChange("about_us", data);
  };

  useEffect(() => {
    setCompanyWebsite(formData["company_website"]);
    setAboutUs(formData["about_us"]);
  }, [dispatch, formData]);

  useEffect(() => {
    if (
      !isAboutUsEdited &&
      formData["company_name"] &&
      formData["company_specialty"]
    ) {
      handleFormDataChange("about_us", aboutUs);
    }
  }, [isAboutUsEdited]);

  return (
    <Container className="justify-content-center ">
      <Row>
        <Col md={8} lg={12}>
          <CustomForm
            className="col-md-12"
            onSubmit={handleSubmit}
            style={{ minHeight: "50vh" }}
          >
            <FormTitle className="text-start border-bottom">
              Business details
            </FormTitle>
            <Row className="justify-content-center">
              <Col md={6}>
                <CustomFormGroup controlId="formCompanyName" className="my-5">
                  <Form.Label className="text-center mb-3 fw-bolder h3">
                    Website
                  </Form.Label>
                  <CustomInput
                    type="text"
                    value={companyWebsite}
                    onChange={(e) => handleSetWebsite(e.target.value)}
                    placeholder="Enter company website"
                    required
                    onBlur={(e) => handleSetWebsiteURL(e.target.value)}
                  />
                </CustomFormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Modify your About us Statement</h3>
                <Form.Control
                  as="textarea"
                  name="about_us"
                  className="form-control mt-n3 h3"
                  onChange={(e) => handleAboutUs(e.target.value)}
                  value={aboutUs}
                  style={{ height: "100px" }}
                />
              </Col>
            </Row>
            <Row></Row>
          </CustomForm>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyPresence;
