import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PROPOSAL_SET_VALUES } from "../../store/constants/proposalConstants";
import { iteratee } from "lodash";

const SimpleRichTextEditor = () => {
  const proposalValues = useSelector((state) => state.proposalValues);
  const { projectTypes } = useSelector((state) => state.projectTypeList);
  const [editorContent, setEditorContent] = useState(proposalValues?.user_scope || proposalValues?.scope);
  const [projectName, setProjectName] = useState();

  const dispatch = useDispatch();

  const handleEditorChange = (event, setEditorContent) => {
    // setEditorContent(event.target.innerHTML);
    dispatch({
      type: PROPOSAL_SET_VALUES,
      payload: {
        user_scope: event.target.innerHTML
      }
    })
  };

  useEffect(() => {
    setEditorContent(proposalValues?.user_scope || proposalValues?.scope);
  }, [proposalValues?.proposal_template, proposalValues?.scope, proposalValues?.user_scope])

  return (
    <div className="editor-container">
      <div
        style={{ textTransform: "initial", fontSize: "15px" }}
        className="editor"
        contentEditable={true}
        dangerouslySetInnerHTML={{ __html: editorContent }}
        onBlur={(e) =>
          handleEditorChange(e, setEditorContent)
        }
        onPaste={(e) => e.preventDefault()}
      ></div>

    </div>
  );
};

export default SimpleRichTextEditor;
