import {
  CONTACT_LIST_FAIL,
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  DOWNLOAD_CONTACT_LIST_FAIL,
  DOWNLOAD_CONTACT_LIST_REQUEST,
  DOWNLOAD_CONTACT_LIST_SUCCESS,
} from "../../constants/contactConstants";

export const contactListReducer = (
  state = { contacts: [], recent_emails: [] },
  action
) => {
  switch (action.type) {
    case CONTACT_LIST_REQUEST:
      return { loading: true, contacts: [] };
    case CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        ...action.payload,
        contacts: action.payload.results,
      };
    case CONTACT_LIST_FAIL:
      return { loading: false, contacts: [], error: action.payload };
    default:
      return state;
  }
};

export const userContactDeleteReducer = (state = { contacts: [] }, action) => {
  switch (action.type) {
    case CONTACT_LIST_REQUEST:
      return { loading: true, contacts: [] };
    case CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        ...action.payload,
        contacts: action.payload.results,
      };
    case CONTACT_LIST_FAIL:
      return { loading: false, contacts: [], error: action.payload };
    default:
      return state;
  }
};

export const userCompanyDeleteReducer = (state = { contacts: [] }, action) => {
  switch (action.type) {
    case CONTACT_LIST_REQUEST:
      return { loading: true, contacts: [] };
    case CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        ...action.payload,
        contacts: action.payload.results,
      };
    case CONTACT_LIST_FAIL:
      return { loading: false, contacts: [], error: action.payload };
    default:
      return state;
  }
};

export const downloadContactListReducer = (
  state = { message: null, recent_emails: [] },
  action
) => {
  switch (action.type) {
    case DOWNLOAD_CONTACT_LIST_REQUEST:
      return { loading: true, message: null };
    case DOWNLOAD_CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        ...action.payload,
        message: action.payload.results,
      };
    case DOWNLOAD_CONTACT_LIST_FAIL:
      return { loading: false, message: null, error: action.payload };
    default:
      return state;
  }
};
