// StyledComponents.js
import styled from "styled-components";
import { Form, ListGroup } from "react-bootstrap";
import { Button } from "react-bootstrap";

export const ObjectiveCard = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:hover,
  &.selected {
    background-color: #e2e6ea;
  }

  &.selected svg {
    color: #0d6efd;
  }
`;

export const ObjectiveIcon = styled.span`
  color: #6c757d;
  min-width: 30px;
`;

export const ObjectiveText = styled.span`
  margin-left: 1rem;
  flex-grow: 1;
  color: #495057;
`;

export const ObjectiveCheckMark = styled.span`
  margin-left: auto;
  font-size: 1.5rem;
`;
export const ObjectiveListItem = styled(ListGroup.Item)`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: ${({ selected }) =>
    selected ? "2px solid #0d6efd" : "1px solid rgba(0, 0, 0, 0.125)"};
  background-color: ${({ selected }) => (selected ? "#e7f1ff" : "white")};

  &:hover {
    background-color: ${({ selected }) => (selected ? "#e7f1ff" : "#f8f9fa")};
  }

  .form-check-input {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 1rem;
`;

export const ToolButton = styled(Button)`
  margin: 0.5rem;
  width: 140px; // Adjust width as needed
  height: 50px; // Adjust height as needed
`;

export const FormTitle = styled.h3`
  color: #4b49ac; // Assuming this is the color from the image
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const CustomForm = styled(Form)`
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc; // Assuming this based on the image
`;

export const CustomInput = styled(Form.Control)`
  border-radius: 0.375rem;
  border: 1px solid #ced4da; // Use the border color as per your design
`;

export const CustomFormGroup = styled(Form.Group)`
  margin-bottom: 1.5rem;
`;
