import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./services.css";

export const Services = ({ img, title, text, link }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (title === "Research") {
      navigate("/project_research");
    } else if (title === "Project Board") {
      navigate("/project_board");
    } else {
      navigate("/site_router");
    }
  };

  return (
    <>
      <div className="d-flex flex-column align-items-start gap-3">
        <img src={img} className="service-img" />
        <h1 className="service-title">{title}</h1>
        <p className="service-para">{text}</p>
        <Button
          className="create_btn service-btn"
          variant="primary"
          size="sm" // Use "sm" for small size
          onClick={handleButtonClick}
        >
          {title === "Research" ? "Visit Table" : title == "Project Board" ? "Search Map" : "Create Route"}
        </Button>
      </div>
    </>
  );
};
