import axios from "axios";
import { toast } from "react-toastify";
import {
  FETCH_INVITE_HISTORY_START,
  FETCH_INVITE_HISTORY_SUCCESS,
  FETCH_INVITE_HISTORY_FAIL,
  SEND_INVITE_START,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_FAIL,
  SHOW_ALERT,
  HIDE_ALERT,
} from "../../constants/refferalConstants";
import { isEmpty } from "../../../utils/helpers/helper";

const getAuthConfig = (getState) => {
  let { access } = getState().auth; // Assuming token is stored in auth state
  if (!access) {
    access = localStorage.getItem("token"); // Fallback to localStorage
  }
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access}`,
    },
  };
};

export const fetchInviteHistory = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_INVITE_HISTORY_START });
  try {
    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/referral/history`,
      config
    );
    dispatch({
      type: FETCH_INVITE_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toast.error("Failed to fetch invite history");
    dispatch({
      type: FETCH_INVITE_HISTORY_FAIL,
      error: error.toString(),
    });
  }
};

export const sendInvite = (email) => async (dispatch, getState) => {
  dispatch({ type: SEND_INVITE_START });
  try {
    const body = JSON.stringify({ emails: email });

    let { access } = getState();
    if (isEmpty(access)) {
      access = JSON.parse(localStorage.getItem("token"));
    }

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/referral/send`,
      body,
      config
    );
    dispatch({
      type: SEND_INVITE_SUCCESS,
      payload: data,
    });
    toast.success("Invite sent successfully!");
  } catch (error) {
    let errorMessage = "Failed to send invite";
    if (
      error.response &&
      error.response.data &&
      error.response.data.existing_emails
    ) {
      errorMessage = `Referral email(s) already exist: ${error.response.data.existing_emails.join(
        ", "
      )}`;
    }
    toast.error(errorMessage);
    dispatch({
      type: SEND_INVITE_FAIL,
      error: errorMessage,
    });
  }
};

export const showAlert = (message) => ({
  type: SHOW_ALERT,
  payload: message,
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
});
