import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner/Spinner";
import ProjectDataCard from "../components/ProjectDataCard";
import { Row, Col, Button } from "react-bootstrap";
import PaginationV2 from "../../../components/pagination/PaginationV2";
import MapLoader from "../../../assets/img/map-loader.png";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import {
  addPageFavorite,
  fetchPageFavorite,
  removePageFavorite,
} from "../../../store/actions/users/users.actions";
import { removeAllProjectSiteRoute, updateSiteRouteNotes } from "../../../store/actions/projects/projects.action";
import LoginModal from "../../../components/LoginModal";
import { Link } from "react-router-dom";

const LargeScreen = ({
  loading,
  projects,
  currentPage,
  handlePageChanged,
  handleArchive,
  handleFavourite,
  map,
  handleProjectDetailCLick,
  authenticatedUser,
  firstTimeLoading,
  isMainCheckboxChecked,
  selectedProjects,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { user } = useSelector((state) => state.userDetails);
  const { siteRoutesNotes } = useSelector((state) => state.projects);
  const { user: auth } = useSelector((state) => state.auth);
  const { isFavorite, loading: fetchLoader } = useSelector(
    (state) => state.fetchPageFavorite
  );
  const handleSetModal = (e) => {
    e.preventDefault();
    setModalShow(true);
  };
  const dispatch = useDispatch();
  const checkFav = (id) => {
    const isFavourite = user?.project_favorites?.find(
      (project) => project?.id === id
    );
    if (isFavourite) {
      return true;
    }
    return false;
  };

  const indexOfLastProject = currentPage * 10;
  const indexOfFirstProject = indexOfLastProject - 10;
  const currentProjects = projects?.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  useEffect(() => {
    dispatch(fetchPageFavorite("Site Route Router"));
  }, [dispatch]);
  const handleFavoriteToggle = () => {
    if (isFavorite) {
      dispatch(removePageFavorite({ page_name: "Site Route Router" })); // Assuming page_url is the identifier
    } else {
      dispatch(
        addPageFavorite({
          page_name: "Site Route Router",
          page_url: "site_router",
        })
      );
    }
  };

  return (
    <>
      {firstTimeLoading ? (
        <div className="m-auto large-screen-pag full-loader">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="col-md-6 ps-0 map-section">
            {/* {currentProjects && !loading ? ( */}
            {firstTimeLoading ? (
              <img src={MapLoader} alt="map" className="w-100 h-100" />
            ) : (
              map
            )}
          </div>

          <div className="col-md-6 justify-content-between my-2 srr-list-div">
            <div className="col-12 row row-cols-2  my-3 large-screen-details">
              <div className="d-flex w-100">
                <div className="d-flex px-0 col-5 align-items-center flex-grow-1">
                  <h4 className="text-start me-3 font-oswald lead-text">
                    Site Route Router
                  </h4>
                  
                  {auth && (
                    <Col md={1}>
                      <FontAwesomeIcon
                        size={"xl"}
                        icon={isFavorite ? solidStar : regularStar}
                        style={{
                          color: isFavorite ? "gold" : "transparent",
                          stroke: isFavorite ? "" : "gold",
                          strokeWidth: isFavorite ? "0" : "20",
                          cursor: "pointer",
                        }}
                        onClick={handleFavoriteToggle}
                        className="fw-bolder"
                      />
                    </Col>
                  )}
                </div>
                <button className="btn btn-secondary w-10 mx-3" onClick={() => dispatch(removeAllProjectSiteRoute(0))}> Clear All </button>
                <button className="btn btn-primary w-15" onClick={() => dispatch(updateSiteRouteNotes(siteRoutesNotes))}> Save All Notes </button>
              </div>
              <h5 className="text-start me-3" style={{ fontWeight: 'normal' }}>
                Optimize job site visits by integrating route planning. <Link to="https://learn.ganarpro.com/site-route-router/">Learn more</Link>
              </h5>
            </div>
            <Row className="ms">
              <Col className=" col-lg-2 pe-lg-0 col-md-5"></Col>
            </Row>
            <div className="col-12 mt-4 mt-lg-0 srr-card project-srr overflow-scroll">
              {loading ? (
                <div className="m-auto large-screen-pag">
                  <Spinner />
                </div>
              ) : currentProjects.length ? (
                currentProjects?.map((project, index) => (
                  <ProjectDataCard
                    key={project.id}
                    handleProjectDetailCLick={handleProjectDetailCLick}
                    handleArchive={handleArchive}
                    handleFavourite={handleFavourite}
                    isFavourite={checkFav(project.id)}
                    {...project}
                    authenticatedUser={authenticatedUser}
                    isMainCheckboxChecked={isMainCheckboxChecked}
                    selectedProjects={selectedProjects}
                    count={index}
                  />
                ))
              ) : user?.id ? (
                <p className="text-start m-4" style={{ fontSize: '15px' }}>
                  + Add project to route 
                </p>
              ) : (
                <div>
                  <Button variant="primary" type="submit" onClick={handleSetModal}>
                    Login{" "}
                  </Button>
                  <h4>to see Site route router projects</h4>
                </div>
              )}
              <div className="col-12 large-screen-pag">
                {loading ? (
                  ""
                ) : (
                  <PaginationV2
                    totalRecords={projects?.length}
                    pageLimit={10}
                    pageNeighbours={1}
                    onPageChanged={handlePageChanged}
                    pageNumber={currentPage}
                  />
                )}
              </div>
            </div>
          </div>
          <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
      )}
    </>
  );
};

export default LargeScreen;
