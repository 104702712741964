import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import ProposalPreviewForm from "../ProposalPreviewForm";
import { Button } from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  form {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    height: auto !important;
  }
`;
const ProposalPreviewModal = (props) => {

  return (
    <StyledModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProposalPreviewForm disable_fields={true} />
      </Modal.Body>
    </StyledModal>
  );
};
export default ProposalPreviewModal;
