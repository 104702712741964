import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { StyledInput } from "../StyledInputWithLoader";
import { saveEmailBody } from "../../store/actions/users/users.actions";
import { useDispatch } from "react-redux";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
`;

// Styled component for the clickable columns
const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s, box-shadow 0.2s;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  flex-grow: 1;

  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
  }

  &:active {
    background-color: #e0e0e0;
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
  }
`;

const EmailModal = (props) => {
  const { user: profile } = useSelector((state) => state.userDetails);
  const { loading, error, body } = useSelector(
    (state) => state.emailBody
  );
  const [showSave, setShowSave] = useState(false);
  const [emailBody, setEmailBody] = useState();
  const dispatch = useDispatch()
  const { app, projectName } = props;

  useEffect(() => {
    app == "project_board" ?
      setEmailBody(profile?.project_email_body):
      setEmailBody(profile?.proposal_email_body?.replaceAll("[project name]", projectName));
  }, [profile?.project_email_body, profile?.proposal_email_body, projectName])

  useEffect(() => {
    if (!loading && !error && body) {
      app == "project_board" ?
      setEmailBody(body):
      setEmailBody(body?.replaceAll("[project name]", projectName));
    }
  }, [body])

  const handleAppSelection = (appName) => {
    if (props.onChange)
      props.onChange(appName, emailBody)
  };

  const handleChangeBody = (e) => {
    setEmailBody(e.target.innerHTML)
  }

  const handleSaveBody = (e) => {
    const div = document.getElementsByClassName('editor_email_body')[0];
    setEmailBody(div.innerHTML)
    const innerHTML = projectName != "" && projectName != null ? div?.innerHTML?.replaceAll(projectName, "[project name]") : div.innerHTML;
    dispatch(saveEmailBody(innerHTML, app == "project_board" ? "project" : "proposal"))
  }

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste behavior

    // Get the plain text from clipboard
    const text = e.clipboardData.getData('text/plain');

    // Insert the plain text at the cursor position
    document.execCommand('insertText', false, text);
  }

  return (
    <StyledModal
      {...props}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={props.closeButton}>
        <Modal.Title id="contained-modal-title-vcenter">
          Choose Email App
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {app !== "project_board" && (
          <Row className="d-flex justify-content-between">
            <Col>
              <h3>
                To complete your proposal submission, include a message in your
                email body and attach the proposal document.
              </h3>
            </Col>
          </Row>
        )}
        <Row className="d-flex justify-content-between">
          <StyledCol
            md={2}
            xs={6}
            onClick={() => handleAppSelection("default")}
          >
            Default
          </StyledCol>
          <StyledCol md={2} xs={6} onClick={() => handleAppSelection("gmail")}>
            Gmail
          </StyledCol>
          <StyledCol
            md={2}
            xs={6}
            onClick={() => handleAppSelection("outlook")}
          >
            Outlook
          </StyledCol>
          <StyledCol md={2} xs={6} onClick={() => handleAppSelection("yahoo")}>
            Yahoo Mail
          </StyledCol>
        </Row>
        {/* {app == "project_board" &&
          <> */}
            <Row className="d-flex justify-content-between mt-4">
              <Col>
                <h3>Insert your message for the body of the email</h3>
              </Col>
            </Row>
            <Row className="mx-4 pt-2">
              <div
                style={{ fontSize: "15px" }}
                className="editor_email_body"
                contentEditable={true}
                dangerouslySetInnerHTML={{
                  __html: emailBody,
                }}
                onPaste={handlePaste}
                onBlur={handleChangeBody}
              />
                <div className="d-flex mt-3 justify-content-end gap-2">
                  <Button className="btn btn-primary mt-3 col-sm-2 align-end" onClick={handleSaveBody}>
                    Save
                  </Button>
                </div>
            </Row>
          {/* </>
        } */}
      </Modal.Body>
    </StyledModal>
  );
};

EmailModal.defaultProps = {
  closeButton: true,
};

export default EmailModal;
