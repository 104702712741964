import React, { useEffect, useState } from "react";
import { Accordion, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-intl-tel-input/dist/main.css";
import Spinner from "../spinner/Spinner";
import styled from "styled-components";
import { StyledAccordionHeader, StyledForm } from "./CalculatorForm.styled";
import {
  BID_AMOUNT_SET_VALUES,
  COST_PROFIT_SET_VALUES,
} from "../../store/constants/mortgageConstant";
import ToolTip from "../tooltip/ToolTip";
import { JSONParse } from "mixpanel-browser/src/utils";

const StyledSpinner = styled(Spinner)`
  color: red;
  border: 1px red;
  .lds-dual-ring {
    text-align: center;
    color: red;
  }
`;

const CalculatorCostProfit = ({ bidAmount }) => {
  const values = useSelector((state) => state.costProfitValues);
  const { loading: bidAmountLaborLoader } = useSelector(
    (state) => state.bidAmountPricing
  );
  const {
    loading: stateLaborLoader,
    one_day_work,
    average_labor_rate,
  } = useSelector((state) => state.stateLaborPricing);
  const dispatch = useDispatch();

  const handleChange = (evt) => {
    const { name, value: newValue } = evt.target;

    dispatch({ type: COST_PROFIT_SET_VALUES, payload: { [name]: newValue } });

    if (name === 'laborers_on_site' || name === 'hours_crew_works_daily') {
      dispatch({
        type: COST_PROFIT_SET_VALUES,
        payload: {
          use_accurate_days_on_site: true,
         },
      });
    }
  };
  const accurateDaysOnSite = values?.accurate_days_on_site;

  const noOfDaysExpected = (bidAmount / one_day_work).toFixed(1);
  const numberOfDaysToUse = values.use_accurate_days_on_site
    ? accurateDaysOnSite
    : values?.noOfDaysExpected;

  const projectLaborCost =
    values?.laborers_on_site *
    values?.hours_crew_works_daily *
    numberOfDaysToUse *
    average_labor_rate;

  // const jobOverHeadAmount = values?.job_costs_over_head * bidAmount;
  // const projectProfitAmount =
  //   Number(bidAmount) - (Number(projectLaborCost) + Number(jobOverHeadAmount));

  const noOfDaysExpectedCal = values.use_number_of_days
    ? Number(values.noOfDaysExpected)
    : Number(noOfDaysExpected);

  const supplyCost = (5 / 100) * parseFloat(bidAmount);

  const noOfDaysExpectedCalToUse = values.use_accurate_days_on_site
    ? accurateDaysOnSite
    : noOfDaysExpectedCal;

  const mobilizationCost =
    10 * Number(values?.laborers_on_site) * noOfDaysExpectedCalToUse;

  const totalCost =
    parseFloat(projectLaborCost) +
    parseFloat(supplyCost) +
    parseFloat(mobilizationCost);
  // parseFloat(jobOverHeadAmount);

  const projectProfitAmount = parseFloat(bidAmount) - parseFloat(totalCost);

  const dailyMobilizationCost = Math.round(
    mobilizationCost / noOfDaysExpectedCalToUse
  );

  const dollar_contract_percent = localStorage.getItem(
    "dollar_contract_percent"
  )
    ? localStorage.getItem("dollar_contract_percent")
    : values?.dollar_contract_percent;
  const i_outsource = localStorage.getItem("i_outsource")
    ? JSON.parse(localStorage.getItem("i_outsource"))
    : values?.i_outsource;

  useEffect(() => {
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: {
        hourly_labor_rate: average_labor_rate,
        dollar_contract_percent,
        i_outsource,
      },
    });
  }, [average_labor_rate, dispatch, dollar_contract_percent]);

  const handleAccurateNumberOfDays = (evt) => {
    const { value: newValue } = evt.target;
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { accurate_days_on_site: newValue },
    });
  };

  const handleUseAccurateNumberOfDays = (evt) => {
    const { name } = evt.target;
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { [name]: evt.target.checked },
    });

    if (!evt.target.checked) {
      dispatch({
        type: COST_PROFIT_SET_VALUES,
        payload: { accurate_days_on_site: 0 },
      });
    }
  };
  const handleOutsourceButton = (evt) => {
    const { name } = evt.target;
    localStorage.setItem("i_outsource", evt.target.checked);

    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { [name]: evt.target.checked },
    });
  };

  const handleDollarAmount = (amount) => {
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: { dollar_contract_amount: amount !== "" ? parseInt(amount) : 0 },
    });
  };

  const handleDollarPercent = (percent) => {
    localStorage.setItem("dollar_contract_percent", percent);

    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: {
        dollar_contract_percent: percent !== 0 ? parseInt(percent) : 0,
      },
    });
  };

  useEffect(() => {
    const ganarpro_value = values?.use_number_of_days ? values?.noOfDaysExpected : noOfDaysExpected;
    const hours_per_day = values?.laborers_on_site * values?.hours_crew_works_daily;
    const multiple = hours_per_day / 32;
    const total_hours = values?.laborers_on_site * values?.hours_crew_works_daily * ganarpro_value;

    const days = Math.round(total_hours / hours_per_day / multiple);
    dispatch({
      type: COST_PROFIT_SET_VALUES,
      payload: {
        accurate_days_on_site: days,
       },
    });

  }, [values?.laborers_on_site, values?.hours_crew_works_daily, values?.use_accurate_days_on_site])

  return (
    <StyledForm className="">
      <p className="text-start fw-bolder">
        Adjust laborer schedules to impact payroll and mobilization costs.
      </p>
      <Row className="my-5 justify-content-between">
        {!i_outsource ? (
          <Form.Group as={Col} controlId="laborersonsite">
            <FloatingLabel
              controlId="laborersonsite"
              label="Laborers"
              className="mb-3"
            >
              <Form.Control
                name="laborers_on_site"
                type="number"
                className="form-control mt-n3"
                value={values?.laborers_on_site}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Form.Group>
        ) : (
          <Form.Group as={Col} controlId="laborersonsite">
            <FloatingLabel
              controlId="trade_company"
              label="Trade / Company"
              className="mb-3"
            >
              <Form.Control
                name="trade_company"
                type="text"
                placeholder="Trade / Company"
                className="form-control mt-n3"
                value={values?.trade_company}
                onChange={handleChange}
              />
            </FloatingLabel>
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          controlId="laborersonsite"
          className="align-self-center ms-5"
        >
          <Form.Check
            type="switch"
            name="i_outsource"
            label="I outsource"
            className="align-item-center"
            checked={i_outsource}
            onChange={handleOutsourceButton}
          />
        </Form.Group>
      </Row>

      {values?.i_outsource ? (
        <>
          <Row className="my-5">
            {values?.dollar_contract_percent < 1 && (
              <>
                <Form.Group
                  as={Col}
                  controlId="laborersonsite"
                  className="col-md-5"
                >
                  <FloatingLabel
                    controlId="dollar_contract_amount"
                    label="Dollar amount"
                    className="mb-3"
                  >
                    <Form.Control
                      name="dollar_contract_amount"
                      type="number"
                      min="0"
                      placeholder="Dollar amount"
                      className="form-control  mt-n3"
                      value={values?.dollar_contract_amount}
                      onChange={(e) => handleDollarAmount(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
              </>
            )}
            {values?.dollar_contract_amount < 1 && (
              <>
                <Form.Group
                  as={Col}
                  controlId="dollar_contract_percent"
                  className="col-md-3 ms-5"
                >
                  <FloatingLabel
                    controlId="dollar_contract_percent"
                    label="Percentage"
                    className="mb-3"
                  >
                    <Form.Control
                      name="dollar_contract_percent"
                      type="number"
                      min="0"
                      max="100"
                      placeholder="%"
                      className="form-control mt-n3"
                      value={values?.dollar_contract_percent}
                      onChange={(e) => handleDollarPercent(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Col className="align-self-center">
                  <h5> of contract amount</h5>
                </Col>
              </>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row className="my-5">
            <Form.Group
              as={Col}
              controlId="hourCrewWorksDaily"
              className="calculator-tool-tip-parent"
            >
              <span className="calculator-tool-tip">
                <ToolTip information="Number of hours you intend to allocate to pay each person per day. A standard workday typically consists of 8 hours, but you can adjust this to impact the labor payroll cost accordingly." />
              </span>
              <FloatingLabel
                controlId="hoursCrewWorksDaily"
                label="Hours worked by each Laborer"
                className="mb-3"
              >
                <Form.Control
                  name="hours_crew_works_daily"
                  type="number"
                  max="12"
                  min="1"
                  className="form-control mt-n3"
                  value={values?.hours_crew_works_daily}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
          {bidAmountLaborLoader || stateLaborLoader ? (
            <div className="text-center">
              <StyledSpinner />
            </div>
          ) : (
            <>
              <Row className="my-5">
                <Form.Group as={Col} controlId="hourlyLaborRate">
                  <FloatingLabel
                    controlId="hourlyLaborRate"
                    label="Hourly Laborer rate"
                    className="mb-3"
                  >
                    <Form.Control
                      name="hourly_labor_rate"
                      type="number"
                      className="form-control mt-n3"
                      value={values?.hourly_labor_rate}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  controlId="noOfDaysExpected"
                  className="calculator-tool-tip-parent"
                >
                  <span className="calculator-tool-tip">
                    <ToolTip information="The estimated number of days you will be on-site working is calculated by dividing the bid amount by the price per day in this state. This estimate is not set by a project manager or superintendent; Ganarpro uses this calculation based on the bid amount to determine the expected duration working for the provided bid amount." />
                  </span>
                  <FloatingLabel
                    controlId="noOfDaysExpected"
                    label="Ganarpro estimated days working"
                    className="mb-3"
                  >
                    <Form.Control
                      name="noOfDaysExpected"
                      type="number"
                      className="form-control mt-n3"
                      value={
                        values?.use_number_of_days
                          ? values?.noOfDaysExpected
                          : noOfDaysExpected
                      }
                      readOnly
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="daily_mobilization_cost"
                  md={6}
                  className="calculator-tool-tip-parent"
                >
                  <span className="calculator-tool-tip">
                    <ToolTip information="A cost of $10 per laborer is applied to account for daily driving fuel and daily non-production startup waste time." />
                  </span>
                  <FloatingLabel
                    controlId="daily_mobilization_cost"
                    label="Daily mobilization cost"
                    className="mb-3"
                  >
                    <Form.Control
                      name="daily_mobilization_cost"
                      type="number"
                      className="form-control mt-n3"
                      value={dailyMobilizationCost}
                      readOnly
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  md={6}
                  className="mb-3 checkbox m-0 d-flex"
                  controlId="use_accurate_days_on_site"
                >
                  <Form.Check
                    type="checkbox"
                    name="use_accurate_days_on_site"
                    label="I know number of days"
                    className="mb-4"
                    checked={values?.use_accurate_days_on_site}
                    onChange={handleUseAccurateNumberOfDays}
                  />
                  <span className="">
                    <ToolTip information="The number of days my crew will complete the project, do not use Ganarpro estimate to calculate my days worked on site" />
                  </span>
                </Form.Group>
                {values?.use_accurate_days_on_site && (
                  <Form.Group
                    as={Col}
                    controlId="noOfDaysExpected"
                    md={6}
                    className="calculator-tool-tip-parent"
                  >
                    <span className="calculator-tool-tip">
                      <ToolTip information="The number of days I estimate my crew will work the project" />
                    </span>
                    <FloatingLabel
                      controlId="accurate_days_on_site"
                      label="My estimate of days working"
                      className="mb-3"
                    >
                      <Form.Control
                        name="accurate_days_on_site"
                        type="text"
                        className="form-control mt-n3"
                        value={values?.accurate_days_on_site}
                        onChange={handleAccurateNumberOfDays}
                      />
                    </FloatingLabel>
                  </Form.Group>
                )}
              </Row>
            </>
          )}

          {/* <Row className="my-5">
            <Form.Group as={Col} controlId="profitMargin">
              <FloatingLabel
                controlId="profitMargin"
                label="Profit margin %"
                className="mb-3"
              >
                <Form.Control
                  name="profit_margin"
                  type="number"
                  className="form-control mt-n3"
                  value={profitMargin.toFixed(0)}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>
          </Row> */}
        </>
      )}
    </StyledForm>
  );
};
export default CalculatorCostProfit;
