import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import Spinner from "../../../components/spinner/Spinner";
import ProjectDataCard from "../../projectBoardList/components/ProjectDataCard";
import { Nav, Col, Container, Form, Row, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isSubscriptionActive } from "../../../utils/helpers/helper";
import {
  archiveProject,
  favouriteProject,
  fetchProjects,
  unFavouriteProject,
  deleteSearch,
} from "../../../store/actions/projects/projects.action";
import LoginModal from "../../../components/LoginModal";
import SubscriptionModal from "../../../components/subscriptionModal";
import { authUser } from "../../../store/selectors/users/user.selector";
import styled from "styled-components";
import {
  createPageView,
  getUserDetail,
} from "../../../store/actions/users/users.actions";
import { Link } from "react-router-dom";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";

const StyledSection = styled.section`
  background-color: rgb(249, 249, 251);
  min-height: 751px;
`;

const SavedSearhces = () => {
  const { savedSearches } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const { user: userProfile, loading } = useSelector(
    (state) => state.userDetails
  );
  const { loading: search_loading } = useSelector((state) => state.projects);
  const authenticatedUser = useSelector(authUser());
  const price_id = process.env.REACT_APP_PROJECT_APP;
  const dispatch = useDispatch();

  const STORY_HEADERS = [
    {
      prop: "name",
      title: "Name",
    },
    {
      prop: "projects_count",
      title: "Total Projects",
    },
    {
      prop: "id",
      title: "Action",
      cell: (row) => (
        <>
          {search_loading ? (
            <p>Loadding .... </p>
          ) : (
            <>
              <Button onClick={() => handleSearchRedirect(row.params)}>
                {" "}
                Visit Search{" "}
              </Button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  handleDeleteSearch(row.id);
                }}
                style={{ marginLeft: "15px" }}
              >
                Delete
              </button>
            </>
          )}
        </>
      ),
    },
  ];

  const handleSearchRedirect = (query) => {
    window.open(`/project_board?${query}`, "_self");
  };

  const handleDeleteSearch = (id) => {
    dispatch(deleteSearch(id, user.id));
    dispatch(getUserDetail());
  };

  const handleProjectDetailCLick = (e, link) => {
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!isSubscriptionActive(user, price_id, user, free_mode_count)) {
      setPaymentModalShow(true);
    } else {
      window.open(link, "_self");
    }
  };

  const handleArchive = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(archiveProject(id));
  };

  const handleFavourite = (e, type, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!isSubscriptionActive(user, price_id, user, free_mode_count)) {
      setPaymentModalShow(true);
    } else {
      dispatch(unFavouriteProject(id));
      dispatch(getUserDetail());
    }
  };

  const checkFav = ({ id }) => {
    const isFavourite = userProfile?.project_favorites?.find(
      (project_id) => project_id === id
    );
    if (isFavourite) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    dispatch(getUserDetail());
  }, [dispatch]);

  const path = window.location.pathname.slice(1);

  useEffect(() => {
    const pageRecord = "Saved Searches";
    const type = "Saved Searches";

    dispatch(createPageView(pageRecord, path, type));
  }, [dispatch, path]);

  return (
    <div>
      <div>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12 my-4">
              <Nav
                variant="underline"
                defaultActiveKey="/home"
                className="mx-5"
              >
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/favorites">
                    Saved Projects
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/hiddenProjects">
                    Hidden Projects
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/recentlyViewed">
                    Recently Viewed
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Link} to="/myganarpro/savedSearches">
                    Saved Searches
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <StyledSection>
        <div className="container py-5">
          <div className="row my-4">
            <div className="col-md-6 text-start">
              <h1 className="fw-bolder fa-3xl">
                Saved Searches(
                {userProfile?.saved_searches &&
                  userProfile?.saved_searches?.length}
                )
              </h1>
            </div>
            <div className="col-md-5">
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="row ">
            {loading || userProfile == null ? (
              <div className="m-auto large-screen-pag">
                <Spinner />
              </div>
            ) : (
              <DatatableWrapper
                body={userProfile?.saved_searches}
                headers={STORY_HEADERS}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 50,
                    options: [5, 10, 15, 20, 50],
                  },
                }}
              >
                <Row className="mb-4 p-2">
                  <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Filter />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                  >
                    <PaginationOptions />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
          </div>

          <LoginModal
            show={loginModalShow}
            onHide={() => setLoginModalShow(false)}
          />
          <SubscriptionModal
            show={paymentModalShow}
            onHide={() => setPaymentModalShow(false)}
          />
        </div>
      </StyledSection>
    </div>
  );
};

export default SavedSearhces;
