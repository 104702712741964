import React from "react";
import { Form, Col, Row } from "react-bootstrap";

const AccountCreationForm = ({ formData, onChange }) => {
  return (
    <Form>
      <Row class="justify-content-center">
        <Form.Group as={Col} md="6" controlId="formGridIndustryCategory">
          <Form.Label>Industry category (Required)</Form.Label>
          <Form.Select
            name="industryCategory"
            value={formData.industryCategory}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            required
          >
            <option value="">Select category</option>
            <option value="business-industrial">Business & Industrial</option>
            <option value="health">Health</option>
            <option value="education">Education</option>
            {/* Add more options as needed */}
          </Form.Select>
        </Form.Group>
      </Row>

      <fieldset>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={2}>
            Business size (Required)
          </Form.Label>
          <Col sm={10}>
            {[
              "Small - 1 to 10 employees",
              "Medium - 11 to 100 employees",
              "Large - 101 to 500 employees",
              "Very Large - 501+ employees",
            ].map((size, index) => (
              <Form.Check
                type="radio"
                label={size}
                name="businessSize"
                value={size}
                checked={formData.businessSize === size}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                id={`business-size-${index}`}
                key={`business-size-${index}`}
                required
              />
            ))}
          </Col>
        </Form.Group>
      </fieldset>

      <Form.Group as={Col} md="6" controlId="formGridWebsite">
        <Form.Label>Website</Form.Label>
        <Form.Control
          type="url"
          name="website"
          placeholder="https://example.com"
          value={formData.website}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Form.Group>

      {/* Add any other form inputs as needed */}
    </Form>
  );
};

export default AccountCreationForm;
