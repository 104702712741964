import {
  SITE_VISIT_CREATE_FAIL,
  SITE_VISIT_CREATE_REQUEST,
  SITE_VISIT_CREATE_SUCCESS,
  SITE_VISIT_DELETE_FAIL,
  SITE_VISIT_DELETE_REQUEST,
  SITE_VISIT_DELETE_SUCCESS,
  SITE_VISIT_DETAIL_FAIL,
  SITE_VISIT_DETAIL_REQUEST,
  SITE_VISIT_DETAIL_SUCCESS,
  SITE_VISIT_HEADING_RESSET_VALUES,
  SITE_VISIT_HEADING_SET_VALUES,
  SITE_VISIT_LIST_FAIL,
  SITE_VISIT_LIST_REQUEST,
  SITE_VISIT_LIST_SUCCESS,
  SITE_VISIT_RESSET_VALUES,
  SITE_VISIT_SET_VALUES,
  SITE_VISIT_UPDATE_FAIL,
  SITE_VISIT_UPDATE_REQUEST,
  SITE_VISIT_UPDATE_SUCCESS,
} from "../../constants/siteVisitConstants";

const initialValues = {
  today_date: new Date().toISOString().split("T")[0],
  project_name: "",
  project_address: "",
  company: "",
  superintendent: "",
  project_manager: "",
  square_foot: "",
  units: "",
  start_date: "",
  pressure_washing: "",
  flooring_type: "",
  stories: "",
  phases: "",
  completion_date: "",
  notes: "",
  disabled: false,
};

const initialHeadingValues = {
  main_heading: "Job estimation site visit checklist",
  start_date_heading: "Service start date",
  pressure_washing_heading: "Pressure washing",
  flooring_type_heading: "Flooring type",
  stories_heading: "Stories/ Number of buildings",
  phases_heading: "Number of Phases",
  completion_date_heading: "Project completion date",
  notes_heading: "Extra Notes",
};

export const SiteVisitReducer = (state = initialValues, action) => {
  switch (action.type) {
    case SITE_VISIT_SET_VALUES:
      return { ...state, ...action.payload };

    case SITE_VISIT_RESSET_VALUES:
      return initialValues;

    default:
      return state;
  }
};

export const SiteVisitHeadingReducer = (state = initialHeadingValues, action) => {
  switch (action.type) {
    case SITE_VISIT_HEADING_SET_VALUES:
      return { ...state, ...action.payload };

    case SITE_VISIT_HEADING_RESSET_VALUES:
      return initialHeadingValues;

    default:
      return state;
  }
};

export const SiteVisitActionReducer = (state = { site_visit: null, loading: false }, action) => {
  switch(action.type){
    case SITE_VISIT_CREATE_REQUEST:
    case SITE_VISIT_UPDATE_REQUEST:
      return { loading: true };

    case SITE_VISIT_CREATE_SUCCESS:
    case SITE_VISIT_UPDATE_SUCCESS:
      return { loading: false, error: false, site_visit: action.payload };

    case SITE_VISIT_CREATE_FAIL:
    case SITE_VISIT_UPDATE_FAIL:
      return { loading: false, error: action.payload, site_visit: null };

    default:
      return state;
  }
}

export const SiteVisitDetailsReducer = (state = { site_visit: null, loading: false }, action) => {
  switch(action.type){
    case SITE_VISIT_DETAIL_REQUEST:
      return { loading: true };
    case SITE_VISIT_DETAIL_SUCCESS:
      return { loading: false, error: false, site_visit: action.payload };
    case SITE_VISIT_DETAIL_FAIL:
      return { loading: false, error: action.payload, site_visit: null };
    default:
      return state
  }
}

export const SiteVisitListReducer = (state = { site_visits: [] }, action ) => {
  switch(action.type){

    case SITE_VISIT_LIST_REQUEST:
      return { loading: true };

    case SITE_VISIT_LIST_SUCCESS:
      return { loading: false, error: false, site_visits: action.payload };

    case SITE_VISIT_DELETE_SUCCESS:
      let site_visits = state.site_visits.filter((site_visit) => {
        return site_visit.id !== action.payload;
      });
      return { site_visits, loading: false };

    case SITE_VISIT_LIST_FAIL:
      return { loading: false, error: action.payload, site_visits: []}

    default:
      return state;
  }
};
