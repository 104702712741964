import axios from "axios";
import {
  isEmpty,
  toastError,
  toastSuccess,
} from "../../../utils/helpers/helper";
import {
  deleteSiteVisitAPI,
  downloadSiteVisitDetailAPI,
  getAllSiteVisitAPI,
  getSiteVisitDetailAPI,
  getSiteVisitDetailByProjectAPI,
  saveSiteVisitAPI,
  updateSiteVisitAPI,
} from "../../../utils/requests/site_visits";
import {
  SITE_VISIT_CREATE_FAIL,
  SITE_VISIT_CREATE_REQUEST,
  SITE_VISIT_CREATE_SUCCESS,
  SITE_VISIT_DELETE_FAIL,
  SITE_VISIT_DELETE_REQUEST,
  SITE_VISIT_DELETE_SUCCESS,
  SITE_VISIT_DETAIL_FAIL,
  SITE_VISIT_DETAIL_REQUEST,
  SITE_VISIT_DETAIL_SUCCESS,
  SITE_VISIT_DOWNLOAD_FAIL,
  SITE_VISIT_DOWNLOAD_REQUEST,
  SITE_VISIT_DOWNLOAD_SUCCESS,
  SITE_VISIT_HEADING_SET_VALUES,
  SITE_VISIT_LIST_FAIL,
  SITE_VISIT_LIST_REQUEST,
  SITE_VISIT_LIST_SUCCESS,
  SITE_VISIT_SET_VALUES,
  SITE_VISIT_UPDATE_FAIL,
  SITE_VISIT_UPDATE_REQUEST,
  SITE_VISIT_UPDATE_SUCCESS,
} from "../../constants/siteVisitConstants";

export const getAllSiteVisits = () => {
  return async (dispatch) => {
    dispatch({
      type: SITE_VISIT_LIST_REQUEST,
    });
    return getAllSiteVisitAPI()
      .then(async (response) => {
        dispatch({
          type: SITE_VISIT_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: SITE_VISIT_LIST_FAIL,
          payload: error,
        });
      });
  };
};

export const getSiteVisitDetails = (siteVisitId) => {
  return async (dispatch) => {
    dispatch({
      type: SITE_VISIT_DETAIL_REQUEST,
    });
    return getSiteVisitDetailAPI(siteVisitId)
      .then(async (response) => {
        dispatch({
          type: SITE_VISIT_DETAIL_SUCCESS,
          payload: response,
        });

        const newValues = Object.keys(response).reduce((value, key) => {
          if (
            response[key] !== "" &&
            response[key] !== null &&
            !key.includes("heading")
          ) {
            value[key] = response[key];
          }
          return value;
        }, {});

        const headings = Object.keys(response).reduce((value, key) => {
          if (
            response[key] !== "" &&
            response[key] !== null &&
            key.includes("heading")
          ) {
            value[key] = response[key];
          }
          return value;
        }, {});

        dispatch({
          type: SITE_VISIT_SET_VALUES,
          payload: newValues,
        });

        dispatch({
          type: SITE_VISIT_HEADING_SET_VALUES,
          payload: headings,
        });
      })
      .catch((error) => {
        toastError("Unable to get site visit details");
        dispatch({
          type: SITE_VISIT_DETAIL_FAIL,
          payload: error,
        });
      });
  };
};

export const getSiteVisitDetailsByProject = (projectName) => {
  return async (dispatch) => {
    dispatch({
      type: SITE_VISIT_DETAIL_REQUEST,
    });
    return getSiteVisitDetailByProjectAPI(projectName)
      .then(async (response) => {
        dispatch({
          type: SITE_VISIT_DETAIL_SUCCESS,
          payload: response,
        });

        const newValues = Object.keys(response).reduce((value, key) => {
          if (
            response[key] !== "" &&
            response[key] !== null &&
            key !== "today_date" &&
            !key.includes("heading")
          ) {
            value[key] = response[key];
          }
          return value;
        }, {});

        const headings = Object.keys(response).reduce((value, key) => {
          if (
            response[key] !== "" &&
            response[key] !== null &&
            key.includes("heading")
          ) {
            value[key] = response[key];
          }
          return value;
        }, {});

        if (Object.keys(newValues).length) {
          dispatch({
            type: SITE_VISIT_SET_VALUES,
            payload: newValues,
          });

          dispatch({
            type: SITE_VISIT_HEADING_SET_VALUES,
            payload: headings,
          });
        }
      })
      .catch((error) => {
        toastError("Unable to get site visit details");
        dispatch({
          type: SITE_VISIT_DETAIL_FAIL,
          payload: error,
        });
      });
  };
};

export const saveSiteVisit = (data, download = false) => {
  return async (dispatch) => {
    dispatch({
      type: SITE_VISIT_CREATE_REQUEST,
    });
    return saveSiteVisitAPI(data)
      .then(async (response) => {
        toastSuccess("Site Visit saved successfully");
        dispatch({
          type: SITE_VISIT_CREATE_SUCCESS,
          payload: response.data,
        });
        if (download)
          document.location.href = `/site_visit?show_tab=recent&download_id=${response.data.id}`;
        else document.location.href = "/site_visit?show_tab=recent";
      })
      .catch((error) => {
        toastError("Site visit creation failed");
        dispatch({
          type: SITE_VISIT_CREATE_FAIL,
          payload: error,
        });
      });
  };
};

export const updateSiteVisit = (siteVisitId, data, download = false) => {
  return async (dispatch) => {
    dispatch({
      type: SITE_VISIT_UPDATE_REQUEST,
    });
    return updateSiteVisitAPI(siteVisitId, data)
      .then(async (response) => {
        toastSuccess("Site Visit updated successfully");
        dispatch({
          type: SITE_VISIT_UPDATE_SUCCESS,
          payload: response,
        });

        if (download)
          document.location.href = `/site_visit?show_tab=recent&download_id=${siteVisitId}`;
        else document.location.href = "/site_visit?show_tab=recent";
      })
      .catch((error) => {
        toastError("Site visit update failed");
        dispatch({
          type: SITE_VISIT_UPDATE_FAIL,
          payload: error,
        });
      });
  };
};

export const deleteSiteVisit = (siteVisitId, editId) => {
  return async (dispatch) => {
    dispatch({
      type: SITE_VISIT_DELETE_REQUEST,
    });
    return deleteSiteVisitAPI(siteVisitId)
      .then(async (response) => {
        toastSuccess("Site Visit deleted successfully");
        dispatch({
          type: SITE_VISIT_DELETE_SUCCESS,
          payload: siteVisitId,
        });

        if (editId !== undefined) {
          window.location.href = "/site_visit?show_tab=recent";
        }
      })
      .catch((error) => {
        toastError("Site Visit Deletion Failed");
        dispatch({
          type: SITE_VISIT_DELETE_FAIL,
          payload: error,
        });
      });
  };
};

export const downloadSiteVisit =
  (siteVisitId, site_visit) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SITE_VISIT_DOWNLOAD_REQUEST,
      });
      let { access } = getState();
      if (isEmpty(access)) {
        access = JSON.parse(localStorage.getItem("token"));
      }
      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        responseType: "blob",
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/site_visits/download/${siteVisitId}`,
        site_visit,
        config
      );
      dispatch({
        type: SITE_VISIT_DOWNLOAD_SUCCESS,
      });
      const url = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${
          site_visit.project_name
            ? site_visit.project_name
            : new Date(site_visit.created).toISOString()
        }__Site_Visit_Sheet.docx`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;

      toastError("Site Visit Download Failed");
      dispatch({
        type: SITE_VISIT_DOWNLOAD_FAIL,
        payload: error.data,
      });
    }
  };
