import preQualImg from "../../../assets/img/Prequalify-200.png";
import projectImg from "../../../assets/img/projects-200.png";
import proposalImg from "../../../assets/img/Proposal-200.png";
// import calculatorImg from "../../../assets/img/calculator.jpg";
import calculatorImg from "../../../assets/img/calculator-200.png";
import prequelMasterKeyImg from "../../../assets/img/qualkey-200.png";
import ContactOutreachIcon from "../../../assets/img/contactoutreach-200.png";
import capabilitiesImg from "../../../assets/img/capabilitystatement-200.png";
import siteVisit from "../../../assets/img/site_visit-200.jpg";
import siteRoute from "../../../assets/img/site route  router-01.png"

export const Pages = {
  "Project Research": {
    id: "projects_overview",
    imgSrc: projectImg,
    description:
      "Construction project data for prospecting, analyzing, researching.",
    linkTo: "/projects_overview",
  },
  "Project Board": {
    id: "projects_overview",
    imgSrc: projectImg,
    description:
      "Construction project data for prospecting, analyzing, researching.",
    linkTo: "/projects_overview",
  },
  "Cleanup Calculator": {
    id: "cleanup_calculator_overview",
    imgSrc: calculatorImg,
    description:
      "Calculates bid amount estimates and analyze potential cost and profits. Win more bids.",
    linkTo: "/cleanup_calculator_overview",
  },
  "Cleanup Rates": {
    id: "cleanup_calculator_overview",
    imgSrc: calculatorImg,
    description:
      "Calculates bid amount estimates and analyze potential cost and profits. Win more bids.",
    linkTo: "/cleanup_calculator_overview",
  },
  "Proposal Generator": {
    id: "proposal",
    imgSrc: proposalImg,
    description:
      "Standardize proposal creation with templates. Satisfy customer needs with the correct scope of work.",
    linkTo: "/proposal",
  },
  "Proposal Templates": {
    id: "proposal",
    imgSrc: proposalImg,
    description:
      "Standardize proposal creation with templates. Satisfy customer needs with the correct scope of work.",
    linkTo: "/proposal",
  },
  PlanRoom: {
    id: "planify",
    imgSrc: preQualImg,
    description:
      "Unleash the Power of Pre-Qualification and Dynamic Plan Rooms.",
    linkTo: "/planify",
  },
  PreQualify: {
    id: "planify",
    imgSrc: preQualImg,
    description:
      "Unleash the Power of Pre-Qualification and Dynamic Plan Rooms.",
    linkTo: "/planify",
  },
  QualKey: {
    id: "qualkey",
    imgSrc: prequelMasterKeyImg,
    description:
      "Centralize company information to utilize in vendor application forms. Gather and answer up to 240 questions.",
    linkTo: "/qualkey",
  },
  "Capability Statement": {
    id: "capability_statement",
    imgSrc: capabilitiesImg,
    description:
      "A single-page summary highlighting your business operations and main contact point.",
    linkTo: "/capability_statement",
  },
  "Contact Outreach": {
    id: "contact_outreach",
    imgSrc: ContactOutreachIcon,
    description:
      "Tailored email marketing and contact management tool for North American construction industry professionals.",
    linkTo: "/contact_outreach",
  },
  "Site Visit Sheet":  {
    id: "site_visit",
    description:
      "Facilitate and manage construction site visits, streamlining the process of recording observations",
    imgSrc: siteVisit,
    linkTo: "/site_visit",
  },
  "Site Route Router": {
    id: "site_route_router",
    description: "Optimize job site visits, build personal connections, and uncover new business opportunities. With integrated route planning, it significantly enhances efficiency.",
    imgSrc: siteRoute,
    linkTo: "/site_router",
  }
  // You can add more items following the same pattern
};
