export const USER_REGION_REQUEST = "USER_REGION_REQUEST";
export const USER_REGION_LIST_SUCCESS = "USER_REGION_LIST_SUCCESS";
export const USER_REGION_LIST_FAIL = "USER_REGION_LIST_FAIL";
export const GC_QUALIFY_COMPANIES_REQUEST = "GC_QUALIFY_COMPANIES_REQUEST";
export const GC_QUALIFY_COMPANIES_LIST_SUCCESS =
  "GC_QUALIFY_COMPANIES_LIST_SUCCESS";
export const GC_QUALIFY_COMPANIES_FAIL = "GC_QUALIFY_COMPANIES_FAIL";

export const PLAN_ROOM_REQUEST = "PLAN_ROOM_REQUEST";
export const PLAN_ROOM_UPDATE_SUCCESS = "PLAN_ROOM_UPDATE_SUCCESS";
export const PLAN_ROOM_UPDATE_FAIL = "PLAN_ROOM_UPDATE_FAIL";

export const PRE_QUALIFY_REQUEST = "PRE_QUALIFY_REQUEST";
export const PRE_QUALIFY_UPDATE_SUCCESS = "PRE_QUALIFY_UPDATE_SUCCESS";
export const PRE_QUALIFY_UPDATE_FAIL = "PRE_QUALIFY_UPDATE_FAIL";
