import React, { useState } from "react";
import EmailIcon from "../../assets/icons/EmailIcon";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deleteContactActivity } from "../../store/actions/projects/projects.action";
import useIsMobile from "../../utils/helpers/use-is-mobile";

const ActivityRow = ({ activity }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { deleting_activity } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  // Convert HTML string to plain text
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(activity.email_body, "text/html");
  const textContent = parsedHtml.body.textContent || "";

  // Truncate text if it exceeds 80 characters
  const truncatedText =
    textContent.length > 80 ? textContent.substring(0, 80) : textContent;

  // Toggle between truncated and full text
  const displayedText = isExpanded ? textContent : truncatedText;

  const formatDate = (dateString) => {
    let date_str = dateString?.includes(":00Z") ? dateString : dateString+":00Z";
    const date = new Date(date_str);

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    const time = timeFormatter.format(date);
    const formattedDate = dateFormatter.format(date);

    return `${time} | ${formattedDate}`;
  }

  return (
    <>
      <tr style={{ minHeight: "20px" }}>
        <td className="px-2">
          <EmailIcon
            fill="currentColor"
            width="18"
            height="18"
            className="me-2"
          />
        </td>
        <td className="px-2">
          <div>
            <span dangerouslySetInnerHTML={{ __html: displayedText }} />
            {!isExpanded && textContent.length > 80 && (
              <span style={{ whiteSpace: "nowrap" }}>
                ...&nbsp;
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setIsExpanded(true)}
                >
                  See more
                </span>
              </span>
            )}
            {isExpanded && (
              <span
                style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
                onClick={() => setIsExpanded(false)}
              >
                See less
              </span>
            )}
          </div>
        </td>
        <td className="px-2" style={!isMobile ? { minWidth: '150px' } : {}}>{formatDate(activity.contact_date)}</td>
        <td className="px-2">
          <Button
            className="btn-danger"
            style={{ color: "#fff" }}
            onClick={(e) => dispatch(deleteContactActivity(activity?.id))}
            disabled={deleting_activity}
          >
            Delete{" "}
            {deleting_activity && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span className="visually-hidden">Loading...</span>{" "}
          </Button>
        </td>
      </tr>
      <tr style={{ height: "20px" }}>
        <td>
          {/* <div style={{ width: '2px', marginLeft: '8px', background: 'lightgray', color: 'transparent' }}>1</div> */}
        </td>
        <td></td>
        <td></td>
      </tr>
    </>
  );
};

export default ActivityRow;
