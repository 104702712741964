import React from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";

import "../Planify.css";
const CustomFileInput = ({ name, label, fileInputValue, onFileChange }) => {
  const inputId = `file-input-${name}`;

  return (
    <div className="custom-file-input">
      <Form.Label htmlFor={inputId}>Upload {label}</Form.Label>
      <InputGroup className="mb-3">
        <FormControl
          placeholder={fileInputValue || "No file selected..."}
          aria-label="File"
          aria-describedby={inputId}
          // value={fileName}
          readOnly
          className="file-name-input"
        />
        <Button
          variant="outline-primary"
          id={`${inputId}-button`}
          as="label"
          htmlFor={inputId}
          className="upload-button"
        >
          Browse
        </Button>
      </InputGroup>
      <input
        id={inputId}
        type="file"
        name={name}
        onChange={onFileChange}
        style={{ display: "none" }} // This hides the default file input
      />
    </div>
  );
};

export default CustomFileInput;
