// BusinessObjectivesForm.js
import React, { useState } from "react";
import { Container, Row, Col, Button, Form, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ObjectiveListItem } from "./StyledComponents";

// ... your objectives array and other imports

const objectives = [
  {
    id: "generate_leads",
    title: "Generate leads",
    description:
      "Download decision makers with Contact Outreach and get on bid lists by filling in subcontractor applications with PreQualify",
    // icon: "{icon({name: 'cart-shopping', family: 'classic' style: 'solid'})}",
    icon: "fa-tag",
  },
  {
    id: "our_leads",
    title: "Use our leads",
    description:
      "Projects offers active construction project leads and Planroom gives you opportunities from company website",
    icon: "fa-tag",
  },
  {
    id: "final_cleaning",
    title: "Quote Final Cleaning",
    description: "Estimate pricing and create cleaning proposals",
    icon: "fa-tag",
  },
  {
    id: "paint_proposal",
    title: "Create Painting Proposals",
    description: "4 available templates to select",
    icon: "fa-tag",
  },
  {
    id: "marketing_documents",
    title: "Create marketing documents",
    description: "Build your company Capability Statement",
    icon: "fa-tag",
  },
  {
    id: "company_details",
    title: "Manage my company details",
    description:
      "Use QualKey to gather and store all supporting details about your business",
    icon: "fa-tag",
  },
  // ... other objectives
];
const BusinessObjectivesForm = ({ formData, handleFormDataChange }) => {
  const [selectedObjectives, setSelectedObjectives] = useState({
    ...formData["business_objectives"],
  });
  const handleCheckboxChange = (id) => {
    setSelectedObjectives((prevSelectedObjectives) => {
      const updatedObjectives = {
        ...prevSelectedObjectives,
        [id]: !prevSelectedObjectives[id],
      };
      // Now we use updatedObjectives to update the parent component's state
      handleFormDataChange("business_objectives", updatedObjectives);
      // Return updatedObjectives to update local state
      return updatedObjectives;
    });
  };

  // ... rest of your component

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="fw-bolder my-5">Grow, become more efficient, and increase brand recognition</h2>
          <p className="h4 mb-5">
            For services that are personalized to your business, select the
            topics most important to you.
          </p>
          <Form>
            <ListGroup variant="flush" className="py-2">
              {objectives.map((obj) => (
                <ObjectiveListItem
                  key={obj.id}
                  selected={!!selectedObjectives[obj.id]}
                  onClick={() => handleCheckboxChange(obj.id)}
                  className="py-4 text-start"
                >
                  <FontAwesomeIcon
                    icon={obj.icon}
                    className="me-3"
                    size="2x"
                    style={{ color: "#0d6efd" }}
                  />

                  <div className="flex-grow-1">
                    <strong className="h4 fw-bolder">{obj.title}</strong>
                    <p className="h5 fw-normal my-2 text-start">
                      {obj.description}
                    </p>
                  </div>
                  <Form.Check
                    type="checkbox"
                    checked={!!selectedObjectives[obj.id]}
                    onChange={() => {}}
                  />
                </ObjectiveListItem>
              ))}
            </ListGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessObjectivesForm;
