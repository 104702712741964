import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectBoardNav from "./components/ProjectBoardNav";
import "./siteRouteRouter.css";
import {
  fetchProjects,
  fetchProjectsByLocation,
  archiveProject,
  favouriteProject,
  unFavouriteProject,
  saveSearch,
} from "../../store/actions/projects/projects.action";
import Mobile from "./views/Mobile";
import LargeScreen from "./views/LargeScreen";
import Map from "../../components/map/Map";
import { authUser } from "../../store/selectors/users/user.selector";
import Geolocation from "../../components/geolocation/Geolocation";
import { useSearchParams } from "react-router-dom";
import { isEmpty, isSubscriptionActive } from "../../utils/helpers/helper";
import LoginModal from "../../components/LoginModal";
import SubscriptionModal from "../../components/subscriptionModal";
import {
  getUserDetail,
} from "../../store/actions/users/users.actions";
import { emailTemplate } from "../../store/selectors/projects/project.selector";
import { useFeatureAccess } from "../../hooks/useFeatureAccess";
import PricingModal from "../../components/pricingModal";
import { UPDATE_PRICING_MODAL } from "../../store/actionTypes";

const sortItems = [
  {
    id: 1,
    key: "last_modified_date",
    value: "updated",
  },
  {
    id: 2,
    key: "created_date",
    value: "newest",
  },
  {
    id: 3,
    key: "bid_due_date",
    value: "Bid due date",
  },
  {
    id: 4,
    key: "sf_size",
    value: "Square Feet",
  },
  {
    id: 5,
    key: "project_completion",
    value: "Near completion",
  },
  {
    id: 6,
    key: "plan_drawings",
    value: "Plans Drawings",
  },
  {
    id: 7,
    key: "public_works",
    value: "Public works",
  },
];
const SiteRouteRouter = () => {
  const [selectedTab, setSelectedTab] = useState("list");
  const [selectedSortItem, setSelectedSortItem] = useState("");
  const [filters, setFilters] = useState([]);
  const [userLocation, setUserLocation] = useState({});
  const [userBound, setUserBound] = useState({});
  const [latLng, setLatLng] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryStringParam, setQueryStringParam] = useState("");
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);
  const boardListMapRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const { user: profile, loading: profileLoading } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [isMainCheckboxChecked, setMainCheckboxChecked] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const [emailContent, setEmailContent] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const emailTemplateBody = useSelector(emailTemplate());
  const price_id = process.env.REACT_APP_PROJECT_APP;
  const { hasFeatureAccess, enforceAccess } = useFeatureAccess("Projects");

  const handleMainCheckboxChange = () => {
    setMainCheckboxChecked((prevChecked) => !prevChecked);
    setSelectedProjects(
      !isMainCheckboxChecked
        ? projects
            .filter(
              (project) =>
                project.no_of_email_sent > 0 || project.no_of_contacts > 0
            )
            .map((project) => project.id)
        : []
    );
  };

  useEffect(() => {
    setSubjectContent(emailTemplateBody?.subject);
    setEmailContent(emailTemplateBody?.text);
  }, [emailTemplateBody]);

  const {
    projects,
    loading,
    count,
    pricingModalShow,
    siteRoutesProjects,
    email_templates,
  } = useSelector((state) => state.projects);
  const authenticatedUser = useSelector(authUser());
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      setFirstTimeLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    if (!isEmpty(userLocation)) {
      setLatLng({ latitude: userLocation?.lat, longitude: userLocation?.lng });
    }
  }, [userLocation]);

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    let savedParams = localStorage.getItem(`saved_params_${user?.id}`);
    savedParams = savedParams ? JSON.parse(savedParams) : {};
    let values = {};
    for (const param in currentParams) {
      if (param.includes("coordinates")) {
        values["coordinates"] = JSON.parse(currentParams[param]);
      }
    }

    if (
      !isEmpty(currentParams["latitude"]) &&
      !isEmpty(currentParams["longitude"])
    ) {
      setLatLng({
        latitude: currentParams["latitude"] ?? userLocation?.lat,
        longitude: currentParams["longitude"] ?? userLocation?.lng,
      });
      values["latitude"] = currentParams["latitude"] ?? "";
      values["longitude"] = currentParams["longitude"] ?? "";
      setZoom(10);
      values["bounds"] = calculateUserBound(
        parseFloat(currentParams["longitude"]),
        parseFloat(currentParams["latitude"])
      );
    }
    values["isQueryParams"] = true;
    if (
      (!values["sortItem"] || !selectedSortItem) &&
      !localStorage.getItem(`${user?.id}_sortId`)
    ) {
      setSelectedSortItem("updated");
      values["sortItem"] = {
        key: "last_modified_date",
        value: "last_modified_date",
      };
    } else {
      let stored_id = localStorage.getItem(`${user?.id}_sortId`);
      const item = sortItems.find((item) => item.id == stored_id);
      setSelectedSortItem(item.value);
      values["sortItem"] = {
        key: item.key,
        value: item.value,
      };
    }
    if (Object.keys(values).length > 1) {
      handleSetQueryString(values);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleGetLocation();
    }, 3000);

    return () => clearTimeout(timer);
  }, [userLocation]);

  const handleSetSelectedTab = (tab) => {
    setSelectedTab(tab);
  };

  const handlePageChanged = (data) => {
    const { currentPage } = data;
    setCurrentPage(currentPage);
  };

  const calculateUserBound = (longitude, latitude) => {
    const offset = parseFloat(6 / 10);
    const bounds = {
      south: parseFloat(latitude) - offset,
      west: parseFloat(longitude) - offset,
      north: parseFloat(latitude) + offset,
      east: parseFloat(longitude) + offset,
    };

    return bounds;
  };

  const handleSetQueryString = ({
    searchValue,
    longitude = "",
    latitude = "",
    bounds = "",
    isQueryParams = false,
  }) => {
    if (isEmpty(longitude)) {
      longitude = latLng?.longitude;
    }
    if (isEmpty(latitude)) {
      latitude = latLng?.latitude;
    }
    setFilters([searchValue]);


    var obj = {
      break_ground: 'present',
      longitude: longitude,
      latitude: latitude,
      coordinates: JSON.stringify(
        JSON.stringify(calculateUserBound(longitude, latitude))
      ),
    };
    if (!isEmpty(bounds)) {
      obj["coordinates"] = JSON.stringify(JSON.stringify(bounds));
    }
    const queryString = Object.keys(obj)
      .map((key) => {
        if (obj[key]) {
          return `${key}=${obj[key]}`;
        }
      })
      .filter((key) => !!key)
      .join("&");

    setQueryStringParam(queryString);
    setSearchParams(queryString);

    if (bounds || obj?.sort) {
      const query = JSON.parse(JSON.parse(obj?.coordinates));
      if (
        !isEmpty(query?.south) ||
        !isEmpty(query?.north) ||
        !isEmpty(query?.east) ||
        !isEmpty(query?.west)
      ) {
        dispatch(fetchProjects(queryString));
        return;
      }
      if (bounds) {
        dispatch(
          fetchProjectsByLocation(JSON.stringify(JSON.stringify(bounds)))
        );
      }

      return;
    }

    if (bounds) {
      dispatch(fetchProjectsByLocation(JSON.stringify(JSON.stringify(bounds))));
      return;
    }
    delete obj["longitude"];
    delete obj["latitude"];

    const requestQueryString = Object.keys(obj)
      .map((key) => {
        if (obj[key]) {
          return `${key}=${obj[key]}`;
        }
      })
      .filter((key) => !!key)
      .join("&");
    dispatch(fetchProjects(requestQueryString));
    return queryString;
  };

  const fetchProjectByLocations = (locations = {}) => {
    if (isEmpty(locations)) {
      locations = userBound;
    }

    if (!isEmpty(locations)) {
      const currentParams = Object.fromEntries([...searchParams]);
      if (currentParams === undefined) {
        return;
      }
      const values = {
        bounds: locations
      };
      const north = JSON.parse(currentParams["coordinates"])["north"];
      if (!firstTimeLoading || isEmpty(north)) {
        handleSetQueryString(values);
        delete values["bounds"];
        localStorage.setItem(
          `saved_params_${user?.id}`,
          JSON.stringify(values)
        );
      }
    }
  };

  const handleArchive = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(archiveProject(id));
    dispatch(getUserDetail())
  };

  const handleFavourite = (e, type, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!isSubscriptionActive(user, price_id, user, free_mode_count)) {
      setPaymentModalShow(true);
    } else {
      dispatch(getUserDetail());
      if (type === "favourite") {
        dispatch(favouriteProject(id));
        return;
      }
      dispatch(unFavouriteProject(id));
    }
  };

  const handleSaveSearch = (searchName) => {
    if (user) {
      const data = {
        name: searchName,
        params: queryStringParam,
        projects_count: count,
        userId: user.id,
      };
      dispatch(saveSearch(data));
      setShowSaveSearchModal(false);
    } else {
      setLoginModalShow(true);
    }
  };

  const handleSearchLatLng = (latLng = null) => {
    if (latLng) {
      setLatLng({ latitude: latLng.lat, longitude: latLng.lng });
      setZoom(zoom);
      return;
    }
  };

  const handleGetLocation = () => {
    let city;
    const latlng = new window.google.maps.LatLng(
      userLocation.lat,
      userLocation.lng
    );
    if (isEmpty(latLng)) {
      setLatLng({
        latitude: userLocation.lat,
        longitude: userLocation.lng,
      });
    }
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          fetchProjectByLocations(userBound);
          return;
        } else {
          if (!isEmpty(userBound)) {
            fetchProjectByLocations(userBound);
          }
        }
      } else {
        if (!isEmpty(userBound)) {
          fetchProjectByLocations(userBound);
        }
      }
    });
    createMap();
  };

  const createMap = () => {
    return (
      <Map
        id="myMap"
        options={{
          center: {
            lat: parseFloat(latLng?.latitude),
            lng: parseFloat(latLng?.longitude),
          },
          zoom: zoom,
        }}
        onMapLoad={(map) => {}}
        selectedLatLng={latLng}
        projects={projects}
        handleFetchProject={fetchProjectByLocations}
        mapZoom={zoom}
        setLatLng={setLatLng}
        setZoom={setZoom}
        boardListMapRef={boardListMapRef}
      />
    );
  };

  const handleProjectDetailCLick = (e, link) => {
    e.preventDefault();
    if (!user) {
      setLoginModalShow(true);
    } else if (!hasFeatureAccess) {
      enforceAccess();
    } else {
      window.open(link, "_self");
    }
  };

  const handleLoadProjectByEvent = () => {
    handleGetLocation();
    setSelectedProjects([]);
    setMainCheckboxChecked(false);
  };

  useEffect(() => {
    setSubjectContent(emailTemplateBody?.subject);
    setEmailContent(emailTemplateBody?.text);
  }, [emailTemplateBody]);

  return (
    <div className="project-board-container">
      <Geolocation
        setUserLocation={setUserLocation}
        setUserBound={setUserBound}
        setLatLng={setLatLng}
      />
      <section>
        <ProjectBoardNav
          selectedTab={selectedTab}
          setSelectedTab={handleSetSelectedTab}
          setQueryParams={handleSetQueryString}
          setSearchLatLng={handleSearchLatLng}
          saveSearchHandler={handleSaveSearch}
          queryString={queryStringParam}
          handleGetLocation={handleGetLocation}
        />
      </section>
      <section className="project-list-container">
        <div className="mx-3">
          <div className="d-md-none">
            <Mobile
              selectedTab={selectedTab}
              count={count}
              selectedSortItem={selectedSortItem}
              loading={loading || profileLoading}
              projects={siteRoutesProjects}
              currentPage={currentPage}
              handlePageChanged={handlePageChanged}
              handleArchive={handleArchive}
              handleFavourite={handleFavourite}
              map={createMap()}
              handleProjectDetailCLick={handleProjectDetailCLick}
              authenticatedUser={authenticatedUser}
              emailTemplates={email_templates}
              isMainCheckboxChecked={isMainCheckboxChecked}
              setMainCheckboxChecked={setMainCheckboxChecked}
              handleMainCheckboxChange={handleMainCheckboxChange}
              selectedProjects={selectedProjects}
              setSelectedProjects={selectedProjects}
              emailContent={emailContent}
              setEmailContent={setEmailContent}
              subjectContent={subjectContent}
              setSubjectContent={setSubjectContent}
              emailTemplateBody={emailTemplateBody}
            />
          </div>

          <div className="d-none d-md-flex row">
            <LargeScreen
              loading={loading || profileLoading}
              count={count}
              sortItems={sortItems}
              projects={siteRoutesProjects}
              currentPage={currentPage}
              handlePageChanged={handlePageChanged}
              handleArchive={handleArchive}
              handleFavourite={handleFavourite}
              latLng={latLng}
              map={createMap()}
              handleProjectDetailCLick={handleProjectDetailCLick}
              authenticatedUser={authenticatedUser}
              firstTimeLoading={firstTimeLoading}
              emailTemplates={email_templates}
              handleLoadProjectByEvent={handleLoadProjectByEvent}
              isMainCheckboxChecked={isMainCheckboxChecked}
              setMainCheckboxChecked={setMainCheckboxChecked}
              selectedProjects={selectedProjects}
              setSelectedProjects={selectedProjects}
              emailContent={emailContent}
              setEmailContent={setEmailContent}
              subjectContent={subjectContent}
              setSubjectContent={setSubjectContent}
              emailTemplateBody={emailTemplateBody}
              handleMainCheckboxChange={handleMainCheckboxChange}
            />
          </div>
        </div>
      </section>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
      <PricingModal
        show={pricingModalShow}
        app="site_router"
        onHide={() => dispatch({
          type: UPDATE_PRICING_MODAL,
          pricing_modal: false,
        })}
      />
      <SubscriptionModal
        show={paymentModalShow}
        onHide={() => setPaymentModalShow(false)}
      />
    </div>
  );
};

export default SiteRouteRouter;
